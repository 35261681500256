import React from 'react';
import useGetCustomer from 'Hooks/Costumer/getCostumers';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Container, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { saveAs } from 'file-saver';

function CustomerList() {
    const { customers, isCustomerLoading, isCustomerError } = useGetCustomer(true);

    const columns = [
        {
            field: 'name',
            headerName: 'Namn',
            flex: 1,
            renderCell: (params) => (
                `${params.row.first_name} ${params.row.last_name}`
            ),
        },
        { field: 'email', headerName: 'Mail', flex: 1.5 },
        {
            field: 'accepts_marketing',
            headerName: 'Marketing',
            width: 120,
            renderCell: (params) => (
                <Box display="flex" justifyContent="center">
                    {params.row.accepts_marketing ? (
                        <CheckCircleOutlineIcon style={{ color: 'green' }} />
                    ) : (
                        <RemoveCircleOutlineIcon style={{ color: 'red' }} />
                    )}
                </Box>
            ),
        },
        // Add other columns as needed
    ];

    const downloadEmails = () => {
        const csvContent = "data:text/csv;charset=utf-8," + 
            customers.map(customer => customer.email).join("\n");

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, "customer-emails.csv");
    }

    const copyEmailsToClipboard = () => {
        const emailStr = customers.map(customer => customer.email).join("\n");
        navigator.clipboard.writeText(emailStr)
            .then(() => {
                console.log('Emails copied to clipboard');
            })
            .catch(err => {
                console.error('Error in copying text: ', err);
            });
    }

    if (isCustomerError) {
        return <Box m={2}>Error loading customers.</Box>;
    }

    return (
        <Container>
            <Box marginTop={2} display="flex" justifyContent="flex-end">
                {/* Buttons Container */}
                <Box>
                    <Button onClick={downloadEmails} variant="contained" color="primary" style={{ marginRight: 8, textTransform: 'none' }}>
                        Spara emails som CSV
                    </Button>
                    <Button onClick={copyEmailsToClipboard} variant="contained" color="primary" style={{ marginRight: 8, textTransform: 'none' }}>
                        Kopiera emails till urklippstavlan
                    </Button>
                </Box>
            </Box>
            <Box marginTop={2} height={"88vh"} width="100%">
                <DataGrid
                    loading={isCustomerLoading || !customers}
                    rows={customers || []}
                    columns={columns}
                    pageSize={10}
                    checkboxSelection
                    disableSelectionOnClick
                />
            </Box>
        </Container>
    );
}

export default CustomerList;
