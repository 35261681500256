import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from "@mui/styles"
import { Box, Button, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    dropzone: {
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
      borderStyle: "dashed",
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      textAlign: "center",
      cursor: "pointer",
    },
    dropzoneText: {
      marginBottom: theme.spacing(2),
    },
  }));

function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject("Ingen fil vald");
        return;
      }
  
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result); // Extract the base64 data (remove the data URI prefix)
      };
      reader.onerror = (error) => {
        reject(error);
      };
  
      // Read the file as a data URL (base64)
      reader.readAsDataURL(file);
    });
  }

const ImageDropzone = ({ handleAddImage, image, setImage }) => {
    const classes = useStyles();
    
    const onDropCallback = useCallback(async (acceptedFiles) => {
      // You can perform any necessary validation or processing here
      // For example, you can convert the image to base64
      const imageFile = acceptedFiles[0]; // Assuming only one image is dropped
  
      if (imageFile) {
        setImage(imageFile);
        const base64 = await convertFileToBase64(imageFile); // Implement this function to convert the image to base64
        handleAddImage(base64, setImage);
      }
  
      // You can handle the dropped files here or pass them to the parent component if needed
    }, [handleAddImage, setImage]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropCallback,
    accept: 'image/*', // Set accepted file types (e.g., only images)
  });

  return (
    <Box {...getRootProps()} className={classes.dropzone}>
              <input {...getInputProps()} />
              <Typography variant="subtitle1" className={classes.dropzoneText}>
                {isDragActive
                  ? "Släpp bilden här..."
                  : image
                  ? `Vald bild: ${image.name}`
                  : "Lägg till bilder "}
              </Typography>
              <Button variant="outlined" component="span" sx={{ textTransform: "none", fontWeight: "bold" }}>
                Välj bild
              </Button>
</Box>
  );
};

export default ImageDropzone;
