import { Box } from "@mui/material";
import BarLoader from "react-spinners/BarLoader";

const LoadingScreen = () => {

    return (
        <Box display="block" width="100%" height="100%">
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <Box display="flex" flexDirection="column" alignItems="center">  {/* This box will ensure they are vertically stacked */}
                    <BarLoader color='#2B545A' speedMultiplier={0.65}/>
                </Box>
            </Box>
        </Box>
    );
};

export default LoadingScreen;