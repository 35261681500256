import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider
} from '@mui/material';

const DeleteConfirmationDialog = ({ open, onClose, onConfirmDelete, title, subject }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { minWidth: '400px', padding: "10px" } }}
    >
      <DialogTitle sx={{ fontSize: "20px" }}>
        <strong>Ta bort {subject}?</strong>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText sx={{ color: "#393939", fontSize: "16px"}}>
          Detta kommer att radera <strong>{title}</strong>.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          variant="outlined"
          onClick={onClose} 
          color="primary"
          autoFocus
          style={{ fontWeight: "bold", textTransform: "none" }}
        >
          Avbryt
        </Button>
        <Button
          variant="contained" 
          onClick={onConfirmDelete} 
          color="primary" 
          style={{ fontWeight: "bold", textTransform: "none", backgroundColor: "#E00601" }}
        >
          Ta bort
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
