import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Divider,
  Grid,
  Modal,
  Tab,
  Tabs,
  MobileStepper,
  IconButton,
} from "@mui/material";

import "moment/locale/sv";
import { useParams } from "react-router-dom";
import { useProductUpdate } from "Hooks/Products/useProductUpdate";
import useProductDetails from "Hooks/Products/getProductWithMetafieldsById";
import useShopifyCollections from "Hooks/Products/useShopifyCollections";
import ImageDropzone from "./components/ImageDropzone";

import CustomSnackbar from "components/SnackBar";
import LoadingComponent from "components/LoadingComponent";
import ErrorComponent from "components/ErrorComponent";
import VariantComponent from "./components/Variants";
import DetailsComponent from "./components/Details";
import CategoriesComponent from "./components/Categories";
import DeliveryOptionsComponent from "./components/DeliveryOptions";
import StyledBox from "components/StyledBox";

import translations from "translations";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { ArrowBackIos } from "@mui/icons-material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import deliveryOptions from "data/deliveryOptions.json"; // Adjust the path as necessary

const ProductDetails = () => {
  const { id: productId } = useParams();

  // HOOKS
  const {
    updateProduct,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
  } = useProductUpdate(productId);

  const { product, storeName, isLoading, isError } =
    useProductDetails(productId);

  const {
    collections,
    isLoading: isCollectionsLoading,
    isError: isCollectionsError,
  } = useShopifyCollections();

  const isErrorAny = isUpdateError || isError;
  const severitySave = isUpdateError ? "error" : "success";
  const saveMessageDefault = isErrorAny ? "Uppdatering av produkt misslyckas" : "Uppdatering av produkt lyckades!";

  // CONSTS
  const imageField = product?.metafields?.find(
    (metafield) => metafield.key === "recipe"
  );
  const imageUrlRecepie = imageField?.value || "";

  const [productCollections, setProductCollections] = useState([]);
  const [openSnackbarSave, setOpenSnackbarSave] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [activeHeaderTab, setActiveHeaderTab] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [image, setImage] = useState(null);
  const maxSteps = product?.images?.length || 0;
  const [updatedFields, setUpdatedFields] = useState({
    title: "",
    body_html: "",
    allergener: "",
    variants: [],
    status: product?.status,
    vendor: "",
    tags: "",
    images: [],
  });

  // the different delivery tags (used in DeliveryOptions.jsx) and the category tags, these are used in the info section of the product
  const deliveryTags = deliveryOptions.map((option) => option.tag);
  const allTags = product?.tags.split(",").map((tag) => tag.trim());
  const deliveryTag = allTags?.find((tag) => deliveryTags.includes(tag));
  const categoryTags = allTags
    ?.filter((tag) => !deliveryTags.includes(tag))
    .join(", ");

  useEffect(() => {
    if (product && collections) {
      const allergnerMetafield = product?.metafields?.find(
        (metafield) => metafield.key === "allergener"
        );
        const allergenerValue = allergnerMetafield?.value || "";
      const tags = product?.tags
        ? product.tags.split(",").map((tag) => tag.trim().toLowerCase())
        : [];

      // Assuming that the tags array contains collection handles that match the collections' handles
      const productCollectionIds = collections
        .filter((collection) => tags.includes(collection.handle))
        .map((collection) => collection.id);

      setProductCollections(productCollectionIds);

      const initializedImages = product.images
        ? product.images?.map((img) => ({
            src: img.src,
          }))
        : [];
      setUpdatedFields({
        title: product.title || "",
        body_html: product.body_html || "",
        metafields: allergenerValue,
        variants: product.variants || [],
        status: product.status || "",
        vendor: product.vendor || "",
        tags: product.tags || "",
        images: initializedImages,
      });

    }
  }, [product, collections]);
  console.log("🚀 ~ ProductDetails ~ product:", product)


  // HANDLERS //
  const handleOpenShopfiy = (productName) => {
    window.open(
      `https://${storeName}.myshopify.com/products/${productName}`,
      "_blank"
    );
  };

  const formatLeveransmall = (leveransmall) => {
    switch (leveransmall) {
      case "1-dag-leveransmall":
        return "1 dag leverans, bryttid 23:59";
      case "2-dag-leveransmall":
        return "2 dagar leverans, bryttid 23:59";
      case "4-dag-leveransmall":
        return "4 dagar leverans, bryttid 23:59";
      case "jul-leveransmall":
        return "Jul-leverans 1/12 - 23/12, bryttid 23:59";
      default:
        return "Standard leverans (3 dagar), bryttid 23:59";
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbarSave(false);
  };

  const handleDraft = async () => {
    handleChangeField("status", "draft");
  };

  const handleActive = async () => {
    handleChangeField("status", "active");
  };

  const handleUpdateFields = () => {
    updateProduct(updatedFields, {
      onSuccess: () => {
        setOpenSnackbarSave(true);
        setHasChanges(false);
        setImage(null);
      },
      onError: () => {
        setOpenSnackbarSave(true);
        // Possibly set an error message for the snackbar here
      },
    });
  };

  const handleQuillChange = (field, value, source) => {
    if (source === "user") {
      handleChangeField(field, value);
    }
  };
  // function to handle the updatedFields
  // checks if the index is valid and checks if the previous variants is an array
  const handleChangeField = (field, value, variantIndex) => {
    setHasChanges(true);
    if (variantIndex !== undefined) {
      setUpdatedFields((prev) => {
        const updatedVariants = Array.isArray(prev.variants)
          ? [...prev.variants]
          : [];
        updatedVariants[variantIndex] = {
          ...updatedVariants[variantIndex],
          [field]: value,
        };
        return {
          ...prev,
          variants: updatedVariants,
        };
      });
    } else {
      setUpdatedFields((prevFields) => ({
        ...prevFields,
        [field]: value,
      }));
    }
  };

  const updateProductTags = (collectionIds, currentTags) => {
    const currentDeliveryTags = currentTags.filter((tag) =>
      deliveryTags.includes(tag)
    );
    const collectionHandles = collections
      .filter((collection) => collectionIds.includes(collection.id))
      .map((collection) => collection.handle);

    // Combine delivery tags with collection handles and return as a comma-separated string
    const updatedTags = [...currentDeliveryTags, ...collectionHandles];
    return updatedTags.join(", ");
  };

  const handleCollectionChange = (collectionId) => {
    setProductCollections((prevCollections) => {
      const newCollections = prevCollections.includes(collectionId)
        ? prevCollections.filter((id) => id !== collectionId)
        : [...prevCollections, collectionId];

      // Extract current tags and pass them to updateProductTags
      const currentTags = updatedFields.tags
        .split(",")
        .map((tag) => tag.trim());
      const updatedTags = updateProductTags(newCollections, currentTags);

      handleChangeField("tags", updatedTags);
      return newCollections;
    });
  };

  const handleDeliveryOptionChange = (selectedDeliveryTag) => {
    const currentTags = updatedFields.tags.split(",").map((tag) => tag.trim());
    const currentNonDeliveryTags = currentTags.filter(
      (tag) => !deliveryTags.includes(tag)
    );

    let updatedTags;
    if (selectedDeliveryTag === "standard-leverans") {
      updatedTags = currentNonDeliveryTags;
    } else {
      updatedTags = [
        ...currentNonDeliveryTags.filter((tag) => tag !== "standard-leverans"),
        selectedDeliveryTag,
      ];
    }

    handleChangeField("tags", updatedTags.join(", "));
  };

  const handleHeaderTabChange = (event, newValue) => {
    setActiveHeaderTab(newValue);
  };

  const handleAddAllergener = () => {};

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDeleteImage = () => {
    let newActiveStep = activeStep;
    const updatedImages = updatedFields.images.map((img, index) => {
      if (index === activeStep) {
        newActiveStep =
          index === updatedFields.images.length - 1 ? index - 1 : index;
        return { ...img, toDelete: true };
      }
      return img;
    });

    setUpdatedFields((prevFields) => ({
      ...prevFields,
      images: updatedImages,
    }));
    setHasChanges(true);

    // Check if the current or a new active step is marked for deletion,
    // and adjust if necessary. This is a basic approach and might need
    // refinement based on your exact needs.
    const nonDeletedIndex = updatedImages.findIndex(
      (img, index) => !img.toDelete && index >= newActiveStep
    );
    setActiveStep(nonDeletedIndex !== -1 ? nonDeletedIndex : 0);
  };

  const handleAddImage = (newImage) => {
    const updatedImages = [
      ...updatedFields.images,
      { src: newImage, isNew: true },
    ];
    setUpdatedFields((prevFields) => ({
      ...prevFields,
      images: updatedImages,
    }));
    setHasChanges(true);
  };

  if (isLoading || isCollectionsLoading) {
    return <LoadingComponent />;
  }

  if (isErrorAny || isCollectionsError || !product) {
    return <ErrorComponent error={isErrorAny || isCollectionsError} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: 3,
      }}
    >
      <StyledBox>
        <Card
          elevation="0"
          sx={{ width: "70vw", overflow: "hidden" }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => window.history.back()}
              startIcon={<ArrowBackIos />}
              sx={{
                mb: 2,
                textTransform: "none",
              }}
            >
              Gå tillbaka
            </Button>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                onClick={handleUpdateFields}
                disabled={isUpdateLoading || !hasChanges}
                variant="contained"
                color="secondary"
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  mb: 2,
                  mr: 2,
                }}
              >
                {isUpdateLoading ? "Sparar..." : "Spara"}
              </Button>
              <Button
                disabled={isUpdateLoading || !hasChanges}
                onClick={() => {
                  setHasChanges(false);
                  setImage(null);
                  setUpdatedFields({
                    title: product.title || "",
                    body_html: product.body_html || "",
                    allergener: "",
                    variants: product.variants || [],
                    status: product.status || "",
                    vendor: product.vendor || "",
                    tags: product.tags || "",
                    images: product.images || [],
                  });
                }}
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                Avbryt
              </Button>
            </Box>
          </Box>

          <Box position="relative" width="100%" height="350px">
            <CardMedia
              component="img"
              height="350"
              image={
                product.images?.[activeStep]?.src ||
                "https://via.placeholder.com/150"
              }
              alt={product.title}
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }} // Ensure the image covers the container
            />
            {product.images?.length > 1 && (
              <IconButton
                onClick={handleDeleteImage}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "rgba(255, 255, 255, 0.8)", // Adjust color for visibility
                  backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent background
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.6)", // Darken on hover for better UI feedback
                  },
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            )}
          </Box>
          {maxSteps > 1 && (
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  <KeyboardArrowRight fontSize="large" />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  <KeyboardArrowLeft fontSize="large" />
                </Button>
              }
            />
          )}
          <ImageDropzone
            image={image}
            setImage={setImage}
            handleAddImage={handleAddImage}
          />

          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                mt: 3,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const imageUrl = imageUrlRecepie;
                  window.open(imageUrl, "_blank");
                }}
                disabled={!imageUrlRecepie} // Disable the button if imageUrlRecepie is falsy
                sx={{
                  ml: "auto",
                  bgcolor: "#79A8A9",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
                startIcon={<InsertDriveFileIcon />}
              >
                Recept
              </Button>
              <Button
                onClick={() => handleOpenShopfiy(product.handle)}
                variant="contained"
                endIcon={<ArrowOutwardIcon />}
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  m: "10px",
                }}
              >
                Visa på hemsidan
              </Button>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h4" component="div" gutterBottom>
                {product.title}
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  gutterBottom
                >
                  Produkt ID: <b>{product.id}</b>
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  gutterBottom
                >
                  Kategori: <b>{categoryTags}</b>
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  gutterBottom
                >
                  Leveransmall: <b>{formatLeveransmall(deliveryTag)}</b>
                </Typography>
                <Box>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    gutterBottom
                  >
                    Status: <b>{translations.productStatus[product.status]}</b>
                  </Typography>
                  {updatedFields.status === "draft" ? (
                    <Button
                      onClick={handleActive}
                      disabled={isUpdateLoading}
                      sx={{
                        mb: 2,
                        backgroundColor: "#0088D1", // Main color
                        color: "white", // Text color
                        "&:hover": {
                          backgroundColor: "#006DA8", // Change hover color to match
                        },
                        textTransform: "none",
                        fontWeight: "bold",
                        minWidth: "150px",
                      }}
                    >
                      Gör synlig på hemsidan
                    </Button>
                  ) : (
                    <Button
                      onClick={handleDraft}
                      disabled={isUpdateLoading}
                      sx={{
                        mb: 2,
                        backgroundColor: "#0088D1", // Main color
                        color: "white", // Text color
                        "&:hover": {
                          backgroundColor: "#006DA8", // Change hover color to match
                        },
                        textTransform: "none",
                        fontWeight: "bold",
                        minWidth: "150px",
                      }}
                    >
                      Gör osynlig på hemsidan
                    </Button>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <Tabs
                    value={activeHeaderTab}
                    onChange={handleHeaderTabChange}
                    aria-label="product tabs"
                    sx={{ mb: 3 }}
                  >
                    <Tab
                      label="Information"
                      sx={{ textTransform: "None", fontWeight: "bold" }}
                    />
                    <Tab
                      label="Pris och varianter"
                      sx={{ textTransform: "None", fontWeight: "bold" }}
                    />
                    <Tab
                      label="Kategori"
                      sx={{ textTransform: "None", fontWeight: "bold" }}
                    />
                    <Tab
                      label="Leveransmall"
                      sx={{ textTransform: "None", fontWeight: "bold" }}
                    />
                  </Tabs>
                  {activeHeaderTab === 0 && (
                    <Box>
                      {/* Render the product details when the first tab is selected */}
                      <DetailsComponent
                        updatedFields={updatedFields}
                        handleChangeField={handleChangeField}
                        handleQuillChange={handleQuillChange}
                        handleAddAllergener={handleAddAllergener}
                      />
                    </Box>
                  )}
                  {activeHeaderTab === 1 && (
                    <Box>
                      {/* Render the variants details when the second tab is selected */}
                      <VariantComponent
                        title={product.title}
                        variants={updatedFields.variants}
                        index={0}
                        handleChangeField={handleChangeField}
                        setIsHelpModalOpen={setIsHelpModalOpen}
                        xz
                      />
                    </Box>
                  )}
                  {activeHeaderTab === 2 && (
                    <Box>
                      <CategoriesComponent
                        productCollections={productCollections}
                        allCollections={collections}
                        handleCollectionChange={handleCollectionChange}
                      />
                    </Box>
                  )}
                  {activeHeaderTab === 3 && (
                    <Box>
                      <DeliveryOptionsComponent
                        productTags={updatedFields?.tags
                          .split(",")
                          .map((tag) => tag.trim())}
                        handleDeliveryOptionChange={handleDeliveryOptionChange}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </StyledBox>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <CustomSnackbar
        open={openSnackbarSave}
        handleClose={handleSnackbarClose}
        message={saveMessageDefault}
        severity={severitySave}
      />
      {/* Modal */}
      <Modal
        open={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)} // Close modal on close button click or outside click
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 5,
            borderRadius: "10px",
          }}
        >
          {/* Modal content */}
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", mb: "25px" }}
            gutterBottom
          >
            Vad är struket pris?
          </Typography>
          <Typography variant="body1" gutterBottom>
            För att sätta ett överstruket pris, sätt <b>Struket pris</b> högre
            än <b>Pris</b>. Det vill säga kommer <b>Pris</b> i så fall bli det
            nya <b>kampanjpriset</b>!
          </Typography>
          <Button
            onClick={() => setIsHelpModalOpen(false)}
            variant="outlined"
            sx={{
              mt: "25px",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Stäng
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProductDetails;
