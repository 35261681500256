import React from 'react';
import { Grid } from '@mui/material';
import StatsBox from 'components/StatBox';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'; // Example icon for total orders
import EventIcon from '@mui/icons-material/Event'; // Example icon for busiest day
import DateRangeIcon from '@mui/icons-material/DateRange'; // Example icon for busiest week

function OrderStatsCards({ totalOrders, busiestDay, busiestWeek }) {
  return (
    <Grid container spacing={3} justifyContent="center">
      {/* Total Orders Box */}
      <Grid item xs={12} sm={6} md={4}>
        <StatsBox
          title="Totalt antal ordrar"
          value={totalOrders}
          color="#2B545A" // Text color
          icon={ShoppingCartIcon} // Icon component
        />
      </Grid>

      {/* Busiest Day Box */}
      <Grid item xs={12} sm={6} md={4}>
        <StatsBox
          title="Populäraste dagen för beställningar"
          value={busiestDay}
          color="#2B545A" // Text color
          icon={EventIcon} // Icon component
        />
      </Grid>

      {/* Busiest Week Box */}
      <Grid item xs={12} sm={6} md={4}>
        <StatsBox
          title="Populäraste veckan för leveranser"
          value={`vecka ${busiestWeek}`}
          color="#2B545A" // Text color
          icon={DateRangeIcon} // Icon component
        />
      </Grid>
    </Grid>
  );
}

export default OrderStatsCards;
