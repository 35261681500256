import { Box, Typography } from '@mui/material';
import UseSyncOrders from '../../Hooks/Orders/syncOrders';

const SyncOrders = () => {

    
    const {syncData, isLoading, isError} = UseSyncOrders();
    console.log("🚀 ~ file: syncOrders.jsx:8 ~ SyncOrders ~ syncData:", syncData)
    
    
    return (
        <Box>
            <Typography variant="h1">Sync Orders (kika konsolen för response)</Typography>

            {!isLoading && !isError && (
                <Typography>
                    {syncData.message}
                </Typography>
            )}
        </Box>
    );
    }

export default SyncOrders;
