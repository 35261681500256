import { useQuery } from 'react-query';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext'; // Ensure the path to AuthContext is correct.

const fetchDiscounts = async (updateToken, logoutUser, retryCount = 0) => {
  const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:8000";
  const response = await fetch(`${baseUrl}/shopify-api/discounts/`, {
    method: 'GET',
    credentials: 'include',
  });

  if ((response.status === 401 || response.status === 403) && retryCount < 1) {
    await updateToken();
    return fetchDiscounts(updateToken, logoutUser, retryCount + 1); // Retry after refreshing token
  } else if ((response.status === 401 || response.status === 403) && retryCount >= 1) {
    logoutUser();
    throw new Error('Authentication failed. Logging out.');
  }

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const useFetchDiscounts = () => {
  const { updateToken, logoutUser } = useContext(AuthContext);

  const { data: discounts, isLoading, isError, error } = useQuery(
    'discounts',
    () => fetchDiscounts(updateToken, logoutUser),
    {
      retry: 1,
      // Optionally, you could also provide onSuccess and onError here
      // onSuccess: () => {
      //   queryClient.invalidateQueries('discounts'); // If you need to invalidate and refetch
      // },
      // onError: (error) => {
      //   console.error('Error fetching discounts:', error);
      // },
    },
  );

  return { discounts, isLoading, isError, error };
};

export default useFetchDiscounts;
