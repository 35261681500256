import React, { useState } from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import "../styles/heatmapstyles.css";
import { subYears, addYears } from "date-fns";
import ReactTooltip from "react-tooltip";

import { Box, Button, Typography, Grid } from "@mui/material";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function YearlyHeatmap({ orders, type, label, header }) {
  // State to keep track of the current year being viewed
  const [currentYear, setCurrentYear] = useState(new Date());
  const monthLabels = [
    "jan",
    "feb",
    "mar",
    "apr",
    "maj",
    "jun",
    "jul",
    "aug",
    "sep",
    "okt",
    "nov",
    "dec",
  ];
  const weekdayLabels = ["sön", "mån", "tis", "ons", "tor", "fre", "lör"];

  const legendItems = [
    { color: "#ebedf0", text: "0" }, // corresponds to .color-empty
    { color: "#d6e685", text: "1-20" }, // corresponds to .color-level-1
    { color: "#8cc665", text: "21-40" }, // corresponds to .color-level-2
    { color: "#44a340", text: "41-60" }, // corresponds to .color-level-3
    { color: "#1e6823", text: "61-80" }, // corresponds to .color-level-4
    { color: "#004400", text: "81+" }, // corresponds to .color-level-5
  ];

  // Function to get heatmap values for the current year
  const getHeatmapValues = () => {
    const dateCounts = {};

    orders.forEach((order) => {
      // Choose the date field based on the type
      let dateStr;
      if (type === "created") {
        dateStr = new Date(order.created_at).toISOString().split("T")[0];
      } else if (type === "deliver") {
        const leveransDatum = order.note_attributes.find(
          (attr) => attr.name === "Leveransdatum"
        )?.value;
        if (leveransDatum) {
          // Convert the date from DD/MM/YYYY to YYYY-MM-DD
          const [day, month, year] = leveransDatum.split("/");
          dateStr = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        }
      }

      // Continue processing if dateStr is defined
      if (dateStr) {
        if (!dateCounts[dateStr]) {
          dateCounts[dateStr] = { date: dateStr, count: 0 };
        }
        dateCounts[dateStr].count += 1;
      }
    });

    // Convert to array and sort by date
    const valuesArray = Object.values(dateCounts).sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    // Find the maximum value
    const maxValue = Math.max(...valuesArray.map((v) => v.count));

    // Define thresholds based on maximum value
    const thresholds = {
      level1: maxValue * 0.2, // 20% of the max
      level2: maxValue * 0.4, // 40% of the max
      level3: maxValue * 0.6, // 60% of the max
      level4: maxValue * 0.8, // 80% of the max
      // level5: anything above level4
    };

    // Assign a level to each value based on these thresholds
    valuesArray.forEach((v) => {
      if (v.count <= thresholds.level1) v.level = "color-level-1";
      else if (v.count <= thresholds.level2) v.level = "color-level-2";
      else if (v.count <= thresholds.level3) v.level = "color-level-3";
      else if (v.count <= thresholds.level4) v.level = "color-level-4";
      else v.level = "color-level-5";
    });
    valuesArray.forEach((value) => {
      value.tooltip = `${value.count} ${
        value.count === 1 ? "beställning" : "beställningar"
      } ${label} ${value.date}`;
    });
    return valuesArray;
  };

  // Move to the previous or next year
  const changeYear = (increment) => {
    setCurrentYear(addYears(currentYear, increment));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs>
        <Typography variant="h6" gutterBottom>
          {header} under <strong>{currentYear.getFullYear()}</strong>
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
          <Button
            startIcon={<NavigateBeforeIcon />}
            variant="outlined"
            onClick={() => changeYear(-1)}
            sx={{ textTransform: "none", width: "60px", padding: 0 }}
          >
            {currentYear.getFullYear() - 1}
          </Button>
          <Button
            endIcon={<NavigateNextIcon />}
            onClick={() => changeYear(1)}
            variant="outlined"
            sx={{ textTransform: "none", width: "60px", padding: 0 }}
          >
            {currentYear.getFullYear() + 1}
          </Button>
        </Box>
        <CalendarHeatmap
          startDate={subYears(currentYear, 1)}
          endDate={currentYear}
          values={getHeatmapValues()}
          monthLabels={monthLabels}
          weekdayLabels={weekdayLabels}
          classForValue={(value) => {
            return value ? value.level : "color-empty";
          }}
          tooltipDataAttrs={(value) => ({
            "data-tip": value ? value.tooltip : "", // Add tooltip data attribute
          })}
          showWeekdayLabels={true}
        />
        <ReactTooltip />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {legendItems.map((item, index) => (
            <Box key={index} display="flex" alignItems="center" mr={2}>
              <Box
                width={20}
                height={20}
                mr={1}
                style={{ backgroundColor: item.color }}
              />
              <Typography variant="caption">{item.text}</Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}

export default YearlyHeatmap;
