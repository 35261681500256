import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { 
    DataGrid,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { 
  Box,
  Chip,
  Button
} from '@mui/material';
import moment from 'moment';

import useFetchDiscounts from 'Hooks/Discounts/useFetchDiscounts';
import useDeleteDiscount from 'Hooks/Discounts/useDeleteDiscount';
import useUpdateDiscount from 'Hooks/Discounts/useUpdateDiscount';

import DeleteConfirmationDialog from 'components/DeleteConfirmation';
import CustomSnackbar from 'components/SnackBar';
import DateCellEditor from './DateCellEditor';
import ErrorComponent from 'components/ErrorComponent';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';

const formatValue = (value, valueType) => {
    const numericValue = Math.abs(value); // Convert the value to a positive number
  
    if (valueType === 'fixed_amount') {
      return `${numericValue} kr`;
    } else if (valueType === 'percentage') {
      return `${numericValue} %`;
    }
  
    return value; // Default return in case valueType is neither
  };

const formatDate = (dateString) => {
    if (!dateString) return '';   
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    console.log(`${day}/${month}/${year}, ${hours}:${minutes}`);
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
};

const DiscountsDataGrid = () => {
  const queryClient = useQueryClient();
  const { discounts, isLoading, error } = useFetchDiscounts();
  const { updateDiscount, isLoading: isUpdateLoading, error: isUpdateError } = useUpdateDiscount();
  const { deleteDiscount } = useDeleteDiscount();
  const [editRowsModel, setEditRowsModel] = useState({});
  const [rowChanges, setRowChanges] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const handleSnackbarOpen = () => {
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = () => {
  setOpenSnackbar(false);
};

const handleDialogOpen = (row) => {
  setSelectedRow(row);
  setOpenDialog(true);
};
const hasRowChanged = (rowId) => {
  return rowId in rowChanges;
};
  const handleEditRowsModelChange = (model) => {
    setEditRowsModel(model);
  };

  const handleArchive = async (row) => {
    const today = new Date();
    const rowId = row.id;
    const data = {
      id: rowId,
      price_rule_id: row.price_rule_id,
      starts_at: today,
      ends_at: today,
    };

    try {
        await updateDiscount(data);
        if (!isUpdateLoading) handleSnackbarOpen();
        queryClient.invalidateQueries('discounts');
  
        // Clear the changes for this row
        setRowChanges((prev) => {
          const updatedChanges = { ...prev };
          delete updatedChanges[rowId]; // Remove changes for this row
          return updatedChanges;
        });
  
        setEditRowsModel((prev) => ({ ...prev, [rowId]: undefined }));
      } catch (error) {
        console.error('Error saving discount:', error);
      }
  };
  const handleSave = async (rowId, row) => {
    const changes = rowChanges[rowId];
    if (changes) {
      // Create an object to hold the updated fields
      const updatedFields = {};
  
      // Check which fields are changed and add them to the updatedFields object
      if (changes.starts_at) {
        updatedFields.starts_at = changes.starts_at;
      }
      if (changes.ends_at) {
        updatedFields.ends_at = changes.ends_at;
      }
  
      const data = {
        id: rowId,
        price_rule_id: row.price_rule_id,
        ...updatedFields
      };
  
      try {
        await updateDiscount(data);
        if (!isUpdateLoading) handleSnackbarOpen();
        queryClient.invalidateQueries('discounts');
  
        // Clear the changes for this row
        setRowChanges((prev) => {
          const updatedChanges = { ...prev };
          delete updatedChanges[rowId]; // Remove changes for this row
          return updatedChanges;
        });
  
        setEditRowsModel((prev) => ({ ...prev, [rowId]: undefined }));
      } catch (error) {
        console.error('Error saving discount:', error);
      }
    }
  };
  
  const handleCancel = (rowId) => {
    setRowChanges((prev) => {
      const updatedChanges = { ...prev };
      delete updatedChanges[rowId]; // Remove changes for this row
      return updatedChanges;
    });
  
    setEditRowsModel((prev) => ({ ...prev, [rowId]: undefined }));
  };

    const handleDelete = async (row) => {
      try {
        await deleteDiscount(row.id, row.price_rule_id);
        setSelectedRow({});
        setOpenDialog(false);
        // Invalidate and refetch
        queryClient.invalidateQueries('discounts');
      } catch (error) {
        console.error('Error deleting discount:', error);
        // Handle error (e.g., show notification to the user)
      }
    };
  
    if (error) return <ErrorComponent />;

    const formattedDiscounts = discounts ? discounts.map(discount => ({
      ...discount,
      value: `${formatValue(discount.value, discount.value_type)} rabatt på hela ordern`,
      starts_at: formatDate(discount.starts_at),
      ends_at: formatDate(discount.ends_at),
      starts_at_input: moment(discount.starts_at),
      ends_at_input: moment(discount.ends_at),
    })) : [];
  
    
    // specific cell for the date fields
    const renderDateCell = (params, field) => {
      const isEditing = editRowsModel[params.id]?.[field];
    
      const handleValueChange = (newValue) => {
        setRowChanges((prev) => ({
          ...prev,
          [params.id]: { ...prev[params.id], [field]: newValue },
        }));
      };

      const handleStartEditing = () => {
        setEditRowsModel((prev) => ({
          ...prev,
          [params.id]: { ...prev[params.id], [field]: true }
        }));
      };
    
      return (
        <DateCellEditor
          params={params}
          field={field}
          isEditing={isEditing}
          onValueChange={handleValueChange}
          onStartEditing={handleStartEditing}
        />
      );
    };
    

  const columns = [
    { field: 'code', headerName: 'Rabattkod', width: 200 },
    { field: 'value', headerName: 'Värde', width: 200 },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        let backgroundColor;
        let label;
  
        switch (params.value) {
          case 'Aktiv':
            backgroundColor = '#CDFEE1';
            label = 'Aktiv';
            break;
          case 'Schemalagd':
            backgroundColor = '#FFEF9D';
            label = 'Schemalagd';
            break;
          case 'Utgången':
            backgroundColor = '#F2F2F2';
            label = 'Utången';
            break;
          default:
            backgroundColor = '#F2F2F2';
            label = params.value;
        }
        
        const chipStyle = {
          backgroundColor,
          color: backgroundColor === 'default' ? undefined : 'black', // Adjust text color here if needed
          size: 'small'
        };

        return <Chip label={label} style={chipStyle} size='small' />;
      },
    },
    {
      field: 'starts_at',
      headerName: 'Startdatum',
      width: 200,
      renderCell: (params) => renderDateCell(params, 'starts_at'),
    },
    {
      field: 'ends_at',
      headerName: 'Slutdatum',
      width: 200,
      renderCell: (params) => renderDateCell(params, 'ends_at'),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 200,
      flex: 0.5,
      getActions: (params) => {
        const actions = [];
        
        // Add the update button if there are changes for this row
      if (hasRowChanged(params.id)) {
        actions.push(
          <Button
            sx={{ textTransform: 'none' }}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => handleSave(params.id, params.row)}
          >
            Spara
          </Button>
        );

        actions.push(
          <Button
            sx={{ textTransform: 'none' }}
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => handleCancel(params.id)}
          >
            Avbryt
          </Button>
        );
      }
      if (!hasRowChanged(params.id)) {

        actions.push(
          <GridActionsCellItem
            icon={<ArchiveIcon />}
            label="Archive"
            onClick={() => handleArchive(params.row)}
            className="archive-icon"
            sx={{ visibility: 'hidden' }}
          />
        );
        actions.push(
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDialogOpen(params.row)}
            className="delete-icon"
            sx={{ visibility: 'hidden' }}
          />
        );
      }
      
        return actions;
      },
  },
  ];

  return (
    <>
      <style>
        {`
          .MuiDataGrid-cell:hover .edit-icon {
            visibility: visible;
          }
          .MuiDataGrid-row:hover .delete-icon {
            visibility: visible;
          }
          .MuiDataGrid-row:hover .archive-icon {
            visibility: visible;
          }
        `}
      </style>
    <Box style={{ height: 800, width: '100%' }}>
      <DataGrid
        disableRowSelectionOnClick
        loading={isLoading}
        rows={formattedDiscounts}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        getRowId={(row) => row.id}
        editRowsModel={editRowsModel}
        onEditRowsModelChange={handleEditRowsModelChange}
      />
    </Box>

     <CustomSnackbar
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        message= {isUpdateError ? "Uppdatering av rabattkod misslyckades!" : "Uppdatering av rabattkod lyckades!"}
        severity= {isUpdateError ? "error" : "success"}
      />

      <DeleteConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirmDelete={() => {
          handleDelete(selectedRow)
          setOpenDialog(false);
        }}
        title={selectedRow.code}
        subject={'rabattkoden'}
      />
    </>
  );
};

export default DiscountsDataGrid;
