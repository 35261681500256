import React, { forwardRef, useState } from 'react';
import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';


import PrintIcon from '@mui/icons-material/Print';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BackwardIcon from '@mui/icons-material/ArrowBack';

import { mergePDFsOnServer, printPDF } from 'utilities/generatePdf';
import generatePDF from 'utilities/generatePdf';
import useGetLocations from '../../../../Hooks/Locations/useGetLocations';

const OrderGridToolbar = forwardRef(({ selectedRows, handleOptionTwo, handleOptionThree, onFilterOrders, orders, clearFilters }, ref) => {
    const [selectedAction, setSelectedAction] = useState('');
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [selectedStoreFilter, setSelectedStoreFilter] = useState('');
    const [selectedDateFilter, setSelectedDateFilter] = useState('');
    const { locations } = useGetLocations();

    const toolbarStyles = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    };

    const utilitiesContainerStyle = {
      display: 'flex',
      alignItems: 'center',
      gap: '16px', // Adjust spacing between elements
    };

    // Custom styled components
const CustomFormControl = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(1),
    minWidth: 150,
  }));
  
  const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    textTransform: 'none',
  }));
  
    const handleStoreFilterChange = (event) => {
      setSelectedStoreFilter(event.target.value);
      onFilterOrders({ store: event.target.value, date: selectedDateFilter });
    };

  const handleDateFilterChange = (event) => {
      setSelectedDateFilter(event.target.value);
      onFilterOrders({ store: selectedStoreFilter, date: event.target.value });
  };

  const handleClearFilter = () => {
      setSelectedStoreFilter('');
      setSelectedDateFilter('');
      setSelectedAction('');
      clearFilters(); // Call the clearFilters function from parent component
  };



  
    const handleActionChange = (event) => {
        setSelectedAction(event.target.value);
    };




  const quickFilterSx = {
    '& .MuiInputBase-input': {
      bgcolor: 'common.white', // Background for the input
      borderRadius: '4px', // Rounded corners for the input
    },
  };

  

const handleBulkPDFGeneration = async () => {
    setLoading(true);  
  // Reset progress at the start
    setProgress(0);
    
    // Map each selected row to a promise that generates a PDF
    const pdfGenerationPromises = selectedRows.map(async (id, index, array) => {
        const order = orders?.find(o => o.shopify_order_id === id);
        if (order) {
            try {
                const pdfBlob = await generatePDF(id, order, "shopflow");
                // Update progress after each PDF is generated
                setProgress((currentProgress) => 
                    ((index + 1) / array.length) * 100
                );
                return pdfBlob;
            } catch (error) {
                console.error("Error generating PDF for order", id, error);
                return null; // Return null if an error occurs
            }
        }
        return null; // Return null if no order is found
    });

    try {
        // Wait for all PDF generation promises to resolve
        const pdfBlobs = (await Promise.all(pdfGenerationPromises)).filter(blob => blob !== null);

        if (pdfBlobs.length > 0) {
          const mergedPdfBlob = await mergePDFsOnServer(pdfBlobs);
          if (mergedPdfBlob) {
              printPDF(mergedPdfBlob); // Print the merged PDF
              setLoading(false);
          }
      } else {
          console.log('No PDFs were generated.');
      }

    } catch (error) {
        console.error('Error in bulk PDF generation and merging:', error);
    }

    // Reset progress at the end
    setProgress(0);
};


    const executeAction = () => {
      if (selectedAction === 'print') {
          handleBulkPDFGeneration();
      } else if (selectedAction === 'move_forward') {
          handleOptionTwo();
      } else if (selectedAction === 'move_backward') {
        handleOptionThree();

      }
      setSelectedAction('');
    };


return (
    <GridToolbarContainer ref={ref} sx={toolbarStyles}>
      <Box sx={utilitiesContainerStyle}>
      <CustomFormControl size='small'>
        <InputLabel id="action-select-label">Hantera</InputLabel>
        <Select
          labelId="action-select-label"
          id="action-select"
          value={selectedAction}
          onChange={handleActionChange}
          label="Hantera"
        >
          <MenuItem value="print">
            <PrintIcon style={{ marginRight: '10px' }} fontSize='small'/>
            Skriv ut
          </MenuItem>
          <MenuItem value="move_forward">
            <ArrowForwardIcon style={{ marginRight: '10px' }} fontSize='small'/>
            Flytta framåt
          </MenuItem>
          <MenuItem value="move_backward" style={{ color: '#757575' }}>
            <BackwardIcon style={{ marginRight: '10px' }} fontSize='small'/>
            Flytta bakåt
          </MenuItem>
          {/* More actions can be added here */}
        </Select>
      </CustomFormControl>
        <CustomButton
          onClick={executeAction}
          disabled={!selectedAction}
          sx={{
            backgroundColor: selectedAction ? 'primary.main' : 'grey.400',
          }}
        >
          Kör
        </CustomButton>
        <CustomButton onClick={handleClearFilter}>
            Rensa filter
        </CustomButton>
        {loading && <CircularProgress variant="determinate" value={progress} />}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
      <CustomFormControl variant="outlined" size="small">
            <InputLabel id="filter-select-label">Plats</InputLabel>
            <Select
                labelId="filter-select-label"
                id="filter-select"
                value={selectedStoreFilter}
                onChange={handleStoreFilterChange}
                label="Plats"
            >
                {locations?.map((location) => (
                    <MenuItem key={location.id} value={`${location.name}`}>
                        {location.name}
                    </MenuItem>
                ))}
                <MenuItem value="">Alla</MenuItem>
            </Select>
        </CustomFormControl>

      <CustomFormControl variant="outlined" size="small">
            <InputLabel id="filter-select-label">Filter</InputLabel>
            <Select
                fullWidth
                labelId="filter-select-label"
                id="filter-select"
                value={selectedDateFilter}
                onChange={handleDateFilterChange}
                label="Filter"
            >
                <MenuItem value="today">Dagens Ordrar</MenuItem>
                <MenuItem value="tomorrow">Morgondagens Ordrar</MenuItem>
                <MenuItem value="nextWeek">Kommande 7 dagar</MenuItem>
            </Select>
        </CustomFormControl>
        <GridToolbarQuickFilter
          sx={{ ...quickFilterSx }}
        />
      </Box>
    </GridToolbarContainer>
  );
  
  
});

export default OrderGridToolbar;