import { useMutation, useQueryClient } from 'react-query';


export const useDeleteOrder = () => {
    const queryClient = useQueryClient();
  
    return useMutation(
      async (orderId) => {
        // Ensure your API endpoint is correct
        const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
        const response = await fetch(`${baseUrl}/webhook/delete_order/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    order_ids: [orderId]
                }),
                credentials: 'include', // Include this line

            });
            if (!response.ok)
                throw new Error('Network response was not ok');
            return await response.json();
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('orders');
        },
        onError: (error) => {
          // Handle the error
          console.error('Error deleting the order:', error);
        },
      }
    );
  };
  