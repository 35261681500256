import React, { useState, useEffect, useRef, useCallback } from 'react';
import jsQR from 'jsqr';
import './QRCodeScanner.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import throttle from 'lodash/throttle'; // Import throttle

const QRCodeScanner = () => {
  const [processedIds, setProcessedIds] = useState(new Set()); // Track processed IDs
  const videoRef = useRef();
  const canvasRef = useRef();
  const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:8000";

  const [scanResults, setScanResults] = useState([]);


  const handleScan = useCallback(async (data) => {
    // Check if the ID has already been processed
    if (processedIds.has(data)) {
      console.log(`Order ID ${data} already processed.`);
      return;
    }
  
    // Add the ID to the set of processed IDs to avoid duplicate processing
    setProcessedIds(prev => new Set(prev).add(data));
  
    try {
      // Attempt to update the order status via the backend
      const response = await fetch(`${baseUrl}/webhook/update_order_status_from_qr/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ order_id: data, status: 'delivered' }),
        credentials: 'include',
      });
  
      const jsonResult = await response.json();
      if (response.ok) {
        // Update the result to include the name
        const newScanResult = {
          id: data,
          firstName: jsonResult.first_name, // assuming the backend sends this
          lastName: jsonResult.last_name,   // assuming the backend sends this
          timestamp: new Date(),
        };
        setScanResults(prevResults => [...prevResults, newScanResult]);
        
        NotificationManager.success('Scan and update successful!', 'Success');
      } else {
        // Handle backend errors
        console.error('Error:', jsonResult);
        NotificationManager.error('Update failed', 'Error');
      }
    } catch (error) {
      // Handle network errors
      console.error('Fetch error:', error);
      NotificationManager.error('Network error', 'Error');
    }
  }, [baseUrl, processedIds]);
  

  useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ 
          video: { facingMode: 'environment' }
        });
        videoRef.current.srcObject = stream;
      } catch (error) {
        console.error('Error starting camera:', error);
      }
    };
  
    startCamera();
    const currentVideoRef = videoRef.current;
  
    return () => {
      const tracks = currentVideoRef.srcObject?.getTracks();
      tracks?.forEach(track => track.stop());
    };
  }, []);

  const scanQRCode = () => {
    if (canvasRef.current && videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
      const context = canvasRef.current.getContext('2d');
      canvasRef.current.height = videoRef.current.videoHeight;
      canvasRef.current.width = videoRef.current.videoWidth;
      context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
      const imageData = context.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
      const qrCode = jsQR(imageData.data, imageData.width, imageData.height);

      if (qrCode) {
        handleScan(qrCode.data);
      }
    }
  };

  const throttledScanQRCode = throttle(scanQRCode, 1000);

  useEffect(() => {
    const scanningIntervalId = setInterval(throttledScanQRCode, 1000);

    return () => {
      clearInterval(scanningIntervalId);
      throttledScanQRCode.cancel();
    };
  }, [throttledScanQRCode]);

  return (
    <div className="scanner-container">
      <div className="video-container">
        <video ref={videoRef} autoPlay playsInline muted />
        <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
      </div>

      {
      // scanResults.map((scanResult, index) => (
      //   <div key={index} className="result-panel">
      //     <div className="result-title">Scan Result</div>
      //     <div className="result-content">
      //       ID: {scanResult.id}, {scanResult.firstName} {scanResult.lastName}
      //       <div className="item-timestamp">{scanResult.timestamp.toLocaleString()}</div>
      //     </div>
      //   </div>
      // ))
    }

    <div className="history-panel">
      <div className="history-title">Scan History</div>
      <ul className="scan-history">
        {[...scanResults].reverse().map((scanResult, index) => (
          <li key={index} className="history-item">
            <span className="item-data">ID: {scanResult.id}, {scanResult.firstName} {scanResult.lastName}</span>
            <span className="item-timestamp">{scanResult.timestamp.toLocaleString()}</span>
          </li>
        ))}
      </ul>
    </div>

      <NotificationContainer/>
    </div>
  );
};

export default QRCodeScanner;