import React, { useState } from 'react';
import CustomerStats from './customers/customerStats';
import OrderStats from './orders/orderStats';
import SurveyStats from './survey/SurveyStats';
import ProductStats from './products/ProductStats';
import { Box, Tabs, Tab } from '@mui/material';
import Header from 'components/Header';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function StatsDB() {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ width: '100%',  mt: 4 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', ml: 6 }}>
            <Box mt={4} mb={4}>
                <Header
                title={`Statistik`}
                subtitle="Välj mellan kundstatistik eller orderstatistik."
                />
            </Box>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Orderstatistik" {...a11yProps(0)} sx={{ textTransform: 'none', fontWeight: 'bold'}}/>
                    <Tab label="Kundstatistik" {...a11yProps(1)} sx={{ textTransform: 'none', fontWeight: 'bold'}}/>
                    <Tab label="Enkätsvar" {...a11yProps(2)} sx={{ textTransform: 'none', fontWeight: 'bold'}}/>
                    <Tab label="Produkter" {...a11yProps(3)} sx={{ textTransform: 'none', fontWeight: 'bold'}}/>

                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <OrderStats />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <CustomerStats />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <SurveyStats />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                <ProductStats />
            </TabPanel>
        </Box>
    );
}

export default StatsDB;
