import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';

const addLineItem = async ( orderId, newLineItemData, updateToken, logoutUser) => {
    console.log("orderId" + orderId)
    try {
      const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
      console.log("Sending new line item data:", newLineItemData); // Log the data being sent
      const response = await fetch(`${baseUrl}/webhook/add/lineitems/${orderId}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newLineItemData),
        credentials: 'include',
      });
  

    if ((response.status === 401 || response.status === 403) && !response.headers.get('token-expired')) {
        await updateToken();
      return addLineItem(orderId, newLineItemData, updateToken, logoutUser);
    } else if ((response.status === 401 || response.status === 403) && response.headers.get('token-expired')) {
        logoutUser();
      throw new Error('Authentication failed. Logging out.');
    }

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  } catch (error) {
    console.error('Error adding line item:', error);
    throw error;
  }
};

export const useAddLineItem = () => {
  const { updateToken, logoutUser } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const mutation = useMutation(({ orderId, newLineItem }) => addLineItem(orderId, newLineItem, updateToken, logoutUser), {
    onSuccess: () => {
      queryClient.invalidateQueries('orders');
    },
  });

  return mutation;
};
