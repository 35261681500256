import { getTotalPrice } from "./getTotalPrice.js";
import { getDeliveryDate, getDeliveryDay, getDeliveryTimeRange, getStoreLocation } from "./getNoteAttributes.js";
import data from "data/data.json" 


function getDeliveryCost(order) {
  var shopName = localStorage.getItem("shopName");
  const shopData = data.find(entry => entry.store === shopName);

  if (!shopData){
    return 0;
  }

  if (order.financial_status !== "paid"){
    return shopData.price;
  }
  return 0;
}




async function sendPDFGenerationRequestToBackend(id, order, storeName) {
  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';


  console.log("THE ORDDER", order.billing_address)
  console.log("YOOOO BITCH", order.line_items)


  // Serialize the line items and additional information into query parameters
  const queryParams = new URLSearchParams({
    order_id: id,
    line_items: JSON.stringify(order?.line_items?.map(item => ({
      name: item.name,
      price: item.price,
      quantity: item.quantity,
      vendor: item.vendor,
      comment: item.comment,
      total_price: (item.price * item.quantity) - (item.total_discount || 0),
      added_by_store: item.added_by_store,
    }))),
    storeName: storeName,
    total: getTotalPrice(order),
    email: order.customer?.email,
    phone: order.customer?.phone || "Ej angivet",
    name: `${order.customer?.first_name} ${order.customer?.last_name}`,
    deliveryDate: getDeliveryDate(order),
    deliveryDay: getDeliveryDay(order),
    deliveryTimeRange: getDeliveryTimeRange(order),
    paid: order.financial_status === 'paid' ? 'Ja' : 'Nej',
    note: order.note,
    delivery_cost: getDeliveryCost(order),
    store_location: getStoreLocation(order),
    order_date: order.created_at
  }).toString();

  console.log(queryParams)

  try {
    const response = await fetch(`${baseUrl}/reports/order-pdf/?${queryParams}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/pdf',
      },
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to send PDF generation request to backend');
    }

    // Instead of downloading the PDF, open it in a new tab for preview
    const blob = await response.blob();
    console.log(blob); // Should log a Blob object
    return blob;
  } catch (error) {
    console.error('Error sending PDF generation request to backend:', error);
  }
}



async function generatePDF(id, order, storeName) {
  //pdfMake.createPdf(docDefinition).download(id, order, storeName);
  const blob = await sendPDFGenerationRequestToBackend(id, order, storeName);
  return blob;
}


export async function displayPDFs(blob) {
  const pdfURL = URL.createObjectURL(blob);
    const pdfWindow = window.open(pdfURL, '_blank');
    if (!pdfWindow) {
        alert('Unable to open a new window. Please check your popup settings.');
    }
  
}

export async function mergePDFsOnServer(pdfBlobs) {
  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
  const formData = new FormData();

  // Append each PDF Blob to the FormData
  pdfBlobs.forEach((blob, index) => {
    formData.append('pdfs', blob, `document-${index}.pdf`);
  });

  try {
    const response = await fetch(`${baseUrl}/reports/merge-pdfs/`, {
      method: 'POST',
      body: formData,
      // Don't set 'Content-Type': 'multipart/form-data', fetch does it automatically
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Get the merged PDF blob from the response
    const mergedPdfBlob = await response.blob();
    return mergedPdfBlob; // This Blob can be displayed in an iframe or downloaded
  } catch (error) {
    console.error('Error merging PDFs on server:', error);
  }
}

export function printPDF(pdfBlob) {
  const pdfUrl = URL.createObjectURL(pdfBlob);

  // Create an invisible iframe and append it to the body
  const iframe = document.createElement('iframe');
  iframe.style.visibility = 'hidden';
  iframe.style.position = 'fixed';
  iframe.style.right = '0';
  iframe.style.bottom = '0';
  iframe.src = pdfUrl;
  document.body.appendChild(iframe);

  iframe.onload = () => {
          iframe.contentWindow.focus();
          iframe.contentWindow.print(); // This should open the native print dialog
  };
}

export default generatePDF;