import React from 'react';
import { Grid } from '@mui/material';
import StatsBox from 'components/StatBox'; // Adjust the import path as necessary
import PeopleIcon from '@mui/icons-material/People'; // Import icons you need
import MarketingIcon from '@mui/icons-material/LocalOffer'; // Example icon for marketing
import RateReviewIcon from '@mui/icons-material/RateReview'; // Example icon for rate

function CustomerStatsCards({ totalCustomers, customersAcceptingMarketing }) {
  // Calculate the marketing acceptance rate
  const marketingAcceptanceRate = totalCustomers > 0 
    ? (customersAcceptingMarketing / totalCustomers * 100).toFixed(2) 
    : 0;

  return (
    <Grid container spacing={3} justifyContent="center">
      {/* Total Customers Box */}
      <Grid item xs={12} sm={6} md={4}>
        <StatsBox
          title="Totalt antal kunder"
          value={totalCustomers}
          color="#2B545A" // Text color
          icon={PeopleIcon} // Icon component
        />
      </Grid>

      {/* Customers Accepting Marketing Box */}
      <Grid item xs={12} sm={6} md={4}>
        <StatsBox
          title="Nyhetsbrev prenumeranter"
          value={customersAcceptingMarketing}
          color="#2B545A" // Text color
          icon={MarketingIcon} // Icon component
        />
      </Grid>

      {/* Marketing Acceptance Rate Box */}
      <Grid item xs={12} sm={6} md={4}>
        <StatsBox
          title="Andel som prenumererar"
          value={`${marketingAcceptanceRate}%`}
          color="#2B545A" // Text color
          icon={RateReviewIcon} // Icon component
        />
      </Grid>
    </Grid>
  );
}

export default CustomerStatsCards;
