import React, { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import GetAllOrdersDB from 'Hooks/Orders/getAllOrdersDb';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteOrder } from 'Hooks/Orders/delOrder';
import OrderGridToolbar from 'pages/Landing/table/components/OrderGridToolbar';
import EditIcon from '@mui/icons-material/Edit'; // Import the edit icon
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { useLocation } from 'react-router-dom'; // Import useLocation
import { format, addDays, startOfToday } from 'date-fns';

import { useUpdateOrders } from 'Hooks/Orders/updateOrdersDB';
import AuthContext from 'context/AuthContext';

export default function NewOrdersGrid() {
    const { orders, isLoading, isError } = GetAllOrdersDB("completed");
    const [rows, setRows] = useState([]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const updateOrders = useUpdateOrders();
    const deleteOrder = useDeleteOrder();
    const navigate = useNavigate(); // Initialize navigate function
    const location = useLocation(); // Get the current location

    let { user } = useContext(AuthContext);



    useEffect(() => {
        if (!isLoading && !isError && orders) {
          let formattedOrders = orders.map(order => {
            const leveransdatum = order.note_attributes.find(attr => attr.name === 'Leveransdatum')?.value || '';
            const store_location = order.note_attributes.find(attr => attr.name === 'Upphamtningsplats')?.value || '';
            const leveranstid = order.note_attributes.find(attr => attr.name === 'Leveranstid')?.value || '';
            const date = `${leveransdatum}, ${leveranstid}`;
            return {
              id: order.shopify_order_id,
              firstName: order.customer.name.split(" ")[0] || '',
              lastName: order.customer.name.split(" ")[1] || '',
              date: date,
              leveransdatum: leveransdatum, // store the date for sorting
              store_location: store_location
            };
          }).filter(order => {
            const storeFilterMatch = order.store_location === user?.restricted_store_name;
              return storeFilterMatch;
          })
          // Convert the 'dd/mm/yyyy' date string to 'yyyy-mm-dd' format for sorting
          formattedOrders.sort((a, b) => {
            const dateA = a.leveransdatum.split('/').reverse().join('-');
            const dateB = b.leveransdatum.split('/').reverse().join('-');
            return new Date(dateA) - new Date(dateB);
          });
          console.log(formattedOrders);
          setRows(formattedOrders);
        }
      }, [orders, isLoading, isError, user?.restricted_store_name]); // Add 'user?.restricted_store_name' to dependencies
      
      const handleEdit = (id) => {
        navigate(`${location.pathname}/${id}`); // Append order ID to the current path
    };

      if (isLoading) {
        return <h2>Loading...</h2>;
      }

      const handleDelete = (id) => {
        deleteOrder.mutate(id);
      };
      
      const handleOptionTwo = () => {
        console.log('Selected order IDs to update (before mutation):', selectedRowIds);
        updateOrders.mutate({
            selectedOrderIds: selectedRowIds,
            newStatus: 'delivered'
        });
    };

  

    const filterOrders = (selectedStoreFilter, selectedDateFilter) => {
      const today = format(startOfToday(), 'dd/MM/yyyy');
      const tomorrow = format(addDays(new Date(), 1), 'dd/MM/yyyy');
      const nextWeek = format(addDays(new Date(), 7), 'dd/MM/yyyy');
  
      let filteredOrders = orders.map(order => {
          // Same logic as in useEffect to format each order
          const leveransdatum = order.note_attributes.find(attr => attr.name === 'Leveransdatum')?.value || '';
          const store_location = user?.user_type || '';
          const leveranstid = order.note_attributes.find(attr => attr.name === 'Leveranstid')?.value || '';
          const date = `${leveransdatum}, ${leveranstid}`;
          return {
              id: order.shopify_order_id,
              firstName: order.customer.name.split(" ")[0] || '',
              lastName: order.customer.name.split(" ")[1] || '',
              date: date,
              leveransdatum: leveransdatum,
              store_location: store_location
          };
      }).filter(order => {
          // Ensure 'leveransdatum' exists and is in correct format
          if (!order.leveransdatum || !isValidDate(order.leveransdatum)) {
              return false;
          }
  
          let dateFilterMatch = false;
          if (selectedDateFilter === 'tomorrow' && order.leveransdatum === tomorrow) dateFilterMatch = true;
          if (selectedDateFilter === 'today' && order.leveransdatum === today) dateFilterMatch = true;
          if (selectedDateFilter === 'nextWeek' && new Date(order.leveransdatum) <= new Date(nextWeek)) dateFilterMatch = true;
          if (selectedDateFilter === '') dateFilterMatch = true; // no date filter selected
  
          // Check store location match
          const storeFilterMatch = !selectedStoreFilter || order.store_location === selectedStoreFilter;
  
          return dateFilterMatch && storeFilterMatch;
      });
  
      setRows(filteredOrders);
  };


    if (isLoading) {
      return <h2>Laddar...</h2>;
    }
    
    
  
    
    // Helper function to validate date format
    const isValidDate = (dateStr) => {
        const regex = /^\d{2}\/\d{2}\/\d{4}$/; // Simple regex for 'dd/MM/yyyy' format
        return regex.test(dateStr) && !isNaN(new Date(dateStr).getTime());
    };


    const columns = [
        { field: 'id', headerName: 'ID', flex: 1, minWidth: 150 },
        {
          field: 'firstName',
          headerName: 'Förnamn',
          flex: 1,
          minWidth: 150,
        },
        {
          field: 'lastName',
          headerName: 'Efternamn',
          flex: 1,
          minWidth: 150,
        },
        {
          field: 'date',
          headerName: 'Datum',
          flex: 1,
          minWidth: 110,
        },
        {
          field: 'fullName',
          headerName: 'Namn',
          description: 'This column has a value getter and is not sortable.',
          sortable: false,
          flex: 1,
          minWidth: 160,
          valueGetter: (params) =>
            `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        {
          field: 'store_location',
          headerName: 'Butik',
          flex: 1,
          minWidth: 110,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={() => handleEdit(params.id)}
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => handleDelete(params.id)}
              />,
            ],
          },
    ];
    

    return (
        <Box sx={{ height: '75vh', width: '100%' }}> 
            <DataGrid
                rows={rows}
                density="compact" // Set the density to compact for smaller rows

                columns={columns}
                sx={{
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none',
                    },
                    '& .MuiDataGrid-cell:focus-within': {
                      outline: 'none',
                    },
                  }}
                  getRowId={(row) => row.id}
                checkboxSelection
                onRowSelectionModelChange={(newSelection) => {
                    console.log('New Selection:', newSelection);
                    setSelectedRowIds(newSelection);
                }}
                components={{
                    Toolbar: (props) => (
                        <OrderGridToolbar
                            {...props}
                            selectedRows={selectedRowIds}
                            handleOptionTwo={handleOptionTwo}
                            onFilterOrders={filterOrders}
                            orders={orders} // Make sure you pass the orders array here
                        />
                    ),
                }}
                disableSelectionOnClick
            />
        </Box>
    );
}