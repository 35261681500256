import { useQuery } from 'react-query';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';

const syncOrders = async (updateToken, logoutUser, retryCount = 0) => {
    const baseUrl = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'http://localhost:8000';
    const url = `${baseUrl}/webhook/DBcheck/`;
  
    const response = await fetch(url, {
      headers: {
        'Content-Type':'application/json',
      },
      credentials: 'include',
    });
  
    if ((response.status === 401 || response.status === 403) && retryCount < 1) {
      await updateToken();
      return syncOrders(updateToken, logoutUser, retryCount + 1);
    } else if ((response.status === 401 || response.status === 403) && retryCount >= 1) {
      logoutUser();
      throw new Error('Authentication failed. Logging out.');
    }
  
    const data = await response.json();
  
    return data;
  }
  
  export const UseSyncOrders = () => {  
      const { updateToken, logoutUser } = useContext(AuthContext);
  
      const { data: syncData, isLoading, isError } = useQuery('syncOrders', () => syncOrders(updateToken, logoutUser, 0), {
          retry: 1,
      });
  
      return { syncData, isLoading, isError };
  };
  
  export default UseSyncOrders;