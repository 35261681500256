import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Typography, Chip, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Card, CardContent } from '@mui/material';
import useGetEmails from '../../../Hooks/Email/useGetSentEmail';
// import MailOutlineIcon from '@mui/icons-material/MailOutline'; // An example icon


const EmailTable = () => {
  const { emails, isLoading, isError, error } = useGetEmails();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [currentEmailContent, setCurrentEmailContent] = React.useState('');
  const [currentEmailRecipient, setCurrentEmailRecipient] = React.useState('');
  const [currentEmailSubject, setCurrentEmailSubject] = React.useState('');
  const [currentEmailStatus, setCurrentEmailStatus] = React.useState('');

  const customColors = {
    success: '#4caf50', // A vibrant green
    error: '#f44336',   // A bright red
    unknown: '#9e9e9e'  // A neutral grey
  };


  if (isLoading) {
    return <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
             <CircularProgress />
           </Box>;
  }

  if (isError) {
    return <Typography color="error" align="center">{error.message}</Typography>;
  }

  const handlePreview = (email) => {
    setCurrentEmailContent(email.message);
    setCurrentEmailRecipient(email.recipient);
    setCurrentEmailSubject(email.subject);
    setCurrentEmailStatus(email.success ? 'Success' : 'Failed');
    setOpenDialog(true);
  };

  const handleResendEmail = async () => {
    // Placeholder function - implement API call to resend email
    console.log('Resending email...');
    // Close the dialog after attempting to resend
    setOpenDialog(false);
  };

  return (
    <Box pr={15} pl={15} pt={8}> {/* Add padding around the TableContainer */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
                <TableCell>Status</TableCell> {/* New column for status */}
              <TableCell>Mottagare</TableCell>
              <TableCell>Ämne</TableCell>
              <TableCell>Skickad</TableCell>
              <TableCell>Se mer</TableCell>
            </TableRow>
          </TableHead>
         <TableBody>
  {[...emails]?.reverse().map((email) => (
    <TableRow
      key={email.id} // Assuming each email has a unique 'id'
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        <Chip 
          label={email.success === true ? 'Success' : email.success === false ? 'Failed' : 'Unknown'}
          sx={{
            bgcolor: email.success === true ? customColors.success : email.success === false ? customColors.error : customColors.unknown,
            color: 'white',
            '& .MuiChip-label': {
              color: 'white',
            },
          }}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        {email.recipient}
      </TableCell>
      <TableCell> <Chip label={email.subject}/> </TableCell>
      <TableCell>{new Date(email.sent_at).toLocaleString()}</TableCell>
      <TableCell>
        <Button 
          variant="contained" 
          color="primary"
          onClick={() => handlePreview(email)}
          sx={{
            boxShadow: 'none',
            textTransform: 'none',
            padding: '6px 12px',
            lineHeight: 1.5,
            backgroundColor: 'secondary',
            '&:hover': {
              backgroundColor: '#3f51b5',
              boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
            },
          }}
        >
          Visa e-post
        </Button>
      </TableCell>
    </TableRow>
  ))}
</TableBody>

        </Table>
      </TableContainer>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
            <DialogTitle>Email Preview</DialogTitle>
            <DialogContent sx={{ overflow: "hidden" }}> {/* Adjust overflow for better appearance */}
                <Card variant="outlined" sx={{ maxWidth: 500, margin: "auto", backgroundColor: "#f5f5f5" }}> {/* Card as email body */}
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                    {currentEmailSubject}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                    To: {currentEmailRecipient}
                    </Typography>
                    <Chip 
                    label={currentEmailStatus}
                    color={currentEmailStatus === 'Success' ? 'success' : 'error'}
                    sx={{ marginBottom: 2 }}
                    />
                    <Box sx={{ border: "1px solid #e0e0e0", p: 2, backgroundColor: "#fff", borderRadius: "4px" }}> {/* Container for the email content */}
                    <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: currentEmailContent }} />
                    </Box>
                </CardContent>
                </Card>
                <DialogActions>
                    <Button onClick={handleResendEmail} color="primary">
                        Skicka e-postmeddelandet igen
                    </Button>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Stäng
                    </Button>
                    </DialogActions>
            </DialogContent>
            </Dialog>

    </Box>
  );
};

export default EmailTable;
