import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

function CategoriesComponent({ productCollections, allCollections, handleCollectionChange }) {
    console.log("🚀 ~ CategoriesComponent ~ allCollections:", allCollections)
    console.log("🚀 ~ CategoriesComponent ~ productCollections:", productCollections)
    
  return (
    <FormGroup>
      {allCollections.map((collection) => (
        <FormControlLabel
          key={collection.id} // Unique identifier
          control={
            <Checkbox
              checked={productCollections.includes(collection.id)} // Check if the product has this collection
              onChange={() => handleCollectionChange(collection.id)} // Logic to handle collection changes
              name={collection.title}
            />
          }
          label={collection.title}
        />
      ))}
    </FormGroup>
  );
}

export default CategoriesComponent;
