// StyledBox.jsx
import React from 'react';
import { Box } from '@mui/material';

const StyledBox = ({ children, sx }) => {
  return (
    <Box sx={{
      p: 2, // Adjust padding as needed
      borderRadius: '8px', // Rounded edges
      border: '1px solid #F4F5F6', // Gray border
      boxShadow: '0 4px 20px 0 rgba(0,0,0,0.05)', // Soft box shadow
      bgcolor: 'background.paper', // Adjust background color as needed
      mb: 2, // Margin bottom for spacing between boxes, if necessary
      ...sx, // Add any additional styles
    }}>
      {children}
    </Box>
  );
};

export default StyledBox;
