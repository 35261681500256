import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { themeSettings } from "theme";
import { svSE } from "@mui/x-data-grid";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import AddUserPage from "./pages/Users/AddUser/index.jsx";
import Layout from "./pages/Layout/Layout";

import Booked from "./pages/SavedOldPages/OrdersBooked/index.jsx";
import ProductList from "./pages/Products_Folder/Products/index.jsx";

import CateringOrdersCalendar from "pages/SavedOldPages/OrdersCalendar/index.jsx";
import EmployeePage from "./pages/Users/Employee/index.jsx";
import CateringChart from "./pages/Planning/orders/index.jsx";

import SearchPage from "./pages/Search/index.jsx";

import MyComponent from "./pages/Mobile/NewProduct/index.jsx";

import withAuthentication from "./components/ProtectedRoute";

import NotFoundPage from "./notFound.js";

import { checkAuthentication } from 'actions/authActions'; // Import the checkAuthentication action
import FeedbackForm from 'pages/FeedbackForm/index.jsx';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';



// NEW BACKEND

import AuthRoutes from './authRoutes';



const AuthenticatedCateringOrdersCalendar = withAuthentication(
  CateringOrdersCalendar
);
const AuthenticatedEmployeePage = withAuthentication(EmployeePage);
const AuthenticatedCateringChart = withAuthentication(CateringChart);
const AuthenticatedProductList = withAuthentication(ProductList);
const AuthenticatedSearchPage = withAuthentication(SearchPage);

const AuthenticatedBookedOrder = withAuthentication(Booked);

const AuthenticatedMyComponent = withAuthentication(MyComponent);

// scrolls to the top of the page
function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

// component that doesnt render anything.
// Makes us scroll to top within the app when switching pages
function ScrollToTop() {
  useScrollToTop();
  return null;
}

function App() {
  const mode = useSelector((state) => state.global.mode);
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL || 'http://localhost:8000'}/api/check-auth/`, {
                credentials: 'include',
            });

            const data = await response.json();

            if (data.isAuthenticated) {
                dispatch(checkAuthentication(data.user));
            }
        } catch (error) {
            console.error("Error checking authentication", error);
        } finally {
            setInitialized(true);
        }
    };

    checkAuth();
}, [dispatch]);

  const queryClient = new QueryClient();
  const theme = useMemo(() => createTheme(themeSettings(mode), svSE), [mode]);

  if (!initialized) {
    return null;
  }

  return (
    <div className="app">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ScrollToTop />
          <ThemeProvider theme={theme}>
            <CssBaseline />


            <Routes>
            <Route 
              path="feedback" 
              element={
                <GoogleReCaptchaProvider reCaptchaKey="6LeVFlMpAAAAAO09JV9cwBXwTwQfwh52bNHUSEP8">
                  <FeedbackForm />
                </GoogleReCaptchaProvider>
              } 
            />


            <Route path="*" element={<AuthRoutes />} />
              <Route path="*" element={<NotFoundPage />} /> {/* Not Found outside the layout */}

              <Route path="old" element={<Navigate to="/ostenssons/table" replace />} />

              <Route path="old/*" element={<Layout />}>
                <Route path="" element={<Navigate to="/home" replace />} />
                <Route path="calanader" element={<AuthenticatedCateringOrdersCalendar />} />
                <Route path="catering-stats" element={<AuthenticatedCateringChart />} />
                <Route path="products" element={<AuthenticatedProductList />} />
                <Route path="users" element={<AuthenticatedEmployeePage />} />
                <Route path="add-user" element={<AddUserPage />} />
                <Route path="search" element={<AuthenticatedSearchPage />} />
                <Route path="add-product" element={<AuthenticatedMyComponent />} />
                <Route path="bokforing" element={<AuthenticatedBookedOrder />} />
              </Route>




            </Routes>
          </ThemeProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;