import { useMutation } from 'react-query';

// Function to submit survey data to the server
const submitSurveyResponse = async (surveyData) => {
  console.log("Submitting Survey Response:", JSON.stringify(surveyData, null, 2));
  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';

  try {
    const response = await fetch(`${baseUrl}/survey/submit-survey/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(surveyData),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  } catch (error) {
    console.error('Error submitting survey:', error);
    throw error;
  }
};

// Custom hook for submitting survey
export const useSubmitSurvey = () => {
  const mutation = useMutation(submitSurveyResponse);

  return mutation;
};
