import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';

import LoadingComponent from "components/LoadingComponent.jsx";
import ErrorComponent from "components/ErrorComponent.jsx";
import Header from "components/Header";

import { styled } from '@mui/system';
import { 
  Button,
  ButtonGroup, 
  Box, 
  Typography,
  Divider,  
} from '@mui/material';
import { useTheme } from "@mui/material/styles";

import { getTotalPrice } from "utilities/getTotalPrice.js";

import GetAllOrdersDB from "Hooks/Orders/getAllOrdersDb.js";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#ffffff",
          padding: "10px",
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
        }}
      >
        <p className="label" style={{ margin: 0 }}>{`${label}`}</p>
        <p>Försäljning: {(Math.round(payload[0].value) * 100) / 100} kr</p>
        <p>
          Antal beställningar:{" "}
          {(Math.round(payload[0].payload.count) * 100) / 100} st
        </p>
      </div>
    );
  }
  return null;
};

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  margin: theme.spacing(2),
}));

const StyledButton = ({ isActive, ...props }) => (
  <Button
    {...props}
    sx={{
      textTransform: "none",
      fontWeight: "bold",
      backgroundColor: isActive ? "green.main" : undefined,
      color: isActive ? "common.white" : "text.primary",
      border: isActive ? undefined : `1px solid green.main`,
      "&:hover": {
        backgroundColor: isActive ? "green.main" : undefined,
        color: isActive ? "common.white" : "text.primary",
        border: isActive ? undefined : `1px solid green.main`,
      },
    }}
  />
);

const processDataMonthly = (orders) => {
  return orders.reduce((acc, order) => {
    const deliveryDateMeta = order?.note_attributes?.find(
      (attr) => attr.name === "Leveransdatum"
    );

    if (!deliveryDateMeta) {
      console.log("No deliveryDateMeta found");
      return acc;
    }

    let date;
    if (!deliveryDateMeta.value) {
      // Handle empty or undefined date value (e.g., use the current date)
      date = new Date();
    } else {
      // Convert "DD/MM/YYYY" format to "YYYY-MM-DD"
      const [day, month, year] = deliveryDateMeta.value.split("/");
      date = new Date(`${year}-${month}-${day}`);

      if (isNaN(date.getTime())) {
        // Handle invalid date format
        console.log("Invalid date format:", deliveryDateMeta.value);
        // ... decide how to handle this case ...
        return acc; // or continue to the next iteration
      }
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const yearMonth = `${year}-${String(month).padStart(2, "0")}`;

    if (!acc[yearMonth]) {
      acc[yearMonth] = { count: 1, income: parseFloat(getTotalPrice(order))};
    } else {
      acc[yearMonth].count++;
      acc[yearMonth].income += parseFloat(getTotalPrice(order));
    }

    return acc;
  }, {});
};

const processDataWeekly = (orders) => {
  return orders.reduce((acc, order) => {
    const deliveryDateMeta = order.note_attributes.find(
      (attr) => attr.name === 'Leveransdatum'
    );

    if (!deliveryDateMeta) {
      console.log("No deliveryDateMeta found");
      return acc;
    }

    let date;
    if (!deliveryDateMeta.value) {
      // Handle empty or undefined date value (e.g., use the current date)
      date = new Date();
    } else {
      // Convert "DD/MM/YYYY" format to "YYYY-MM-DD"
      const [day, month, year] = deliveryDateMeta.value.split('/');
      date = new Date(`${year}-${month}-${day}`);
      
      if (isNaN(date.getTime())) {
        // Handle invalid date format
        console.log('Invalid date format:', deliveryDateMeta.value);
        return acc;
      }
    }

    const year = date.getFullYear();
    const weekNumber = getWeekNumber(date);
    const yearWeek = `${year}-W${String(weekNumber).padStart(2, '0')}`;
    if (!acc[yearWeek]) {
      acc[yearWeek] = { count: 1, income: parseFloat(getTotalPrice(order)) };
    } else {
      acc[yearWeek].count++;
      acc[yearWeek].income += parseFloat(getTotalPrice(order));
    }

    return acc;
  }, {});
};

// Helper function to get week number
function getWeekNumber(d) {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  const weekNumber = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  return weekNumber;
}

const fillMissingDates = (data, timeFrame, period) => {
  const result = [];
  let currentDate = new Date();
  const halfPeriod = Math.floor(period / 2);

  if (timeFrame === 'monthly') {
    currentDate.setMonth(currentDate.getMonth() - halfPeriod);
    currentDate.setDate(1);
  } else { // weekly
    currentDate.setDate(currentDate.getDate() - halfPeriod * 7);
    const diff = currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1);
    currentDate.setDate(diff);
  }

  for (let i = 0; i < period; i++) {
    const key = timeFrame === 'monthly'
      ? `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`
      : `${currentDate.getFullYear()}-W${String(Math.floor((currentDate - new Date(currentDate.getFullYear(), 0, 1)) / 604800000)).padStart(2, '0')}`;


    result.push({
      date: key,
      count: data[key]?.count || 0,
      income: data[key]?.income || 0,
    });

    if (timeFrame === "monthly") {
      currentDate.setMonth(currentDate.getMonth() + 1);
    } else {
      currentDate.setDate(currentDate.getDate() + 7);
    }
  }

  return result;
};


const CateringChart = () => {
  const theme = useTheme();

  const [timeFrame, setTimeFrame] = useState("monthly");
  const [period, setPeriod] = useState(8);
  const {
    orders: cateringOrders,
    isLoadingA,
    isErroAr,
  } = GetAllOrdersDB("", false, null);

  //const { data: store, isLoading: isStoreLoading, isError: isStoreError } = useGetMyStoreQuery();
  //const storeId = store?._id;
  //const { data: cateringOrders, isLoading, isError } = useGetCateringOrdersQuery(storeId);

  const processedData = cateringOrders
    ? timeFrame === "monthly"
      ? processDataMonthly(cateringOrders)
      : processDataWeekly(cateringOrders)
    : {};

  const chartData = fillMissingDates(processedData, timeFrame, period);

  if (isLoadingA) {
    return <LoadingComponent />;
  }
  if (isErroAr) {
    return <ErrorComponent error={isErroAr} />
  }

  // if (isError || isStoreError) {
  //   return <div>Error occurred while fetching data.</div>;
  // }

  return (
    <Box sx={{ p: 4 }}>
      <Box style={{ marginTop: "20px", marginLeft: "20px" }}>
        <Header
          title={"Ordrar"}
          subtitle="Se antalet ordrar som har och förväntas levereras"
          style={{ color: theme.palette.textColor.main }}
        />
      </Box>
      <Divider
        sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
      />
      <Box sx={{ mb: 4 }}>
        <StyledButtonGroup>
          <StyledButton
            isActive={timeFrame === "monthly"}
            onClick={() => setTimeFrame("monthly")}
          >
            Månad
          </StyledButton>
          <StyledButton
            isActive={timeFrame === "weekly"}
            onClick={() => setTimeFrame("weekly")}
          >
            Vecka
          </StyledButton>
        </StyledButtonGroup>
      </Box>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={chartData}
          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
          <XAxis dataKey="date" tick={{ fontSize: 12 }} stroke="#363636" />
          <YAxis
            yAxisId="left"
            orientation="left"
            tick={{ fontSize: 12 }}
            stroke="#363636"
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            tick={{ fontSize: 12 }}
            stroke="#363636"
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar
            yAxisId="left"
            dataKey="income"
            fill="#870017"
            name="Försäljning"
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={"#870017"} />
            ))}
          </Bar>
          <Bar yAxisId="right" dataKey="count" fill="#E00400" name="Caterings">
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={"#E00400"} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5">Period:</Typography>
        <StyledButtonGroup>
          {timeFrame === "monthly"
            ? [8, 12, 24].map((value) => (
                <StyledButton
                  key={value}
                  isActive={period === value}
                  onClick={() => setPeriod(value)}
                >
                  {value} Månader
                </StyledButton>
              ))
            : [4, 8, 12, 26, 52].map((value) => (
                <StyledButton
                  key={value}
                  isActive={period === value}
                  onClick={() => setPeriod(value)}
                >
                  {value} Veckor
                </StyledButton>
              ))}
        </StyledButtonGroup>
      </Box>
    </Box>
  );
};

export default CateringChart;
