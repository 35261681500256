import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { Link } from "react-router-dom";

import EyeIcon from "@mui/icons-material/Visibility";
import GetAllOrders from "../../../Hooks/Old/GetAllOrders.js";
import { styled } from "@mui/system";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import LoadingComponent from "components/LoadingComponent.jsx";
import ErrorComponent from "components/ErrorComponent.jsx";
import { getTotalPrice } from "utilities/getTotalPrice.js";

const SearchPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [foundOrders, setFoundOrders] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const theme = useTheme();
  const location = useLocation();

  const {
    orders: cateringOrders,
    isLoading: isLoadingOrders,
    isError: isErrorOrders,
  } = GetAllOrders();

  const isErrorAny =  isErrorOrders;

  const doSearch = () => {
    if (cateringOrders) {
      const orders = cateringOrders.filter(
        (order) =>
          order.id.toString() === searchTerm ||
          order.contact_email.toLowerCase() === searchTerm
      );
      setFoundOrders(orders);
      setSearchPerformed(true);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    doSearch();
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const term = urlParams.get("term");

    if (term) {
      const lowerCaseTerm = term.toLowerCase();
      setSearchTerm(lowerCaseTerm);
    }

    if (cateringOrders && term) {
      const lowerCaseTerm = term.toLowerCase();
      const orders = cateringOrders.filter((order) => {
        return (
          order.id.toString().toLowerCase() === lowerCaseTerm ||
          order.contact_email.toLowerCase() === lowerCaseTerm
        );
      });
      setFoundOrders(orders);
      setSearchPerformed(true);
    }
  }, [location, cateringOrders]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const StyledTableHeader = styled(TableHead)(() => ({
    backgroundColor: theme.palette.green.main,
  }));

  const StyledTableHeaderCell = styled(TableCell)(() => ({
    color: "white",
    fontWeight: "bold",
    fontSize: "0.7rem", // Decreased font size
    align: "center",
  }));

  if (isLoadingOrders) {
    return <LoadingComponent />;
  }

  if (isErrorAny) {
    return <ErrorComponent error={isErrorAny} />;
  }

  return (
    <Container maxWidth="md" sx={{ marginTop: "4rem" }}>
       <Typography
          variant="h3"
          component="h3"
          fontWeight="bold"
          gutterBottom
          sx={{ color: theme.palette.textColor.main }}
        >
          Sök efter order-ID eller email
      </Typography>

      {/* Change maxWidth to 'md' */}
      <form onSubmit={handleSearch} style={{ marginTop: "20px" }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Sök igenom ID eller Email..."
          InputProps={{ endAdornment: <Search /> }}
          value={searchTerm}
          onChange={handleInputChange}
          autoComplete="off"
        />
        <Button
          type="submit"
          variant="contained"
          sx={{
            marginTop: "1rem",
            backgroundColor: "#C00020", // The color you want
            "&:hover": {
              backgroundColor: "#79A8A9", // You may want to darken this color on hover for a nice effect
              color: "#ffffff",
            },
            color: "white", // text color
            textTransform: "none",
            fontWeight: "bold",
          }}
        >
          Sök
        </Button>
      </form>
      {searchPerformed && isLoadingOrders ? (
        <LoadingComponent />
        ) : foundOrders.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{ marginTop: "1rem", maxWidth: "100%", overflow: "auto" }}
        >
          <Table sx={{ minWidth: "100%" }} aria-label="simple table">
            <StyledTableHeader>
              <TableRow>
                <StyledTableHeaderCell>ID</StyledTableHeaderCell>
                <StyledTableHeaderCell>Namn</StyledTableHeaderCell>
                <StyledTableHeaderCell>Totalt</StyledTableHeaderCell>
                <StyledTableHeaderCell align="right">
                  Order
                </StyledTableHeaderCell>
              </TableRow>
            </StyledTableHeader>
            <TableBody>
              {foundOrders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell component="th" scope="row">
                    {order.id}
                  </TableCell>
                  <TableCell>
                    {order.customer?.first_name} {order.customer?.last_name}
                  </TableCell>
                  <TableCell>{getTotalPrice(order)}</TableCell>
                  <TableCell align="center">
                    <Box
                      component={Link}
                      to={`/catering/${order.id}/edit`}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "32px", // adjust as needed
                        height: "32px", // adjust as needed
                        borderRadius: "16px", // half of width/height for a circle
                        backgroundColor: "#E6E6E6",
                        margin: "0 0 0 15px", // top, right, bottom, left. 'auto' on the left pushes the box to the right
                        color: "#616161",
                      }}
                    >
                      <EyeIcon color="#616161" />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : searchPerformed && !isLoadingOrders ? (
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ marginTop: "1rem" }}
          >
            Inga beställningar hittades!
          </Typography>
      ) : null}
    </Container>
  );
};

export default SearchPage;
