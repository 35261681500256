import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import {
  Grid,
  Typography,
  TextField,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import StyledBox from "components/StyledBox";
import { CustomDroppable } from "./CustomDroppable";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"; // Import the icon

function VariantComponent({
  title,
  variants,
  handleChangeField,
  setIsHelpModalOpen,
}) {
  const [variantList, setVariantList] = useState(variants);

  useEffect(() => {
    setVariantList(variants);
  }, [variants]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
  
    const newVariantList = Array.from(variantList);
    const [reorderedItem] = newVariantList.splice(result.source.index, 1);
    newVariantList.splice(result.destination.index, 0, reorderedItem);
  
    // After reordering, update the position of each variant to match its new index
    const updatedVariantsWithPosition = newVariantList.map((variant, index) => ({
      ...variant,
      position: index + 1, // Assuming you want position to be 1-indexed
    }));
  
    setVariantList(updatedVariantsWithPosition);
    handleChangeField("variants", updatedVariantsWithPosition);
  };

  const addNewVariant = () => {
    // Generate a pseudo ID (e.g., a unique string or a random value)
    const pseudoId = `temp_${Math.random().toString(36).substring(7)}`;
  
    const newVariant = {
      id: pseudoId.toString(), // Use the pseudo ID here
      title: "", // You can set default values here
      price: "",
      compare_at_price: "",
    };
  
    const newVariants = [...variantList, newVariant];
    setVariantList(newVariants);
    handleChangeField("variants", newVariants);
  };

  return (
    <>
  {variants.length > 1 ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <CustomDroppable droppableId="variants-droppable">
        {(provided) => (
          <Grid container {...provided.droppableProps} ref={provided.innerRef}>
            {variantList.map((variant, index) => (
              <Draggable
                key={variant.id}
                draggableId={variant.id.toString()}
                index={index}
              >
                {(provided) => (
                  <Grid
                    item
                    xs={12}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <Box
                      sx={{ marginBottom: 2 }}
                      display="flex"
                      alignItems="center"
                      width="100%"
                    >
                      <IconButton {...provided.dragHandleProps}>
                        <DragIndicatorIcon />
                      </IconButton>
                      <StyledBox sx={{ flexGrow: 1 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sx={{ mt: 3 }}>
                            <Typography>
                              <b>Variant</b>
                            </Typography>
                            <Box
                              display="flex"
                              alignItems="center"
                              sx={{ gap: 1 }}
                            >
                              <Box sx={{ width: "auto", flexShrink: 1 }}>
                                <TextField
                                  value={variant.title}
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) =>
                                    handleChangeField(
                                      "title",
                                      e.target.value,
                                      index
                                    )
                                  }
                                  sx={{ width: "100%" }} // Ensures TextField takes up the width of its Box container
                                />
                              </Box>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                  const newVariants = [...variants];
                                  newVariants.splice(index, 1);
                                  handleChangeField("variants", newVariants);
                                }}
                                sx={{
                                  textTransform: "none",
                                }}
                              >
                                Ta bort
                              </Button>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                              <b>Pris</b> (kampanjpris om ett{" "}
                              <b>Struket pris</b> är satt högre):
                            </Typography>
                            <TextField
                              value={variant?.price ? variant.price : ""}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^[0-9]*\.?[0-9]*$/.test(value)) {
                                  handleChangeField("price", value, index);
                                }
                              }}
                              fullWidth
                              autoComplete="off"
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="subtitle1"
                              color="text.secondary"
                              gutterBottom
                            >
                              Struket pris:
                            </Typography>
                            <TextField
                              value={
                                variant?.compare_at_price
                                  ? variant.compare_at_price
                                  : ""
                              }
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^[0-9]*\.?[0-9]*$/.test(value)) {
                                  handleChangeField(
                                    "compare_at_price",
                                    value,
                                    index
                                  );
                                }
                              }}
                              fullWidth
                              autoComplete="off"
                              size="small"
                            />
                          </Grid>
                        </Grid>
                      </StyledBox>
                    </Box>
                  </Grid>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </CustomDroppable>
    </DragDropContext>
  ) : (
    <StyledBox sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            <b>{title}</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Pris (kampanjpris om ett <b>Struket pris</b> är satt högre):
          </Typography>
          <TextField
            value={variants[0]?.price ? variants[0].price : ""}
            onChange={(e) => handleChangeField("price", e.target.value, 0)}
            fullWidth
            autoComplete="off"
            size="small"
          />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Struket pris:
          </Typography>
          <TextField
            value={
              variants[0]?.compare_at_price ? variants[0].compare_at_price : ""
            }
            onChange={(e) =>
              handleChangeField("compare_at_price", e.target.value, 0)
            }
            fullWidth
            autoComplete="off"
            size="small"
          />
        </Grid>
      </Grid>
    </StyledBox>
  )}

  <Button
      variant="outlined"
      color="primary"
      onClick={addNewVariant}
    >
      Add Variant
    </Button>

  </>
  );
}

export default VariantComponent;
