import React, { useState, useContext } from "react";
import {
  Search,
} from "@mui/icons-material";

import FlexBetween from "components/FlexBetween";
import { useDispatch } from "react-redux";
import { setMode } from "state/globalSlice";
//import profileImage from "assets/profile.jpeg";
import {
  AppBar,
  Button,
  Box,
  Typography,
  IconButton,
  InputBase,
  Toolbar,
  Menu,
  MenuItem,
  useTheme,
  ListItemButton,
} from "@mui/material";
import { PeopleOutline as UsersIcon } from "@mui/icons-material";

import Avatar from "@mui/material/Avatar";

import Tooltip from "@mui/material/Tooltip";

import { ListItemText } from "@mui/material";
import Popover from "@mui/material/Popover";

import { useNavigate } from "react-router-dom";

import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";

import { withStyles } from "@mui/styles";

import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";

import TooltipIcon from "./TooltipIcon";

import AuthContext from "context/AuthContext";

const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#333",
    color: "#fff",
    fontSize: "12px",
    borderRadius: "4px",
    padding: "8px 12px",
  },
}))(Tooltip);

const Navbar = ({ user, onLogout }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  let { logoutUser } = useContext(AuthContext);

  const [anchorElUsers, setAnchorElUsers] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");

  const handleClickUsers = (event) => {
    setAnchorElUsers(event.currentTarget);
  };

  const handleCloseUsers = () => {
    setAnchorElUsers(null);
  };

  // Add handleInputChange function
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    // navigate to the /search route with the search term as a URL parameter
    navigate(`/search?term=${searchTerm}`);
  };

  const openUsers = Boolean(anchorElUsers);
  const idUsers = openUsers ? "simple-popover" : undefined;

  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const handleCloseSettings = () => setAnchorElSettings(null);
  const openSettings = Boolean(anchorElSettings);

  const [anchorElHelp, setAnchorElHelp] = useState(null);
  const handleClickHelp = (event) => setAnchorElHelp(event.currentTarget);
  const handleCloseHelp = () => setAnchorElHelp(null);
  const openHelp = Boolean(anchorElHelp);

  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const handleClickProfile = (event) => setAnchorElProfile(event.currentTarget);
  const handleCloseProfile = () => setAnchorElProfile(null);
  const openProfile = Boolean(anchorElProfile);

  const handleEmail = () => {
    window.open(
      "mailto:info@shopflow.se",
      "_blank"
    );
  };

  const handleLogout = () => {
    //onLogout();
    logoutUser();
  };

  const navigateToExternalUrl = () => {
    window.open(
      "https://dokumentation.notion.site/Shopflow-Systems-dokumentation-6860216f925c413d98146e2a097ee984?pvs=4",
      "_blank"
    );
  };

  const handleAddUser = () => {
    handleCloseUsers(); // This closes the popover
    navigate("/add-user"); // navigate to add user route
  };

  const handleAllUsers = () => {
    handleCloseUsers(); // This closes the popover
    navigate("/profile"); // navigate to users route
  };

  const dispatch = useDispatch();

  const handleSidebarToggle = async () => {
    const currentMode = localStorage.getItem("openSideBar");
    const newMode = currentMode === "true" ? "false" : "true";
    localStorage.setItem("openSideBar", newMode);

    // get the user id from the context or local storage
    const userId = localStorage.getItem("user");

    const userJson = JSON.parse(userId);

    const baseUrl = process.env.REACT_APP_BASE_URL
      ? `${process.env.REACT_APP_BASE_URL}`
      : "http://localhost:8000";

    try {
      const response = await fetch(
        `${baseUrl}auth/updateSideBar/${userJson._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ openSideBar: newMode === "true" }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update sidebar state");
      }
    } catch (error) {
      console.error(error);
      // handle the error, you may want to set some state to indicate that the request failed
    }
  };

  const onClickAddUser =
    user && user.user_type === "admin"
      ? handleAddUser
      : null;
  const addUserDisabled =
    !user || !user.user_type || user.user_type !== "admin";


  return (
    <AppBar
      sx={{
        position: "static",
        background: "#FFFFFF",
        boxShadow: "none",
        borderBottom: "1px solid #DCDCDC",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <FlexBetween
            backgroundColor={theme.palette.background.main}
            borderRadius="9px"
            gap="3rem"
            p="0.1rem 2rem"
          >
            {/* Wrap the InputBase and IconButton in a form */}
            <form onSubmit={handleSearch}>
              <InputBase
                placeholder="Sök med id eller mail..."
                value={searchTerm}
                onChange={handleInputChange}
              />
              <IconButton type="submit">
                <Search />
              </IconButton>
            </form>
          </FlexBetween>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          <Box sx={{ display: "flex", alignItems: "center" }}></Box>
          {/* 
          <TooltipIcon tooltipText="Hem" onClick={() => navigate("/home")}>
            <HomeOutlinedIcon sx={{ fontSize: "25px" }} />
          </TooltipIcon> */}

          <TooltipIcon
            tooltipText="Bokföring"
            onClick={() => navigate("/bokforing")}
          >
            <AssignmentTurnedInOutlinedIcon sx={{ fontSize: "20px" }} />
          </TooltipIcon>

          <IconButton onClick={handleClickHelp}>
            <MenuBookOutlinedIcon />
          </IconButton>
          <Popover
            open={openHelp}
            anchorEl={anchorElHelp}
            onClose={handleCloseHelp}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box sx={{ padding: 2 }}>
              <ListItemButton onClick={navigateToExternalUrl}>
                <ListItemText primary="Dokumentation" />
              </ListItemButton>
              <Box>
                <ListItemButton onClick={handleEmail}>
                  <ListItemText primary="Kundtjänst" />
                </ListItemButton>
              </Box>
            </Box>
          </Popover>

          <IconButton onClick={handleClickUsers}>
            <UsersIcon sx={{ fontSize: "25px" }} />
          </IconButton>
          <Popover
            id={idUsers}
            open={openUsers}
            anchorEl={anchorElUsers}
            onClose={handleCloseUsers}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box sx={{ padding: 2 }}>
              <ListItemButton onClick={handleAllUsers}>
                <ListItemText primary="Min profil" />
              </ListItemButton>
              <Box>
                <StyledTooltip
                  title={addUserDisabled ? "Du har ej behörighet" : ""}
                >
                  <span>
                    <ListItemButton
                      disabled={addUserDisabled}
                      onClick={onClickAddUser}
                    >
                      <ListItemText primary="Lägg till användare" />
                    </ListItemButton>
                  </span>
                </StyledTooltip>
              </Box>
            </Box>
          </Popover>

          {/* <IconButton onClick={handleClickSettings}>
            <SettingsOutlined sx={{ fontSize: "25px" }} />
          </IconButton> */}

          <Menu
            anchorEl={anchorElSettings}
            open={openSettings}
            onClose={handleCloseSettings}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MenuItem onClick={() => dispatch(setMode())}>
              {theme.palette.mode === "dark" ? "Light Mode" : "Dark Mode"}
              {/* {theme.palette.mode === "dark" ? <LightModeOutlined /> : <DarkModeOutlined />}  */}
            </MenuItem>
            <MenuItem onClick={handleSidebarToggle}>
              <ListItemText primary="Öppna/stäng sidebar" />
            </MenuItem>
          </Menu>

            <Button
              onClick={handleClickProfile}
              sx={{
                gap: 1,
                textTransform: "none",
                color: "#757575"
              }}
            >
             <Avatar
              sx={{ 
                width: 24, 
                height: 24,
                bgcolor: "#2B555A"
              }}
             />
                <Typography fontWeight="bold" fontSize="0.85rem" sx={{ color: "#2B555A"}}>
                  @{user?.username ? user?.username : "Min butik"}
                </Typography>
            </Button>
            <Popover
            open={openProfile}
            anchorEl={anchorElProfile}
            onClose={handleCloseProfile}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box sx={{ padding: 1 }}>
              <ListItemButton onClick={handleLogout}>
                <ListItemText primary="Logga ut"/> <ExitToAppRoundedIcon sx={{ml: "10px"}} />
              </ListItemButton>
            </Box>
          </Popover>
          </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
