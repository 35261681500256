import React, { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Chip,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";

import {
  StyledContainer,
  StyledOrderItem,
  StyledIconWrapper,
  StyledOrderDetail,
  CustomStepIconCircle,
  StyledStepperContainer,
  StyledAccordion,
  CustomStepIconCheck,
  StyledRow,
} from "./Style/Styles.jsx";

// HOOKS
import GetUpcomingOrders from "../../../Hooks/Old/useGetUpcommingOrders.js";
import useUpdateOrder from "Hooks/Old/useUpdateOrder.js";

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// COMPONENTS
import ErrorComponent from "components/ErrorComponent.jsx";
import LoadingComponent from "components/LoadingComponent.jsx";
import CustomSnackbar from "components/SnackBar.jsx";

// ICONS
import {
  ExpandMore as ExpandMoreIcon,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NewReleasesIcon from "@mui/icons-material/NewReleases";

// UTILS AND THEME
import translations from "translations.js";
import { getDeliveryDate } from "utilities/getNoteAttributes.js";
import { getStatusFromTags } from "utilities/getStatusFromTags.js";
import { colorForFinancialStatus } from "utilities/getStatusColor";
import { iconForFinancialStatus } from "utilities/getStatusFromTags";
import { getTotalPrice } from "utilities/getTotalPrice.js";
import { useTheme } from "@mui/material/styles";

const statusToSteps = (status) => {
  return ["Mottagen", "Accepterad", "Färdig", "Levererad"];
};

function CustomStepIcon(props) {
  const { active, completed } = props;

  return (
    <CustomStepIconCircle ownerState={{ active, completed }}>
      {completed ? <CustomStepIconCheck /> : null}
    </CustomStepIconCircle>
  );
}

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

function isErrorOrder(order) {
  if (!getDeliveryDate(order)) {
    return true;
  }
  return false;
}

function getDaysUntilDelivery(orderDateRaw) {
  const today = new Date();
  const differenceInTime = orderDateRaw - today;
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); 

  return differenceInDays;
}

function getCreatedDate(order) {
  const todayRaw = new Date();

  // the ISOstring makes it '2023-07-21' format. Makes it easier when comparing what to return!
  const today = todayRaw.toISOString().split("T")[0];
  const yesterdayRaw = new Date(todayRaw.setDate(todayRaw.getDate() - 1));
  const beforeyesterdayRaw = new Date(todayRaw.setDate(todayRaw.getDate() - 1));

  const yesterday = yesterdayRaw.toISOString().split("T")[0];
  const beforeyesterday = beforeyesterdayRaw.toISOString().split("T")[0];

  const orderDateRaw = new Date(order.created_at);
  const orderDate = orderDateRaw.toISOString().split("T")[0];

  if (orderDate === today) {
    return "Idag";
  } else if (orderDate === yesterday) {
    return "Igår";
  } else if (orderDate === beforeyesterday) {
    return "Förrgår";
  } else {
    const daysUntilDelivery = getDaysUntilDelivery(orderDateRaw);
    if (daysUntilDelivery === 1) {
      return "Imorgon"; // Tomorrow
    }
    return daysUntilDelivery * -1 + " dagar sedan"; // e.g., "5 days left"
  }
}

const formattedDate = (order) => {
  const date = new Date(order?.created_at);
  const formattedDate =
    date.getDate().toString().padStart(2, "0") +
    "/" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "/" + // Month is 0-based, so we add 1
    date.getFullYear();
  return formattedDate;
};

const MobileLandingPage = () => {
  document.body.style.backgroundColor = "white";
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const limit = 25; // Number of orders to fetch per page
  const [allOrders, setAllOrders] = useState([]);
  const navigate = useNavigate();
  const { updateOrder, open, setOpen } = useUpdateOrder();
  const [note, setNote] = useState("");
  const [noteEdited, setNoteEdited] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false); // Add this state at the top of your component

  let cateringOrders = [];

  const {
    upcomingOrders,
    errorOrders,
    isLoading: ordersLoading,
    isError: ordersError,
  } = GetUpcomingOrders();

  const filteredErrorOrders = errorOrders
    ? errorOrders.filter((order) => {
        const status = getStatusFromTags(order.tags);
        return status !== "Levererad";
      })
    : [];

  const isErrorAny = ordersError;
  const isLoadingAny = ordersLoading;
  const severity = isErrorAny ? "error" : "success";
  // concatinates both correct orders and wrong orders (missing leveransdatum)
  const co = [...(upcomingOrders || []), ...(filteredErrorOrders || [])];

  if (co) {
    cateringOrders = [...co].sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });
  }

  const handleSnackbarClose = () => {
    setOpen(false);
  };

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleIconClick = (orderId) => {
    navigate(`/mobile/edit/${orderId.id}`);
  };

  const handleStatusChange = async (order, newStatus) => {
    setIsUpdating(true); // Start by setting the updating status to true

    const ordersTags = order.tags;
    const tagsArray = ordersTags.split(", ");
    const statusIndex = tagsArray.findIndex((tag) => tag.startsWith("status:"));

    if (statusIndex > -1) {
      tagsArray[statusIndex] = `status: ${newStatus}`;
    } else {
      tagsArray.push(`status: ${newStatus}`);
    }

    const newTags = tagsArray.join(", ");
    const updateData = { tags: newTags };

    try {
      const updatedOrder = await updateOrder(order.id, updateData);

      // Update local state
      const updatedOrders = allOrders.map((o) => {
        if (o.id === updatedOrder.id) {
          // Find the order with the given ID
          return updatedOrder; // Return updated order
        }
        return o; // Return original order
      });

      // Update state with new order array
      setAllOrders(updatedOrders);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUpdating(false); // Set isUpdating back to false once the process has completed
    }
  };

  const handleNoteChange = (order, event) => {
    const newNote = event;

    setNote(newNote);
    setNoteEdited(newNote.trim() !== (order.note || "").trim());
  };

  const handleSaveNote = async (id) => {
    const updateData = {};
    if (noteEdited) {
      updateData.note = note;
    }

    try {
      await updateOrder(id, updateData);
      setNoteEdited(false);
    } catch (error) {
      console.error("Error updating order: ", error);
    }
  };

  const displayedOrders = allOrders.slice(0, page * limit);

  const deliveryDateFunction = (order) => {
    return getDeliveryDate(order)
      ? getDeliveryDate(order)
      : "Leveransdatum saknas!";
  };

  if (isLoadingAny) {
    return <LoadingComponent />;
  }

  if (isErrorAny) {
    return <ErrorComponent error={isErrorAny} />;
  }

  if (allOrders.length === 0 && cateringOrders && cateringOrders.length > 0) {
    setAllOrders(cateringOrders);
  }

  const colorMap = {
    Accepterad: theme.palette.status.accepterad,
    Färdig: theme.palette.status.fardig,
    Levererad: theme.palette.status.levererad,
    Mottagen: theme.palette.status.mottagen,
  };

  const getDropDownColor = (order) => {
    const tag = getStatusFromTags(order.tags);
    return colorMap[tag];
  };

  return (
    <StyledContainer maxWidth="sm">
      <Typography
        variant="h3"
        component="h1"
        align="center"
        style={{
          marginBottom: "1rem",  // Adjusted this for better spacing
          color: "#333",
          fontWeight: "bold",
          letterSpacing: "-0.5px",
        }}
      >
        Senaste beställningar
      </Typography>
      
      {/* New subtle text added below */}
      <Typography
        variant="body2"
        align="center"
        style={{
          marginBottom: "2rem",  // Providing space between the main title and the list
          color: "#888",  // A subtle color
        }}
      >
        Här visas de senaste lagda beställningarna
      </Typography>
      {displayedOrders.map((order) => (
        <StyledOrderItem key={order.id}>
          <StyledIconWrapper>
            <ArrowForwardIosRounded
              onClick={() => handleIconClick(order)}
              style={{ cursor: "pointer", marginTop: "5px", color: "black" }}
            />
          </StyledIconWrapper>

          <StyledOrderDetail
            variant="h5"
            style={{
              color: "#333333",
              fontWeight: "600",
              fontSize: "20px",
              marginBottom: "20px",
            }}
          >
            {order.billing_address?.first_name}{" "}
            {order.billing_address?.last_name}
          </StyledOrderDetail>
          <Box variant="body1" style={{ fontSize: "15px", marginBottom: 10 }}>
            {isErrorOrder(order) && (
              <Chip
                size="medium"
                label="Felaktig"
                icon={<NewReleasesIcon style={{ color: "#5D1615" }} />}
                sx={{
                  minWidth: "80px",
                  mr: "5px",
                }}
                style={{
                  backgroundColor: "#FFE2DD",
                  color: "#5D1615",
                }}
              />
            )}
            {getCreatedDate(order) && (
              <Chip
                size="medium"
                label={getCreatedDate(order)}
                icon={<AccessTimeIcon style={{ color: "#6F79C3" }} />}
                sx={{
                  minWidth: "80px",
                  mr: "5px",
                }}
                style={{
                  backgroundColor: "#E4E2F3",
                }}
              />
            )}

            <Chip
              size="medium"
              label={translations.financialStatus[order.financial_status]}
              icon={iconForFinancialStatus(order.financial_status)}
              sx={{
                minWidth: "80px",
              }}
              style={{
                backgroundColor: colorForFinancialStatus(
                  order.financial_status
                ),
              }}
            />
          </Box>
          <StyledOrderDetail variant="body1" style={{ fontSize: "15px" }}>
            Mobil:{" "}
            {order.billing_address?.phone
              ? order.billing_address.phone
              : "Ej angivet"}
          </StyledOrderDetail>
          <StyledOrderDetail variant="body1" style={{ fontSize: "15px" }}>
            Email: {order.contact_email}
          </StyledOrderDetail>
          <StyledOrderDetail variant="body1" style={{ fontSize: "15px" }}>
            Totalt: {getTotalPrice(order)}kr
          </StyledOrderDetail>

          <StyledStepperContainer>
            <StyledRow>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>Skapad</Typography>
                <Typography variant="body1" color="textSecondary">
                  {formattedDate(order)}
                </Typography>
              </Box>

              <Box>
                <Typography sx={{ fontWeight: "bold" }}>Leverans</Typography>
                <Typography variant="body1" color="textSecondary">
                  {deliveryDateFunction(order)}
                </Typography>
              </Box>
            </StyledRow>

            {isUpdating ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ mt: 1, height: "52px" }}
              >
                <CircularProgress size={24} />
              </Box>
            ) : (
              <Select
                fullWidth
                value={getStatusFromTags(order.tags)}
                onChange={(e) => handleStatusChange(order, e.target.value)}
                sx={{
                  mt: 1,
                  color: getDropDownColor(order),
                  fontWeight: "bold",
                }}
              >
                {statusToSteps(getStatusFromTags(order.tags)).map((label) => (
                  <MenuItem key={label} value={label}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </StyledStepperContainer>

          <StyledOrderItem key={order._id}>
            <TextField
              label="Anteckning"
              variant="outlined"
              multiline
              rows={4}
              defaultValue={order.note}
              onChange={(e) => handleNoteChange(order, e.target.value)}
              onBlur={(e) => handleSaveNote(order.id, e.target.value)}
              fullWidth
              autoComplete="off"
            />
          </StyledOrderItem>

          <StyledAccordion>
            <Box
              sx={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
                "& .MuiAccordion-root": { boxShadow: "none" },
              }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="line-items-content"
                  id="line-items-header"
                >
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold", color: "#666666" }}
                  >
                    Beställningen
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    {order.lineItems.map((item) => (
                      <Box key={item.id} style={{ wordWrap: "break-word" }}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bold" }}
                        >
                          {item.title}
                        </Typography>
                        <Typography variant="body1">
                          Antal: {item.quantity} - Tillagas av:{" "}
                          <b>{item.vendor}</b>
                        </Typography>
                        <Typography
                          style={{ marginBottom: "15px" }}
                          variant="body1"
                        >
                          Pris: {((item.price * item.quantity * 100) / 100) - item?.total_discount + " kr"}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </StyledAccordion>
        </StyledOrderItem>
      ))}
      {allOrders.length > displayedOrders.length && (
        <Button variant="contained" color="primary" onClick={handleLoadMore}>
          Ladda in flera
        </Button>
      )}
      <CustomSnackbar
        open={open}
        handleClose={handleSnackbarClose}
        message={
          isErrorAny
            ? "Uppdatering av order misslyckades"
            : "Uppdatering av order lyckades!"
        }
        severity={severity}
        isMobile={isMobileOnly}
      />
    </StyledContainer>
  );
};

export default MobileLandingPage;