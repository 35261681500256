import React, { useState, useEffect } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/sv';
import { useNavigate } from 'react-router-dom';

import ErrorComponent from "components/ErrorComponent";
import LoadingComponent from "components/LoadingComponent";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';

import {
    TextField,
    Box,
    Typography,
    Divider,
    Container,
    Paper,
    Button,
  } from "@mui/material";

import { getDeliveryDate } from "utilities/getNoteAttributes";
import { parseDate } from "utilities/dateUtility";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import GetAllOrdersDB from 'Hooks/Orders/getAllOrdersDb';

import { Switch } from "@mui/material";
import { Grid } from "@mui/material";


const ProductSummaryView = () => {
  moment.locale("sv");
  // sets the start of the week to monday
  moment.updateLocale("sv", {
    week: {
      dow: 1,
    },
  });
  const initialStartDate = moment().subtract(7, "days");
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(moment());
  const [filteredOrders] = useState([]);

  const [tempStartDate, setTempStartDate] = useState(initialStartDate);
  const [tempEndDate, setTempEndDate] = useState(moment());
  const [lineItemsAggregated, setLineItemsAggregated] = useState([]); // Initialize as an empty array
  const [showNames, setShowNames] = useState(true);


  const navigate = useNavigate();

  const {
    orders: orderData,
    isLoading: isOrderLoading,
    error,
  } = GetAllOrdersDB("", true);

  useEffect(() => {
    if (orderData) {
      const filtered = orderData.filter((order) => {
        const orderDate = moment(parseDate(getDeliveryDate(order)));
        return orderDate.isSameOrAfter(startDate) && orderDate.isSameOrBefore(endDate);
      });
  
      const tempAggregatedData = filtered.reduce((acc, order) => {
        (order.line_items || []).forEach(item => {
          // Ensure both 'title' and 'name' properties exist
          if (item?.title && item?.name) {
            if (!acc[item.title]) {
              // Initialize the entry for this title with totalQuantity and names
              // Also, set the productId using the productId of the first item
              acc[item.title] = { totalQuantity: 0, names: {}, productId: item.product_id };
            }
            acc[item.title].totalQuantity += item.quantity;
      
            if (!acc[item.title].names[item.name]) {
              // Initialize the entry for this name with quantity
              acc[item.title].names[item.name] = { quantity: 0 };
            }
            // Add the quantity for this specific name
            acc[item.title].names[item.name].quantity += item.quantity;
          }
        });
        return acc;
      }, {});

      const transformedRows = [];
      Object.entries(tempAggregatedData).forEach(([title, details], titleIndex) => {
        const titleRow = {
          id: `title-${titleIndex}`,
          name: title,
          quantity: details.totalQuantity,
          productId: details.productId,
          isTitle: true
        };
  
        transformedRows.push(titleRow);
  
        // Include the names only if showNames is true
        if (showNames) {
          Object.entries(details.names).forEach(([name, nameDetails], nameIndex) => {
            transformedRows.push({
              id: `name-${titleIndex}-${nameIndex}`,
              name: name,
              quantity: nameDetails.quantity,
              isTitle: false
            });
          });
        }
      });
  
      setLineItemsAggregated(transformedRows);
    }
    }, [orderData, startDate, endDate, showNames]); // Include showNames as a dependency
  
  
  

  const handleSetTempStartDate = (date) => {
    setTempStartDate(date);
  };

  const handleSetTempEndDate = (date) => {
    setTempEndDate(date);
  };

  const handleSearch = () => {
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
  };

    // Function to handle the toggle switch
    const handleToggleNames = (event) => {
        setShowNames(event.target.checked);
    };


  // // Now, you can calculate totals by title and name
  // const totalsByTitle = lineItems.reduce((acc, item) => {
  //   // If the title doesn't exist in the accumulator, add it
  //   if (!acc[item.title]) {
  //     acc[item.title] = {
  //       totalQuantity: 0,
  //       names: {}
  //     };
  //   }
  //   // Add the quantity to the total for the title
  //   acc[item.title].totalQuantity += item.quantity;
  
  //   // If the name doesn't exist under the title, add it
  //   if (!acc[item.title].names[item.name]) {
  //     acc[item.title].names[item.name] = 0;
  //   }
  //   // Add the quantity to the total for the name under the title
  //   acc[item.title].names[item.name] += item.quantity;
  
  //   return acc;
  // }, {});
  

  if (isOrderLoading) {
    return <LoadingComponent />;
  }

  if (error) {
    return <ErrorComponent error={error} />;
  }

  const itemQuantities = {};
  filteredOrders?.forEach((order) => {
    order?.line_items?.forEach((item) => {
      if (itemQuantities[item.name]) {
        itemQuantities[item.name].quantity += item.quantity;
      } else {
        itemQuantities[item.name] = {
          quantity: item.quantity,
          id: item.product_id,
        };
      }
    });
  });


    // Transform data for DataGrid
    const rows = lineItemsAggregated.map((item, index) => ({
    ...item,
    id: item.id || `row-${index}` // Ensures each row has a unique ID
    }));


    const columns = [
        { 
          field: 'name', 
          headerName: 'Namn/Title', 
          width: 300, 
          renderCell: (params) => {
            // Render title rows in bold, and name rows in normal text if showNames is true
            if (params.row.isTitle) {
              return <strong>{params.value}</strong>;
            } else if (showNames) {
              return params.value;
            }
            return null; // Do not render names if showNames is false
          }
        },
        { 
          field: 'quantity', 
          headerName: 'Antal', 
          type: 'number', 
          width: 150 
        },
        {
            field: 'actions',
            headerName: 'Produkt',
            width: 150,
            renderCell: (params) => {
              // Access productId from the current row's data
              const productId = params.row.productId;
          
              // Check if productId is available and the row is not a title row
              if (productId) {
                return (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      console.log(`Navigating to /product/${productId}`);
                      navigate(`/product/${productId}`);
                    }}
                  >
                    Visa produkt
                  </Button>
                );
              }
          
              // Return null for title rows or if productId is not available
              return null;
            },
          }
      ];


  return (
    <Box sx={{ p: 4 }}>
      <Box style={{ marginTop: "20px", marginLeft: "20px" }}>
        {/* ... Header component */}
      </Box>

      <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ marginBottom: 2 }}>
      <Grid item>
        <Typography>Show Names:</Typography>
      </Grid>
      <Grid item>
        <Switch checked={showNames} onChange={handleToggleNames} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <LocalizationProvider dateAdapter={AdapterMoment} locale="sv">
          <DatePicker
            label="Start Date"
            value={tempStartDate}
            onChange={handleSetTempStartDate}
            renderInput={(params) => (
              <TextField {...params} fullWidth />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <LocalizationProvider dateAdapter={AdapterMoment} locale="sv">
          <DatePicker
            label="End Date"
            value={tempEndDate}
            onChange={handleSetTempEndDate}
            renderInput={(params) => (
              <TextField {...params} fullWidth />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item>
        <Button variant="outlined" onClick={handleSearch}>
          Search
        </Button>
      </Grid>
    </Grid>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      <Container maxWidth="md">
        <Paper elevation={1} sx={{ height: 650, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 20]}
            components={{
              Toolbar: GridToolbarContainer,
            }}
            componentsProps={{
              toolbar: {
                children: (
                  <>
                    <GridToolbarExport />
                    {/* ... other toolbar items */}
                  </>
                ),
              },
            }}
          />
        </Paper>
      </Container>
    </Box>
  );
};

export default ProductSummaryView;