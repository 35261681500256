import { useMutation, useQueryClient } from 'react-query';

const updateProductDetails = async ({ productId, updatedFields }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000/';

  // Prepare the payload
  const payload = {
    ...updatedFields,
    price: parseFloat(updatedFields.price || 0),
    compare_at_price: parseFloat(updatedFields.compare_at_price || 0),
    images: updatedFields.images && updatedFields.images.length > 0 ? updatedFields.images : [],
  };

  const response = await fetch(`${baseUrl}/shopify-api/products/${productId}/update/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

export const useProductUpdate = (productId) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError } = useMutation(
    updatedFields => updateProductDetails({ productId, updatedFields }),
    {
      onSuccess: () => {
        // Invalidate and refetch queries related to the product
        queryClient.invalidateQueries(['product', productId]);
        // You can invalidate other queries as needed, e.g., product list
        // queryClient.invalidateQueries('products');
      },
    }
  );

  return { updateProduct: mutate, isLoading, isError };
};
