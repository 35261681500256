import React from "react";
import {
  Container,
  Divider,
  Box,
} from "@mui/material";

import Header from "components/Header";
import DiscountsDataGrid from "./components/allDiscounts"

const Discounts = () => {

    return (
        <Container>
          <Box mt={4} mb={4}>
            <Header
              title={`Rabattkoder`}
              subtitle="Se alla rabattkoder som finns på hemsidan!"
            />
          </Box>

          <Divider
            sx={{ marginTop: 2, marginBottom: 2 }}
          />

          <DiscountsDataGrid />
        </Container>
      );

};

export default Discounts;