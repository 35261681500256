import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Chip, Tooltip, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';


const getStatusChipProps = (status) => {
    switch (status) {
        case 'open':
            return { label: 'Open', color: 'default', description: 'Draft order is open.' };
        case 'invoice_sent':
            return { label: 'Invoice Sent', color: 'primary', description: 'Invoice has been sent for the draft order.' };
        case 'completed':
            return { label: 'Completed', color: 'success', description: 'Draft order has been completed and turned into an order.' };
        default:
            return { label: 'Unknown', color: 'warning', description: 'Status is unknown.' };
    }
};

const renderDetailsPanel = (order) => (
    <Box margin={2}>
      <div>{order.name}: {order.line_items.map(item => item.name).join(', ')}</div>
      {/* <div>Shipping Address: {`${order.shipping_address.address1}, ${order.shipping_address.city}`}</div> */}
    </Box>
  );



  const DraftOrdersList = ({ draftOrders }) => {
    const [open, setOpen] = useState({});
  
    const handleClick = (id) => {
      setOpen(prevOpen => ({
        ...prevOpen,
        [id]: !prevOpen[id]
      }));
    };
    


    const columns = [
        {
          field: 'expand',
          headerName: '',
          width: 50,
          align: 'center',
          headerAlign: 'center',
          renderCell: (params) => (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleClick(params.id)}
            >
              {open[params.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ),
        },
        { field: 'name', headerName: 'Order #', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'email', headerName: 'Email', width: 250, align: 'center', headerAlign: 'center' },
        {
          field: 'created_at',
          headerName: 'Date Created',
          width: 180,
          align: 'center',
          headerAlign: 'center',
          valueGetter: (params) => new Date(params.value).toLocaleDateString(),
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 160,
          align: 'center',
          headerAlign: 'center',
          renderCell: (params) => {
            const { label, color, description } = getStatusChipProps(params.value);
            return (
              <Tooltip title={description} placement="top">
                <Chip label={label} size="small" color={color} />
              </Tooltip>
            );
          }
        },
        {
          field: 'total_price',
          headerName: 'Total Price',
          width: 150,
          align: 'center',
          headerAlign: 'center',
          valueGetter: (params) => `${params.value} ${params.row.currency}`,
        },
        // Add more columns as needed
      ];


      if (!draftOrders || draftOrders.length === 0) {
        return <p>No draft orders available.</p>;
      }
  
  
      return (
        <Box sx={{ padding: 3, width: 'auto' }}> {/* Use 'sx' for custom Material-UI styling */}
          <div style={{ height: 600, width: '100%' }}>
            <DataGrid
              rows={draftOrders}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              // Add other props as needed
            />
          </div>
          {draftOrders.map((order) => (
            <Collapse key={order.id} in={open[order.id]} timeout="auto" unmountOnExit>
              {renderDetailsPanel(order)}
            </Collapse>
          ))}
        </Box>
      );
    };
    
    export default DraftOrdersList;