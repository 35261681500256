import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  TextField,
  Button,
  Autocomplete,
  TableContainer,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle
} from "@mui/material";
import useShopifyProducts from "Hooks/Products/useShopifyProducts";
import { useAddLineItem } from "../../../../Hooks/Orders/useAddLineItems";
import { useUpdateLineItemComment } from '../../../../Hooks/Orders/useUpdateLineItemComment';
import LoadingComponent from "components/LoadingComponent.jsx";
import CustomSnackbar from "components/SnackBar";
import AddCommentIcon from '@mui/icons-material/AddComment';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';

function OrderDetailsTable({ order }) {
  const { products, isLoading: productsLoading } = useShopifyProducts();
  const [lineItems, setLineItems] = useState([]);
  const [newLineItem, setNewLineItem] = useState({
    product_id: "",
    name: "",
    quantity: "",
    price: "",
    variant: "",
  });

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  // const [totalPrice, setTotalPrice] = useState(0);
  // const [totalAddedByStore, setTotalAddedByStore] = useState(0);
  const { mutate: addLineItem, isLoading: isAdding } = useAddLineItem();
  const { mutate: updateComment, isLoading: isUpdating } = useUpdateLineItemComment();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState("");
  const [severitySnackbar, setSeveritySnackbar] = useState("");
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [currentComment, setCurrentComment] = useState('');
  const [currentCommentIndex, setCurrentCommentIndex] = useState(null);
  const [isCommentChanged, setIsCommentChanged] = useState(false);

  const findProductById = useCallback((products, productId) => {
    return Array.isArray(products) ? products.find((product) => product.id === productId) : null;
  }, []);
  
  const findVariantNameById = useCallback((products, productId, variantId) => {
    const product = findProductById(products, productId);
    const variant = product?.variants?.find(variant => variant.id === variantId);
    return variant && variant.title !== 'Default Title' ? variant.title : 'Default';
  }, [findProductById]);
  
  useEffect(() => {
    if (order && order.line_items && products) {
      setLineItems(order.line_items.map(lineItem => {
        const product = findProductById(products, lineItem.product_id);
        const variant = product?.variants?.find(v => v.id === lineItem.variant_id);
        const priceToUse = variant?.compare_at_price && parseFloat(variant.compare_at_price) > parseFloat(lineItem.price) 
          ? variant.compare_at_price 
          : lineItem.price;
        const discountPerItem = variant && variant.compare_at_price && parseFloat(variant.compare_at_price) > parseFloat(lineItem.price)
        ? parseFloat(variant.compare_at_price) - parseFloat(lineItem.price)
        : 0;

        const totalDiscount = discountPerItem * lineItem.quantity;
        return {
          ...lineItem,
          price: priceToUse,
          variantName: findVariantNameById(products, lineItem.product_id, lineItem.variant_id),
          discountPerItem: discountPerItem,
          totalDiscount: totalDiscount,
        };
      }));
    }
  }, [order, products, findVariantNameById, findProductById]);

  // useEffect(() => {
  //   let newTotalPrice = 0;
  //   let newTotalAddedByStore = 0;
  
  //   order?.line_items?.forEach(item => {
  //     const itemTotal = parseFloat(item.price) * item.quantity;
  //     newTotalPrice += itemTotal;
  
  //     if (item.added_by_store) {
  //       newTotalAddedByStore += itemTotal;
  //     }
  //   });
  
  //   setTotalPrice(newTotalPrice);
  //   setTotalAddedByStore(newTotalAddedByStore);
  // }, [order?.line_items, lineItems, setTotalPrice, setTotalAddedByStore]); // Adding setters to dependency array



  // const handleUpdateComment = (lineItemId, comment) => {
  //   const orderId = order.shopify_order_id;
  //   updateLineItemComment(orderId, lineItemId, comment);
  // };

    // Function to handle product selection
    const handleProductSelect = (event, newValue) => {
      setSelectedProduct(newValue);
      setSelectedVariant(null);
      setNewLineItem({
        ...newLineItem,
        product_id: newValue?.id || '',
        name: newValue?.title || '',
        price: '',
        variant_id: '',
      });
    };

    // Function to handle variant selection
    const handleVariantSelect = (event, newValue) => {
      setSelectedVariant(newValue);
      const priceToUse = newValue?.compare_at_price && parseFloat(newValue.compare_at_price) > parseFloat(newValue.price) 
        ? newValue.compare_at_price 
        : newValue?.price;
    
      setNewLineItem({
        ...newLineItem,
        variant_id: newValue?.id || '',
        price: priceToUse || '',
      });
    };
  
    // Function to handle new line item field changes
    const handleFieldChange = (e, field) => {
      setNewLineItem({ ...newLineItem, [field]: e.target.value });
    };
  

  const handleCommentChange = (index, newComment) => {
      const updatedLineItems = [...lineItems];
      const isChanged = newComment !== order.line_items[index].comment;
      updatedLineItems[index] = {
        ...updatedLineItems[index],
        comment: newComment
      };

      setLineItems(updatedLineItems);

      if (isChanged) {
        setIsCommentChanged(true); // Set the flag to true if any comment is changed
      }
    };

    const handleSaveAllComments = () => {
    // Only proceed if there's a change
    if (isCommentChanged) {
      lineItems.forEach(item => {
        if (item.comment !== order.line_items.find(li => li.id === item.id).comment) {
          updateComment({
            orderId: order.shopify_order_id, 
            lineItemId: item.id, 
            comment: item.comment
          }, {
            onSuccess: () => {
              handleSnackbarOpen('Uppdatering av kommentar lyckaes!', 'success');
            },
            // onError: (error) => {
            //   handleSnackbarOpen('Uppdatering av kommentar misslyckades!', 'error');
            //   console.error(error.message);
            // },
          });
        }
      });
      setIsCommentChanged(false); // Reset the flag after saving
    }
  };
  


  const handleAddLineItem = () => {
    if (newLineItem.product_id && newLineItem.variant_id && newLineItem.quantity && newLineItem.price) {
      // Wrap the new line item in an object under the 'new_line_items' key
      const requestData = {
        "new_line_items": [
          {
          product_id: newLineItem.product_id,
          variant_id: newLineItem.variant_id,
          quantity: parseInt(newLineItem.quantity, 10),
          price: parseFloat(newLineItem.price),
          name: newLineItem.name, // Make sure 'name' is correctly assigned
          taxable: true,
          total_discount: 0,
          fulfillable_quantity: 0,
          fulfillment_service: "manual",
          grams: 0,
          product_exists: true,
          requires_shipping: false,
          added_by_store: true
        }
      ] 

      };
  
      // Call addLineItem mutation to send data to the backend
      addLineItem({ orderId: order.shopify_order_id, newLineItem: requestData }, {
        onSuccess: () => {
          // Handle success
          handleSnackbarOpen('Ny produkt tillagd i order!', 'success');
          // Optionally, refresh the line items list here
        },
        onError: (error) => {
          // Handle error
          handleSnackbarOpen('Tilläggning av produkt misslyckades!', 'error');
          console.log(error.message)
        },
      });
  
      // Resetting the form fields
      setNewLineItem({ product_id: '', variant_id: '', name: '', quantity: '', price: '' });
      setSelectedProduct(null);
      setSelectedVariant(null);
    }
  };
  

    const handleSnackbarOpen = (message, severity) => {
      setOpenSnackbar(true);
      setMessageSnackbar(message);
      setSeveritySnackbar(severity);
    };

    const handleSnackbarClose = () => {
      setOpenSnackbar(false);
    };

    // Function to render product options
    const renderProductOption = (props, option) => (
      <li {...props}>
        {option.id} - {option.title}
      </li>
    );



    // Function to render variant options
    const renderVariantOption = (props, option) => (
      <li {...props}>
        {option.title} - {option.price}
      </li>
    );
    
    // Function to render comments as clickable elements
    const renderComment = (comment, index) => {
      if (comment) {
        return (
          <Button
            onClick={() => handleOpenCommentModal(index, comment)}
            style={{ textDecoration: "none", padding: 1, textTransform: 'none', color: "grey", textAlign: "left"}}
          >
           {comment}
          </Button>
        );
      } else {
        return (
          <Button onClick={() => handleOpenCommentModal(index, '')} style={{ color: "grey", textTransform: 'none', padding: 2 }} startIcon={<AddCommentIcon fontSize="small" />}>
            Kommentera
          </Button>
        );
      }
    };
    // Handle opening the comment modal
    const handleOpenCommentModal = (index, comment) => {
      setCurrentCommentIndex(index);
      setCurrentComment(comment);
      setCommentModalOpen(true);
    };

    // Handle closing the comment modal
    const handleCloseCommentModal = () => {
      setCommentModalOpen(false);
    };

    // Handle saving the comment
    const handleSaveComment = () => {
      handleCommentChange(currentCommentIndex, currentComment);
      handleCloseCommentModal();
    };
  //     // Function to handle new line item field changes
  // const handleNewLineItemChange = (field, value) => {
  //   setNewLineItem({ ...newLineItem, [field]: value });
  // };


  if (productsLoading){
    return <LoadingComponent />;
  }

  if (productsLoading || !products) {
    return <div>Loading...</div>;
  }
  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" mb={1}>
        {isCommentChanged && (
          <Button 
            onClick={handleSaveAllComments}
            color="primary"
            variant="contained"
            size="small"
            sx={{ textTransform: 'none' }}
            disabled={isUpdating}
            endIcon={<SendRoundedIcon />}
          >
            Spara alla kommentarer och skicka
          </Button>
        )}
      </Box>
      <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead>
          <TableRow style={{ 
            backgroundColor: "#E5F0EB"
            }}>
            <TableCell align="left" style={{ fontWeight: "bold", fontSize: "0.8rem", width: '10%' }}>Betald</TableCell>
            <TableCell align="left" style={{ fontWeight: "bold", fontSize: "0.8rem", width: '30%' }}>Produkt</TableCell>
            <TableCell align="left" style={{ fontWeight: "bold", fontSize: "0.8rem", width: '20%' }}>Variant</TableCell>
            <TableCell align="left" style={{ fontWeight: "bold", fontSize: "0.8rem", width: '10%' }}>Antal</TableCell>
            <TableCell align="left" style={{ fontWeight: "bold", fontSize: "0.8rem", width: '15%'}}>Pris</TableCell>
            <TableCell align="left" style={{ fontWeight: "bold", fontSize: "0.8rem", width: '15%' }}>Totalt Pris</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems.map((item, index) => (
            <TableRow key={index}>
                <TableCell style={{ fontSize: '0.8rem', textAlign: 'left' }}>
                  {item.added_by_store ? 
                    <DisabledByDefaultRoundedIcon style={{ color: 'red' }} /> : 
                    <CheckBoxIcon style={{ color: 'green' }} />
                  }
                </TableCell>         
               <TableCell style={{ fontSize: '0.8rem',  fontWeight: 'bold' }}>
                {item.name}<br />   
                {renderComment(item.comment, index)}
                </TableCell>
              <TableCell style={{ fontSize: '0.8rem' }}>{item.variantName === "Default" ? "Standard" : item.variantName}</TableCell>
              <TableCell style={{ fontSize: '0.8rem', width: '60px' }}>
                {item.quantity}
              </TableCell>
              <TableCell style={{ fontSize: '0.8rem' }}>
                {item.price} kr<br />   
                {item.discountPerItem > 0 && (
                  <span style={{color: "red"}}>- {item.discountPerItem} kr</span>
                  )}
              </TableCell>
              <TableCell style={{ fontSize: '0.8rem' }}>
                {parseFloat((item.price * item.quantity) - item.totalDiscount).toFixed(2)} kr<br />
                {item.totalDiscount > 0 && (
                  <span style={{color: "red"}}>({item.totalDiscount} kr)</span>
                  )}
              </TableCell>
            </TableRow>
          ))}

         <TableRow>
            <TableCell colSpan={2}>
              <Autocomplete
                value={selectedProduct}
                onChange={handleProductSelect}
                options={products}
                getOptionLabel={(option) => option.title || ''}
                renderOption={renderProductOption}
                renderInput={(params) => <TextField {...params} label="Produkt" placeholder="Select a product" />}
                fullWidth
                size="small"
              />
            </TableCell>
            <TableCell>
              <Autocomplete
                value={selectedVariant}
                onChange={handleVariantSelect}
                options={selectedProduct?.variants || []}
                getOptionLabel={(option) => option.title || ''}
                renderOption={renderVariantOption}
                renderInput={(params) => <TextField {...params} label="Variant" placeholder="Select a variant" />}
                fullWidth
                disabled={!selectedProduct}
                size="small"
              />
            </TableCell>
            <TableCell>
              <TextField
                value={newLineItem.quantity}
                onChange={(e) => handleFieldChange(e, 'quantity')}
                placeholder="x"
                fullWidth
                size="small"
              />
            </TableCell>
            <TableCell>
              <TextField
                value={newLineItem.price}
                InputProps={{ readOnly: true }}
                placeholder="Pris"
                fullWidth
                size="small"
              />
            </TableCell>
            <TableCell>
              {/* Add button */}
              <Button 
                onClick={handleAddLineItem} 
                disabled={isAdding}
                variant="contained"
                sx={{
                  textTransform: 'none',
                  padding: 0.5,
                }}>
                  {isAdding ? 'Lägger till...' : 'Lägg till'}
                </Button>            
              </TableCell>
          </TableRow>
          <br />
          {/* <TableRow>
              <TableCell colSpan={3} style={{ borderBottom: 'none' }}/>
              <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
                <Typography variant="h6">Totalt pris</Typography>
              </TableCell>
              <TableCell colSpan={1} align="right" style={{ borderBottom: 'none' }}>
                 <Typography variant="body1">{totalPrice.toFixed(2)} kr</Typography> 
              </TableCell>
            </TableRow> */}
            {/* <TableRow>
              <TableCell colSpan={3} style={{ borderBottom: 'none' }}/> 
              <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
                <Typography variant="h6">Totalt pris<br /><strong>tillagt manuellt</strong></Typography> 
              </TableCell>
              <TableCell colSpan={1} align="right" style={{ borderBottom: 'none' }}>
                <Typography variant="body1" >{totalAddedByStore.toFixed(2)} kr</Typography> 
              </TableCell>
            </TableRow> */}
        </TableBody>
      </Table>
      </TableContainer>
      <Dialog 
        open={commentModalOpen} 
        onClose={handleCloseCommentModal}
        fullWidth={true}
        >
      <DialogTitle sx={{ fontSize: "20px" }}>{<strong>Lägg till en kommentar</strong>}</DialogTitle>
      
        <DialogContent>
        <TextField
            fullWidth
            multiline
            rows={6} // Increase the number of rows for the textarea
            value={currentComment}
            onChange={(e) => setCurrentComment(e.target.value)}
            variant="outlined"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button 
          onClick={handleCloseCommentModal}
          variant="contained"
          sx={{
            textTransform: 'none',
          }}
          >Avbryt</Button>
          <Button 
          onClick={handleSaveComment}
          variant="contained"
          color="secondary"
          sx={{
            textTransform: 'none',
          }}
          >Ok</Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        severity={severitySnackbar}
        message={messageSnackbar}
      />

    </Box>
  );
}

export default OrderDetailsTable;