import React from "react";
import {
  Container,
  Box,
  Divider,
} from "@mui/material";

import Header from "components/Header";
import CreateAmountOffDiscountForm from "./components/createDiscounts"

const AddDiscount = () => {

    return (
        <Container>
          <Box mt={4} mb={4}>
            <Header
              title={`Skapa ny rabattkod`}
              subtitle="Skapa ny rabattkod och schemlägg den för aktivering, välj mellan fast belopp eller procent."
            />
          </Box>
          <Divider
            sx={{ marginTop: 2, marginBottom: 2 }}
          />
          <CreateAmountOffDiscountForm />
        </Container>
      );

};

export default AddDiscount;