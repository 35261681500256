import React from 'react';
import { Paper, Typography } from '@mui/material';

const StatsBox = ({ title, value, color, icon: Icon }) => {

  // Extract RGB values from the hex color
  const rgbColor = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  const r = parseInt(rgbColor[1], 16);
  const g = parseInt(rgbColor[2], 16);
  const b = parseInt(rgbColor[3], 16);

  return (
      <Paper
        sx={{
          backgroundColor: "#E5F0EB",
          border: "0px solid rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          p: 1.5,
          minHeight: "100px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
        }}
      >
        {Icon && <Icon fontSize="large" style={{ fill: `rgb(${r}, ${g}, ${b})` }} />}
        <Typography variant="subtitle1" component="p" sx={{ mt: 1 }}>
          {title}
        </Typography>
        <Typography variant="h5" component="p" sx={{ mt: 1, fontWeight: "bold", color:  `rgb(${r}, ${g}, ${b})` }}>
          {value}
        </Typography>
      </Paper>
  );
};

export default StatsBox;
