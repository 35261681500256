// useUpdateLineItemComment.js
import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';

const updateLineItemComment = async (orderId, lineItemId, comment, updateToken, logoutUser) => {
    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
    try {
        const response = await fetch(`${baseUrl}/webhook/update-line-item-comment/${orderId}/${lineItemId}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ comment }),
            credentials: 'include',
        });

        if ((response.status === 401 || response.status === 403) && !response.headers.get('token-expired')) {
            await updateToken();
            return updateLineItemComment(orderId, lineItemId, comment, updateToken, logoutUser);
        } else if ((response.status === 401 || response.status === 403) && response.headers.get('token-expired')) {
            logoutUser();
            throw new Error('Authentication failed. Logging out.');
        }

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return response.json();
    } catch (error) {
        console.error('Error updating line item comment:', error);
        throw error;
    }
};


export const useUpdateLineItemComment = () => {
    const { updateToken, logoutUser } = useContext(AuthContext);
    const queryClient = useQueryClient();

    const mutation = useMutation(({ orderId, lineItemId, comment }) => 
        updateLineItemComment(orderId, lineItemId, comment, updateToken, logoutUser), {
        onSuccess: () => {
            queryClient.invalidateQueries('orders'); // Adjust if you have a different query key
        },
    });

    return mutation;
};
