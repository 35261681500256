import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';

const updateOrderFields = async (orderId, updatedFields, updateToken, logoutUser) => {
  console.log("Updated Fields:", JSON.stringify(updatedFields, null, 2));

  try {
    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
    console.log("Sending updated order fields:", updatedFields); // Log the data being sent
    const response = await fetch(`${baseUrl}/webhook/update-order/${orderId}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedFields),
      credentials: 'include',
    });

    if ((response.status === 401 || response.status === 403) && !response.headers.get('token-expired')) {
      await updateToken();
      return updateOrderFields(orderId, updatedFields, updateToken, logoutUser);
    } else if ((response.status === 401 || response.status === 403) && response.headers.get('token-expired')) {
      logoutUser();
      throw new Error('Authentication failed. Logging out.');
    }

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  } catch (error) {
    console.error('Error updating order:', error);
    throw error;
  }
};

export const useUpdateOrder = () => {
  const { updateToken, logoutUser } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ orderId, updatedFields }) => updateOrderFields(orderId, updatedFields, updateToken, logoutUser),
    {
      onSuccess: (_, { orderId }) => {
        queryClient.invalidateQueries(['order', orderId]); // Invalidate to refetch the updated order data
      },
    }
  );

  return mutation;
};
