import React, { useState, useEffect } from 'react';
import { Radio, RadioGroup, FormControlLabel, FormGroup } from '@mui/material';
import deliveryOptions from 'data/deliveryOptions.json'; // Adjust the path as necessary

const DeliveryOptionsComponent = ({ productTags, handleDeliveryOptionChange }) => {
    const deliveryTags = deliveryOptions.map(option => option.tag);
    const defaultOption = 'standard-leverans';
    
    // Find the matching tag or use default
    const initialTag = productTags.find(tag => deliveryTags.includes(tag)) || defaultOption;

    const [selectedOption, setSelectedOption] = useState(initialTag);

    useEffect(() => {
        // Update state if productTags change
        setSelectedOption(initialTag);
    }, [initialTag]);

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        handleDeliveryOptionChange(event.target.value);
    };

    return (
        <FormGroup>
            <RadioGroup value={selectedOption} onChange={handleChange}>
                {deliveryOptions.map(option => (
                    <FormControlLabel
                        key={option.tag}
                        value={option.tag}
                        control={<Radio />}
                        label={option.label}
                    />
                ))}
            </RadioGroup>
        </FormGroup>
    );
};

export default DeliveryOptionsComponent;
