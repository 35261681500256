import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';

const updateOrdersDone = async (selectedOrderIds, newStatus, updateToken, logoutUser) => {
  try {
    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
    const response = await fetch(`${baseUrl}/webhook/update_order_isDone/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        order_ids: selectedOrderIds,
        is_done: newStatus,
      }),
      credentials: 'include', // Include this line
    });

    if ((response.status === 401 || response.status === 403) && !response.headers.get('token-expired')) {
      // If unauthorized, refresh token and try again
      await updateToken();
      return updateOrdersDone(selectedOrderIds, newStatus, updateToken, logoutUser);
    } else if ((response.status === 401 || response.status === 403) && response.headers.get('token-expired')) {
      // If token is expired, log the user out
      logoutUser();
      throw new Error('Authentication failed. Logging out.');
    }

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  } catch (error) {
    console.error('Error updating orders:', error);
    throw error;
  }
};

export const useUpdateOrdersDone = () => {
  const { updateToken, logoutUser } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const mutation = useMutation(({ order_ids, is_done }) => updateOrdersDone(order_ids, is_done, updateToken, logoutUser), {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('orders');
    },
  });

  return mutation;
};
