// AuthRoutes.jsx
import { Route, Routes, Navigate} from 'react-router-dom';
import { AuthProvider } from './context/AuthContext'

import LoginPage from "./pages/Users/Login";
import UserProfile from "pages/Users/UserAccount";

import CateringOrdersCalendar from "pages/SavedOldPages/OrdersCalendar/index.jsx";

import SearchPage from "./pages/Search/index.jsx";


import ProductList from "./pages/Products_Folder/Products/index.jsx"
import CreateProductForm from 'pages/Products_Folder/NewProduct';

import PrivateRoute from './pages/Login/ProtectedRoute'

import Layout from "./pages/Layout/Layout";

import MobileLayout from 'pages/Layout/MobileLayout.jsx';

import CateringChart from "./pages/Planning/orders/index.jsx";

import BookedPage from "./pages/SavedOldPages/OrdersBooked/index.jsx";

import Discounts from 'pages/Discounts/AllDiscounts/Discounts.jsx';
import AddDiscount from 'pages/Discounts/AddDiscount/AddDiscount.jsx';

import MobileLandingPage from "./pages/Mobile/LandingPage/index";
import MobileUpcommingOrders from "./pages/Mobile/UpcommingOrders/index"

import MobileEditPage from "./pages/Mobile/EditOrder";
import MobileSearch from "./pages/Mobile/SearchMobile";
import MobileCreateProductForm from "./pages/Mobile/NewProduct";
import MobileHomePage from "./pages/Mobile/Home";


// ÖSTENSSONS !!!!
import SyncOrdersPage from "pages/Sync/syncOrders.jsx";
import MainComponent from 'pages/Landing/table/index.jsx';
import OrderEdit from 'pages/Landing/editOrder/index.jsx';
import ProductSummaryView from 'pages/Planning/products/soldProducts.jsx';
import EditProduct from "./pages/Products_Folder/EditProduct/index.jsx";

import CustomerList from 'pages/Statistics/index.jsx';
import StatsDB from 'pages/Statistics/StatsOverview.jsx';

import RestrictedMainOstenssons from 'pages/Restricted/index';

import QRCodeScanner from 'pages/Mobile/ScanQR/index.jsx';

import DraftOrderPage from 'pages/DraftOrders/index.jsx';

import EmailTable from 'pages/Statistics/email/sentEmail.jsx';

const AuthRoutes = () => {
  return (
    
    <AuthProvider>
      <Routes>

        <Route path="/login" element={<LoginPage />} />

        <Route path="" element={<Navigate to="/table" replace />} />

        
        <Route path="/ostenssons/restricted/table" element={<PrivateRoute><RestrictedMainOstenssons /></PrivateRoute>} />

        <Route element={<Layout />}>

            <Route path="/table" element={<PrivateRoute><MainComponent /></PrivateRoute>} />
            <Route path="/table/:id" element={<PrivateRoute><OrderEdit /></PrivateRoute>} />
            <Route path="/ostenssons/sync" element={<PrivateRoute><SyncOrdersPage /></PrivateRoute>} />
            <Route path="/ostenssons/draft" element={<PrivateRoute><DraftOrderPage /></PrivateRoute>} />


            {/* PLANNING */}
            <Route path="/orders-stats" element={<PrivateRoute><CateringChart /></PrivateRoute>} />
            <Route path="/planning" element={<PrivateRoute><ProductSummaryView /></PrivateRoute>} />
           
            {/* STATS */}
            <Route path="/customer" element={<PrivateRoute><CustomerList /></PrivateRoute>} />
            <Route path="/stats" element={<PrivateRoute><StatsDB /></PrivateRoute>} />
            <Route path="/email" element={<PrivateRoute><EmailTable /></PrivateRoute>} />
          
            <Route path="/search" element={<PrivateRoute><SearchPage /></PrivateRoute>} />
            <Route path="/bokforing" element={<PrivateRoute><BookedPage /></PrivateRoute>} />
            <Route path="/calendar" element={<PrivateRoute><CateringOrdersCalendar /></PrivateRoute>} />
            <Route path="/profile" element={<PrivateRoute><UserProfile /></PrivateRoute>} />



            {/* PRODUCTS */}
            <Route path="/products" element={<PrivateRoute><ProductList /></PrivateRoute>} />
            <Route path="/products/:id" element={<PrivateRoute><EditProduct /></PrivateRoute>} />
            <Route path="add-product" element={<PrivateRoute><CreateProductForm /></PrivateRoute>} />


            {/* DISCOUNTS */}
            <Route path="/discounts" element={<PrivateRoute><Discounts /></PrivateRoute>} />
            <Route path="/add-discount" element={<PrivateRoute><AddDiscount /></PrivateRoute>} />
          
    
        
        </Route>

            <Route path="mobile/*" element={<MobileLayout />}>
                <Route path="landing" element={<PrivateRoute><MobileLandingPage/></PrivateRoute>}/>
                <Route path="edit/:id" element={<PrivateRoute><MobileEditPage/></PrivateRoute>}/>
                <Route path="search" element={<PrivateRoute><MobileSearch/></PrivateRoute>} />
                <Route path="new-product" element={<PrivateRoute><MobileCreateProductForm/></PrivateRoute>}/>
                <Route path="home" element={<PrivateRoute><MobileHomePage/></PrivateRoute>} />

                <Route path="UpcommingOrders" element={<PrivateRoute><MobileUpcommingOrders/></PrivateRoute>} />
                <Route path="scanner" element={<PrivateRoute><QRCodeScanner/></PrivateRoute>} />
            </Route>




      </Routes>
    </AuthProvider>
  );
};

export default AuthRoutes;
