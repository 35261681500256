import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  MenuItem,
  Select,
  Grid,
  InputAdornment,
} from "@mui/material";
import useCreateDiscount from "Hooks/Discounts/useCreateDiscount";
import CustomDateTimePicker from "components/CustomDateTimePicker";

import CustomSnackbar from 'components/SnackBar';

const CreateAmountOffDiscountForm = () => {
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [discountType, setDiscountType] = useState("fixed_amount");
  const [startsAt, setStartsAt] = useState(null);
  const [endsAt, setEndsAt] = useState(null);
  const { createDiscount, isLoading, error: isCreateError } = useCreateDiscount();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState("");
  const [severitySnackbar, setSeveritySnackbar] = useState("");

  const handleSnackbarOpen = (message, severity) => {
    setOpenSnackbar(true);
    setMessageSnackbar(message);
    setSeveritySnackbar(severity);
  };

  const handleSnackbarClose = () => {
  setOpenSnackbar(false);
};

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!startsAt) {
        // Handle the case where startsAt is not set
        handleSnackbarOpen("Startdatum saknas!", "error");
        return; // Prevent form submission
      }
    
    const formattedStartsAt = startsAt?.toISOString();
    const formattedEndsAt = endsAt?.toISOString();
    const discountData = {
      title,
      value: parseFloat(value),
      value_type: discountType, // Include this in the discountData
      starts_at: formattedStartsAt,
      ends_at: formattedEndsAt,
    };

    try {
      console.log(discountData); // Handle your response here
      await createDiscount(discountData);
      const messageCreate = `Rabattkoden ${discountData.title} har skapats!`;
        if (!isLoading) handleSnackbarOpen(messageCreate, "success");
        // Reset the form
        setTitle("");
        setValue("");
        setDiscountType("fixed_amount");
        setStartsAt(null);
        setEndsAt(null);
        } catch (error) {
        const messageCreate = `Misslyckades att skapa rabattkoden ${discountData.title}`;
        handleSnackbarOpen(messageCreate, isCreateError);
        console.error("There was an error creating the discount code:", error);
        }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 500 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Rabattkod"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Värde"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            margin="normal"
            type="number"
            required
            InputProps={{
              inputProps: { min: 0.0 },
              endAdornment: (
                <InputAdornment position="end">
                  <Select
                    value={discountType}
                    onChange={(e) => setDiscountType(e.target.value)}
                    sx={{ height: "40px" }} // Adjust the height to align with TextField
                  >
                    <MenuItem value="fixed_amount">Kronor</MenuItem>
                    <MenuItem value="percentage">Procent</MenuItem>
                  </Select>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
      <Grid item xs={6}>

      <CustomDateTimePicker required label={"Startdatum *"} value={startsAt} onChange={(newDate) => setStartsAt(newDate)}/>

      </Grid>

        <Grid item xs={6}>
      <CustomDateTimePicker label={"Slutdatum (frivilligt)"} value={endsAt} onChange={(newDate) => setEndsAt(newDate)}/>
      </Grid>
      </Grid>
      <Button type="submit" variant="contained" sx={{ mt: 2, textTransform: "none" }}>
        Skapa rabattkod
      </Button>
        <CustomSnackbar
            open={openSnackbar}
            handleClose={handleSnackbarClose}
            severity={severitySnackbar}
            message={messageSnackbar}
        />
    </Box>
  );
};

export default CreateAmountOffDiscountForm;
