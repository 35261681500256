import { useState } from 'react';

const useCreateDiscount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  console.log("🚀 ~ file: useCreateDiscount.js:8 ~ createDiscount ~ discountData:")

  const createDiscount = async (discountData) => {
  console.log("🚀 ~ file: useCreateDiscount.js:8 ~ createDiscount ~ discountData:", discountData)
      
      setIsLoading(true);
      const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:8000";
      
      try {
      const response = await fetch(`${baseUrl}/shopify-api/discounts/add/`, {
        method: 'POST',
        body: JSON.stringify(discountData),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include this line if you're including session-based authentication
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Something went wrong!');
      }

      setError(null);
      const data = await response.json();
      console.log("🚀 ~ file: useCreateDiscount.js:28 ~ createDiscount ~ data:", data)
      console.log('Discount created', data);
      return data; // Return data in case it is needed after hook usage
    } catch (err) {
      setError(err.message || 'An error occurred while creating the discount.');
    } finally {
      setIsLoading(false);
    }
  };

  return { createDiscount, isLoading, error };
};

export default useCreateDiscount;
