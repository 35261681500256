import React from 'react';
import { Box } from '@mui/material';
import Pulse from "react-spinners/PulseLoader";

const LoadingComponent = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      mt="-50px"
    >
      <Pulse color='#2B545A' speedMultiplier={0.65}/>
    </Box>
  );
};

export default LoadingComponent;
