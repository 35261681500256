import { useQuery } from 'react-query';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';


const fetchOrders = async (status, include_all_details, is_done,updateToken, logoutUser, retryCount = 0) => {
  const baseUrl = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'http://localhost:8000';
  console.log("IS_NULL", is_done)

  const url = new URL(`${baseUrl}/webhook/db/`);
  if (status) url.searchParams.append('status', status);
  url.searchParams.append('include_all_details', include_all_details);
  if (is_done !== null) {  // Check if is_done is not null
    url.searchParams.append('is_done', is_done);
  }



  const response = await fetch(url, {
    headers: {
      'Content-Type':'application/json',
    },
    credentials: 'include',
  });

  if ((response.status === 401 || response.status === 403) && retryCount < 1) {
    // If unauthorized and haven't retried yet, refresh token and try again
    await updateToken();
    return fetchOrders(updateToken, logoutUser, retryCount + 1);
} else if ((response.status === 401 || response.status === 403) && retryCount >= 1) {
    // If unauthorized and this was a retry, log the user out
    logoutUser();
    throw new Error('Authentication failed. Logging out.');
}

  const data = await response.json();

  return data;
}

export const GetAllOrdersDB = (status, include_all_details=false, is_done=false) => {  
  const { updateToken, logoutUser } = useContext(AuthContext);

  const { data: orders, isLoading, isError } = useQuery(['orders', status, is_done], () => fetchOrders(status, include_all_details, is_done, updateToken, logoutUser, 0), {
      retry: 1,
  });

  return { orders, isLoading, isError };
};


export default GetAllOrdersDB;
