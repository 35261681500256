import { useQuery } from 'react-query';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';  // Ensure the path to AuthContext is correct.

const getCustomers = async (updateToken, logoutUser, marketing = 'all', retryCount = 0) => {
    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
    const response = await fetch(`${baseUrl}/shopify-api/costumers/?marketing=${marketing}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

  if ((response.status === 401 || response.status === 403) && retryCount < 1) {
    await updateToken();
    return getCustomers(updateToken, logoutUser, retryCount + 1);  // Retry after refreshing token
  } else if ((response.status === 401 || response.status === 403) && retryCount >= 1) {
    logoutUser();
    throw new Error('Authentication failed. Logging out.');
  }

  if (!response.ok) {
    const responseText = await response.text();
    console.error(`HTTP error! status: ${response.status}, body: ${responseText}`);
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

const useGetCustomer = (marketing = 'all') => {  
    const { updateToken, logoutUser } = useContext(AuthContext);
    
    const { data: customers, isLoading: isCustomerLoading, isError: isCustomerError } = useQuery(
      ['customers', marketing],  // Add marketing as part of the query key
      () => getCustomers(updateToken, logoutUser, marketing, 0)
    );
    
    return { customers, isCustomerLoading, isCustomerError };
  };
  
  export default useGetCustomer;

