import { useState } from 'react';

const useUpdateDiscount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateDiscount = async ({ id, price_rule_id, starts_at, ends_at }) => {
    setIsLoading(true);
    const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:8000";
    
    try {
      const response = await fetch(`${baseUrl}/shopify-api/discounts/${id}/${price_rule_id}/`, {
        method: 'PUT',
        body: JSON.stringify({ starts_at, ends_at }),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include this line if you're including session-based authentication
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Something went wrong!');
      }

      setError(null);
      const data = await response.json();
      console.log('Discount updated', data);
      return data; // Return data in case it is needed after hook usage
    } catch (err) {
      setError(err.message || 'An error occurred while updating the discount.');
    } finally {
      setIsLoading(false);
    }
  };

  return { updateDiscount, isLoading, error };
};

export default useUpdateDiscount;
