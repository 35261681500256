import { useQuery } from 'react-query';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext'; // Ensure the path to AuthContext is correct.

const fetchEmails = async (updateToken, logoutUser, retryCount = 0) => {
  const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:8000";
  const response = await fetch(`${baseUrl}/webhook/sent-emails/`, {
    method: 'GET',
    credentials: 'include',
  });

  if ((response.status === 401 || response.status === 403) && retryCount < 1) {
    await updateToken();
    return fetchEmails(updateToken, logoutUser, retryCount + 1); // Retry after refreshing token
  } else if ((response.status === 401 || response.status === 403) && retryCount >= 1) {
    logoutUser();
    throw new Error('Authentication failed. Logging out.');
  }

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const useGetEmails = () => {
  const { updateToken, logoutUser } = useContext(AuthContext);

  const { data: emails, isLoading, isError, error } = useQuery(
    'emails',
    () => fetchEmails(updateToken, logoutUser),
    {
      retry: 1,
    },
  );

  return { emails, isLoading, isError, error };
};

export default useGetEmails;