import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import GetAllOrdersDB from '../../../../Hooks/Orders/getAllOrdersDb';
import { useUpdateOrders } from '../../../../Hooks/Orders/updateOrdersDB';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteOrder } from '../../../../Hooks/Orders/delOrder';
import OrderGridToolbar from '../components/OrderGridToolbar';
import EditIcon from '@mui/icons-material/Edit'; // Import the edit icon
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { useLocation } from 'react-router-dom'; // Import useLocation
import { format, addDays, startOfToday, addWeeks } from 'date-fns';
import CustomSnackbar from 'components/SnackBar';
import translations from "translations.js";
import Chip from '@mui/material/Chip';

  
export default function NewOrdersGrid() {
    const { orders, isLoading, isError } = GetAllOrdersDB("new", true);
    const [rows, setRows] = useState([]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const updateOrders = useUpdateOrders();
    const deleteOrder = useDeleteOrder();
    const navigate = useNavigate(); // Initialize navigate function
    const location = useLocation(); // Get the current location
  


    useEffect(() => {
        if (!isLoading && !isError && orders) {
          let formattedOrders = orders.map(order => {
            const leveransdatum = order?.note_attributes?.find(attr => attr.name === 'Leveransdatum')?.value || '';
            const store_location = order?.note_attributes?.find(attr => attr.name === 'Upphamtningsplats')?.value || '';
            const leveranstid = order?.note_attributes?.find(attr => attr.name === 'Leveranstid')?.value || '';
          
            const date = `${leveransdatum}, ${leveranstid}`;
            return {
              shopify_order_id: order.shopify_order_id,

              firstName: order?.customer?.first_name || '',
              lastName: order?.customer?.last_name || '',

              date: date,
              leveransdatum: leveransdatum, // store the date for sorting
              store_location: store_location,
              financial_status: translations.financialStatus[order.financial_status]
            };
          });
          // Convert the 'dd/mm/yyyy' date string to 'yyyy-mm-dd' format for sorting
          formattedOrders.sort((a, b) => {
            const dateA = a?.leveransdatum?.split('/').reverse().join('-');
            const dateB = b?.leveransdatum?.split('/').reverse().join('-');
            return new Date(dateA) - new Date(dateB);
          });
      
          setRows(formattedOrders);
        }
      }, [orders, isLoading, isError]);
      
      const handleEdit = (id) => {
        navigate(`${location.pathname}/${id}`); // Append order ID to the current path
    };


      const handleDelete = (id) => {
        deleteOrder.mutate(id);
      };
      
      const handleOptionTwo = () => {
        console.log('Selected order IDs to update (before mutation):', selectedRowIds);
        updateOrders.mutate({
            selectedOrderIds: selectedRowIds,
            newStatus: 'in_progress'
        },
        {
          onSuccess: () => {
            handleOpenSnackbar('Valda ordrar flyttade!', 'success');
          },
          onError: (error) => {
            handleOpenSnackbar('Något gick fel!', 'error');
            console.log(error);
          }
        });
    };

    const handleOptionThree = () => {
      console.log('THIS IS THE BASE FOR THIS ORDERS: ', selectedRowIds);
  };

      const filterOrders = ({ store, date }) => {
        const today = format(startOfToday(), 'dd/MM/yyyy');
        const tomorrow = format(addDays(new Date(), 1), 'dd/MM/yyyy');
        const nextWeek = format(addWeeks(new Date(), 1), 'dd/MM/yyyy');
    
        let filteredOrders = orders.map(order => {
            // Same logic as in useEffect to format each order
            const leveransdatum = order.note_attributes.find(attr => attr.name === 'Leveransdatum')?.value || '';
            const leveranstid = order.note_attributes.find(attr => attr.name === 'Leveranstid')?.value || '';
            const store_location = order?.note_attributes?.find(attr => attr.name === 'Upphamtningsplats')?.value || '';
            const financial_status = translations.financialStatus[order.financial_status]; // Ensure this line is correct

            const date = `${leveransdatum}, ${leveranstid}`;
            return {
                shopify_order_id: order.shopify_order_id,
                financial_status, // Include this property

                firstName: order?.customer?.first_name || '',
                lastName: order?.customer?.last_name || '',

              date: date,
                leveransdatum: leveransdatum,
                store_location: store_location
            };
          }).filter(order => {
            console.log('store:', store)
            const matchesStore = store ? order.store_location === store : true;
            const matchesDate = date ? (date === 'today' && order.leveransdatum === today) ||
                                       (date === 'tomorrow' && order.leveransdatum === tomorrow) ||
                                       (date === 'nextWeek' && new Date(order.leveransdatum) <= new Date(nextWeek))
                                     : true;
            return matchesStore && matchesDate;
        });
    
        setRows(filteredOrders);
    };
    

    const handleOpenSnackbar = (message, severity) => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setOpenSnackbar(true);
    };

    const handleCloseSnackbar = () => {
      setOpenSnackbar(false);
    };

    const clearFilters = () => {
      if (!isLoading && !isError && orders) {
        const formattedOrders = orders.map(order => {
          // Apply the same formatting as you do when you first receive the orders
          const leveransdatum = order?.note_attributes?.find(attr => attr.name === 'Leveransdatum')?.value || '';
          const store_location = order?.note_attributes?.find(attr => attr.name === 'Upphamtningsplats')?.value || '';
          const leveranstid = order?.note_attributes?.find(attr => attr.name === 'Leveranstid')?.value || '';
          const financial_status = translations.financialStatus[order.financial_status] || "Okänd"; // Fallback to "Okänd" if no translation found

          const date = `${leveransdatum}, ${leveranstid}`;
          return {
            shopify_order_id: order.shopify_order_id, // Ensure this is the unique identifier for each row

            firstName: order?.customer?.first_name || '',
            lastName: order?.customer?.last_name || '',
            financial_status,

            date: date,
            leveransdatum: leveransdatum, // store the date for sorting
            store_location: store_location
          };
        });
    
        // If you need to sort the formattedOrders again, do so here
        formattedOrders.sort((a, b) => {
          const dateA = a?.leveransdatum?.split('/').reverse().join('-');
          const dateB = b?.leveransdatum?.split('/').reverse().join('-');
          return new Date(dateA) - new Date(dateB);
        });
    
        setRows(formattedOrders); // Update the rows with formatted orders
      } else {
        setRows([]); // If no orders, set rows to an empty array
      }
    };
    


    const columns = [
      { field: 'shopify_order_id', headerName: 'ID', flex: 0.6, minWidth: 120 },
      {
          field: 'fullName',
          headerName: 'Namn',
          description: 'Kundens namn',
          sortable: false,
          flex: 0.8,
          minWidth: 120,
          valueGetter: (params) =>
            `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      },
      {
          field: 'date',
          headerName: 'Datum',
          description: 'Leveransdatum och tid',
          flex: 0.8,
          minWidth: 110,
      },
      {
          field: 'store_location',
          headerName: 'Butik',
          description: 'Upphämtningsplats',
          flex: 1,
          minWidth: 110,
      },
      {
        field: 'financial_status',
        headerName: 'Betalstatus',
        description: 'Betalstatusen för ordern',
        flex: 0.5,
        minWidth: 110,
        renderCell: (params) => {
          let chipColor = '#ffffff'; // Default color for the chip
          // Ensure params.value is not undefined before proceeding
          if (!params.value) {
              return <Chip label="Okänd" sx={{ backgroundColor: '#f0f0f0', color: 'black' }} size="small" />;
          }
          if (params.value === 'Betald') {
              chipColor = '#E4F6E8'; // Green color for 'paid'
          } else if (params.value === 'Obetald') {
              chipColor = '#FAEFF0'; // Yellow color for 'unpaid'
          } else if (params.value === 'Delvis betald') {
            chipColor = '#FFF3DD'; // Example yellow color for 'pending'
        } 
          // Add more conditions as needed
      
          return (
              <Chip 
                  label={params.value} 
                  sx={{ backgroundColor: chipColor, color: 'black' }} 
                  size="small" 
              />
          );
      },
      
    },
      {
          field: 'actions',
          type: 'actions',
          width: 80,
          getActions: (params) => [
              <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  onClick={() => handleEdit(params.id)}
              />,
              <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={() => handleDelete(params.id)}
              />,
          ],
      },
  ];
  
    

    return (
        <Box sx={{ height: '75vh', width: '100%' }}> 
            <DataGrid
                loading={isLoading} // Set the loading prop to the isLoading state
                rows={rows}
                columns={columns}
                sx={{
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none',
                    },
                    '& .MuiDataGrid-cell:focus-within': {
                      outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      backgroundColor: '#D7EBFE', // This sets the background color of the header
                    },
                  }}
                  getRowId={(row) => row.shopify_order_id}
                checkboxSelection
                onRowSelectionModelChange={(newSelection) => {
                    console.log('New Selection:', newSelection);
                    setSelectedRowIds(newSelection);
                }}
                slots={{
                  toolbar: OrderGridToolbar,
                }}
                slotProps={{
                  toolbar: {
                    selectedRows: selectedRowIds,
                    handleOptionTwo: handleOptionTwo,
                    handleOptionThree: handleOptionThree,
                    onFilterOrders: filterOrders,
                    orders: orders,
                    clearFilters: clearFilters,
                  },
                }}
                disableSelectionOnClick
            />

            <CustomSnackbar
              open={openSnackbar}
              handleClose={handleCloseSnackbar}
              message={snackbarMessage}
              severity={snackbarSeverity}
            />
        </Box>
    );
}