import React from 'react';
import { Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CustomDateTimePicker from 'components/CustomDateTimePicker';
// onBlur={() => onSave(params.row, field)}
const DateCellEditor = ({ params, field, isEditing, onValueChange, onSave, onStartEditing }) => {
    const DateConversions = {
        starts_at: 'starts_at_input',
        ends_at: 'ends_at_input',
        };
  return (
    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {isEditing ? (
        <CustomDateTimePicker value={params.row[DateConversions[field]]} onChange={onValueChange}/>
      ) : (
        <>
          <span>{params.row[field]}</span>
          <IconButton
            size="small"
            onClick={() => onStartEditing(params.id, field)}
            sx={{ visibility: 'hidden', ml: '10px' }}
            className="edit-icon"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default DateCellEditor;