import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import AllOrdersGrid from './grids/AllOrdersGrid';
import NewOrdersGrid from './grids/NewOrdersGrid';
import InProgressOrdersGrid from './grids/InProgressOrdersGrid';
import CompletedOrdersGrid from './grids/CompletedOrdersGrid';
import DeliveredOrdersGrid from './grids/DeliveredOrdersGrid';


import { ButtonGroup,Button } from '@mui/material';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

const MainComponent = () => {
    // Set the initial state to 'new'

    const handleStatusChange = (newStatus) => {
        setCurrentStatus(newStatus.toLowerCase());
    };

    const initializeCurrentStatus = () => {
      const savedStatus = localStorage.getItem('currentStatus');
      return savedStatus || 'new'; // Return saved status or 'new' as default
  };

  const [currentStatus, setCurrentStatus] = useState(initializeCurrentStatus); 



      // Effect to load the saved status from localStorage
  useEffect(() => {
    const savedStatus = localStorage.getItem('currentStatus');
    if (savedStatus) {
      setCurrentStatus(savedStatus);
    }
  }, []);


    // Effect to save the status to localStorage when it changes
    useEffect(() => {
        localStorage.setItem('currentStatus', currentStatus);
      }, [currentStatus]);

  
  const renderGrid = () => {
    switch (currentStatus) {
      case 'all':
        return <AllOrdersGrid />;
      case 'new':
        return <NewOrdersGrid />;
      case 'in_progress':
        return <InProgressOrdersGrid />;
      case 'completed':
        return <CompletedOrdersGrid />;
      case 'delivered':
        return <DeliveredOrdersGrid />;
      default:
        return <NewOrdersGrid />; // Default to NewOrdersGrid if somehow an unknown status is received
    }
  };


  const buttonGroupSx = {
    width: '100%',
    boxShadow: 'none',
    '& .MuiButtonGroup-grouped': {
      flexGrow: 1,
      border: '1px solid rgba(0, 0, 0, 0.1)', // Light border for all buttons
      '&:not(:last-of-type)': {
        marginRight: '10px', // Spacing between buttons
      },
      '&:first-of-type': {
        marginLeft: '10px', // Margin for the first button
      },
      '&:last-of-type': {
        marginRight: '10px', // Margin for the last button
      },
      borderRadius: '10px', // Remove the rounded corners
      marginBottom: '10px', // Margin bottom for all buttons
      marginTop: '10px', // Margin top for all buttons
    },
  };

    // Add this style for the line above the table
    const topLineStyle = {
        height: '10px', // Adjust the height of the line as per your design
        bgcolor: 'primary.main', // Use your theme's primary color or any color you want for the line
        width: '100%', // Ensure the line spans the full width
      };

    // Styling for the buttons
    const buttonSx = (isSelected, currentStatus) => {
      let bgcolor;
      let borderColor;
      let color;
       if (isSelected) {
    // Apply specific colors for each status when selected
    switch (currentStatus) {
      case 'new':
        bgcolor = '#D7EBFE';
        borderColor = '#1463B8'; // Darker shade of bgcolor for 'new'
        color = '#1463B8'; // Darker shade of bgcolor for 'new'
        break;
      case 'in_progress':
        bgcolor = '#FFF6C2';
        borderColor = '#625818'; // Darker shade of bgcolor for 'in_progress'
        color = '#625818'; // Darker shade of bgcolor for 'in_progress'
        break;
      case 'completed':
        bgcolor = '#FFEDD7';
        borderColor = '#593D21'; // Darker shade of bgcolor for 'completed'
        color = '#593D21'; // Darker shade of bgcolor for 'completed'
        break;
      case 'delivered':
        bgcolor = '#C5E7E6';
        borderColor = '#204346'; // Darker shade of bgcolor for 'delivered'
        color = '#204346'; // Darker shade of bgcolor for 'delivered'
        break;
      case 'all':
        color = 'common.white'; // Default darker shade
        break;
      default:
        bgcolor = 'primary.main'; // Default selected color
        borderColor = 'primary.dark'; // Default darker shade
    }
    } else {
      bgcolor = 'background.paper'; // White for non-active
      borderColor = 'rgba(0, 0, 0, 0.05)'; // Default light border for non-active
    }
    
      return {
        bgcolor,
        color: isSelected ? `${color}`: 'text.primary', // white text for active, primary text color for non-active
        fontWeight: isSelected ? 'bold' : 'normal', // bold text for active, normal text for non-active
        '&:hover': {
          bgcolor: isSelected ? `${borderColor}` : 'grey.100', // darker blue on hover for active, light grey for non-active
          color: isSelected ? 'common.white' : 'text.primary', // white text for active, primary text color for non-active
        },
        height: '60px',
        textTransform: 'none', // Remove the uppercase styling
        border: `1px solid ${borderColor}`,
        boxShadow: 'none', // Remove the shadow
      };
    };
    


    return (
        <Box sx={{ p: 3 }}> {/* Add padding around the entire Box */}
          <Box sx={{ mb: 3 }}> {/* Add margin at the bottom of the ButtonGroup container for spacing */}
            <ButtonGroup sx={buttonGroupSx} variant="contained" aria-label="outlined primary button group">
            <Button sx={buttonSx(currentStatus === 'all', 'all')} onClick={() => handleStatusChange('all')}>
              <ClearAllIcon sx={{ mr: 1 }} /> {/* Icon with right margin */}
              Alla
            </Button>
              <Button sx={buttonSx(currentStatus === 'new', 'new')} onClick={() => handleStatusChange('new')}>
                <InfoOutlinedIcon fontSize="small" sx={{ mr: 1 }} /> {/* Icon with right margin */}  
                Nya
              </Button>
              <Button sx={buttonSx(currentStatus === 'in_progress', 'in_progress')} onClick={() => handleStatusChange('in_progress')}>
                <DonutLargeOutlinedIcon fontSize="small" sx={{ mr: 1 }} /> {/* Icon with right margin */}
                Pågående
                </Button>
              <Button sx={buttonSx(currentStatus === 'completed', 'completed')} onClick={() => handleStatusChange('completed')}>
                <DoneAllOutlinedIcon fontSize="small" sx={{ mr: 1 }} /> {/* Icon with right margin */}
                Färdiga
                </Button>
              <Button sx={buttonSx(currentStatus === 'delivered', 'delivered')} onClick={() => handleStatusChange('delivered')}>
                <LocalShippingOutlinedIcon fontSize="small" sx={{ mr: 1 }} /> {/* Icon with right margin */}
                Levererade
                </Button>
            </ButtonGroup>
          </Box>
          <Box sx={topLineStyle} /> {/* This Box acts as the colored line */}
          {renderGrid()}
        </Box>
      );
    };
    
    export default MainComponent;