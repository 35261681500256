import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Box, TextField, Button, FormControl, Grid, Typography, styled, Rating } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import { useSubmitSurvey } from 'Hooks/Feedback/CreateSurvey';

// Styled Rating component
const StyledRating = styled(Rating)({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: '#aaa', // Or any other color
  },
});

// Custom icons for the rating
const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" style={{ fontSize: '2.5rem' }} />,
      label: 'Very Dissatisfied',
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" style={{ fontSize: '2.5rem' }} />,
      label: 'Dissatisfied',
    },
    3: {
      icon: <SentimentSatisfiedIcon color="warning" style={{ fontSize: '2.5rem' }} />,
      label: 'Neutral',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon color="success" style={{ fontSize: '2.5rem' }} />,
      label: 'Satisfied',
    },
    5: {
      icon: <SentimentVerySatisfiedIcon color="success" style={{ fontSize: '2.5rem' }} />,
      label: 'Very Satisfied',
    },
  };


  function IconContainer(props) {
    const { value, selectedValue, ...other } = props;
    return (
      <span {...other}>
        {React.cloneElement(customIcons[value].icon, {
          color: value === selectedValue ? customIcons[value].icon.props.color : 'disabled'
        })}
      </span>
    );
  }
  
  IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
    selectedValue: PropTypes.number
  };

function FeedbackForm() {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [rating, setRating] = useState(2); // Rating state
    const { executeRecaptcha } = useGoogleReCaptcha();
    const location = useLocation();

    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false); 
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [isValidToken, setIsValidToken] = useState(false);
    const [token, setToken] = useState('');
    const [tokenValidationMessage, setTokenValidationMessage] = useState('');

    const { mutate: submitSurvey } = useSubmitSurvey(); // Declare the submitSurvey function


  useEffect(() => {
    const validateToken = async (token) => {
        const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
        try {
          const response = await axios.get(`${baseUrl}/survey/validate-feedback-token/${token}`);
          setIsValidToken(response.data.valid);
          setTokenValidationMessage(response.data.message);
          if (!response.data.valid) {
            setDialogMessage(response.data.message);
            setDialogOpen(true);
          }
        } catch (error) {
          console.error('Error validating token:', error);
          if (error.response && error.response.data && error.response.data.message) {
            // Handle the specific error message "Det finns ingen beställning med detta id"
            if (error.response.data.message === "Det finns ingen beställning med detta id") {
              setTokenValidationMessage("There is no order with this ID.");
            } else {
              setTokenValidationMessage('Error occurred while validating token.');
            }
          } else {
            setTokenValidationMessage('Error occurred while validating token.');
          }
        }
      };
    

    const queryParams = new URLSearchParams(location.search);
    const feedbackToken = queryParams.get('token');
    if (feedbackToken) {
      setToken(feedbackToken);
      validateToken(feedbackToken);
    }
  }, [location]);



  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const FeedbackTokenDialog = () => (
    <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Feedback Token Status"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const onSubmit = async (data) => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      return;
    }
  
    try {
      const recaptchaToken = await executeRecaptcha('submit_feedback');
      const formData = {
        ...data,
        rating,
        token,
        recaptchaToken,
      };
  
      submitSurvey(formData, {
        onSuccess: (response) => {
          console.log('Feedback Submitted Successfully:', response);
          setFeedbackSubmitted(true);
        },
        onError: (error) => {
          console.error('Failed to submit feedback:', error);
        },
      });
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
};
  

if (feedbackSubmitted) {
    return <Typography variant="h5" style={{ color: '#4CAF50', textAlign: 'center' }}>Stort tack för din feedback!</Typography>;
}

if (!isValidToken) {
    return (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
            <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                <Card style={{
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    maxWidth: '600px',
                    margin: 'auto',
                }}>
                    <CardContent>
                        <Alert severity="error" style={{ wordWrap: 'break-word' }}>
                            <AlertTitle style={{ fontSize: '1.2rem' }}>{tokenValidationMessage}</AlertTitle>
                            <Typography variant="body1" style={{ margin: '8px 0' }}>
                                Har du annan feedback kontakta oss på —
                            </Typography>
                            <Link
                                href="https://www.shopflow.se/kontakta-oss"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: '1.1rem', textDecoration: 'underline' }}
                            >
                                www.shopflow.se/kontakta-oss
                            </Link>
                        </Alert>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

  return (
    <Grid container justifyContent="center">
      <FeedbackTokenDialog />
      <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 8,
            padding: 3,
            maxWidth: '100%',
            '& .MuiTextField-root': { m: 1, width: '100%' },
            mt: 8,
          }}
          noValidate
          autoComplete="off"
        >
          <Typography style={{textAlign: 'center'}} variant="h2" mb="30px" gutterBottom>
            Hur nöjd är du med din beställning
          </Typography>
  
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={12}>
              <FormControl fullWidth sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <StyledRating
            name="customized-icons"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
            getLabelText={(value) => customIcons[value].label}
            IconContainerComponent={(props) => <IconContainer {...props} selectedValue={rating} />}
          />
              </FormControl>
            </Grid>
  
            {rating <= 2 && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Vad tyckte du gick dåligt?"
                  name="improvement_feedback"
                  multiline
                  rows={4}
                  {...register('improvement_feedback')}
                  error={errors.improvement_feedback ? true : false}
                  helperText={errors.improvement_feedback?.message}
                />
              </Grid>
            )}
  
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Vad tyckte du gick bra?"
                name="positive_feedback"
                multiline
                rows={4}
                {...register('positive_feedback')}
                error={errors.positive_feedback ? true : false}
                helperText={errors.positive_feedback?.message}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                fullWidth
                label="Vad gick mindre bra"
                name="improvement_feedback"
                multiline
                rows={4}
                {...register('improvement_feedback')}
                error={errors.improvement_feedback ? true : false}
                helperText={errors.improvement_feedback?.message}
              />
            </Grid> */}
          </Grid>
  
          <Button type="submit" variant="contained" sx={{ mt: 3 }}>
            Submit Feedback
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
            }  

export default FeedbackForm;
