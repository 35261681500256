import React, { useState } from 'react';
import { Box, Typography, Grid, Drawer, Divider } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LoadingComponent from "components/LoadingComponent.jsx";
import ErrorComponent from "components/ErrorComponent.jsx";
import SurveyStatsCards from './surveyStatsCard.jsx';
import useGetSurveys from 'Hooks/Survey/useGetSurveys.js';
import {IconButton } from '@mui/material';
import Rating from '@mui/material/Rating';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import StyledBox from 'components/StyledBox.jsx';

import {
    List,
    ListItem,
    ListItemText,
  } from '@mui/material';


function SurveyStats() {
    const { surveys, isSurveyLoading, isSurveyError } = useGetSurveys();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedSurvey, setSelectedSurvey] = useState(null);

    // Function to open the drawer
    const handleDrawerOpen = (survey) => {
        setSelectedSurvey(survey);
        setDrawerOpen(true);
    };

    // Function to close the drawer
    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    if (isSurveyLoading || !surveys) {
        return <LoadingComponent />;
    }

    if (isSurveyError) {
        return <ErrorComponent error={isSurveyError} />;
    }

    const ratingDistribution = Array.from({ length: 5 }, (_, index) => ({
        name: `${index + 1}`,
        Count: surveys.filter(survey => Math.round(survey.rating) === index + 1).length
      }));
    
    //   const COLORS = ['#FFBB28', '#FF8042', '#00C49F', '#0088FE', '#FF0000'];


      const renderBarChart = () => (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={ratingDistribution}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            layout="vertical"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis type="category" dataKey="name" />
            <Tooltip />
            <Legend />
            <Bar dataKey="Count" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      );


    // Drawer content
// Drawer content styled like the screenshot
const drawerContent = (
    <Box sx={{ width: 500, padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Survey Details
      </Typography>
      <Divider sx={{ my: 2 }} />
  
      <List disablePadding>
        <ListItem sx={{ pb: 0 }}>
          <ListItemText primary="Positiv Feedback" secondary={selectedSurvey?.positive_feedback || "N/A"} />
        </ListItem>
        <Divider />
        <ListItem sx={{ py: 0 }}>
          <ListItemText primary="Konstruktiv Feedback" secondary={selectedSurvey?.improvement_feedback || "N/A"} />
        </ListItem>
        <Divider />
        <ListItem sx={{ py: 0 }}>
        <ListItemText primary="Betyg" />
        <Rating
          name="read-only"
          value={selectedSurvey?.rating || 0}
          readOnly
          precision={0.5}
        />
      </ListItem>
        <Divider />
        <ListItem sx={{ py: 0 }}>
          <ListItemText primary="Created At" secondary={formatDate(selectedSurvey?.created_at) || "N/A"} />
        </ListItem>
      </List>
    </Box>
  );
  


    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-US', options);
      }

    const averageRating = surveys.reduce((acc, survey) => acc + survey.rating, 0) / surveys.length;
    const totalSurveys = surveys.length;
    const positiveNegativeBalance = 'Your calculated value'; // Replace with actual calculation

    // Define columns for Data Grid with flexible width and custom cell styling
    const columns = [
        { 
            field: 'positive_feedback', 
            headerName: 'Positiv Feedback', 
            flex: 1, 
            minWidth: 150 
        },
        { 
            field: 'improvement_feedback', 
            headerName: 'Konstruktiv Feedback', 
            flex: 1, 
            minWidth: 150 
        },
        {
            field: 'rating',
            headerName: 'Betyg',
            type: 'number',
            flex: 0.9,
            minWidth: 100,
            renderCell: (params) => (
              <Box sx={{ display: 'flex', alignItems: 'center', mr: -2 }}> {/* Adjust margin if necessary */}
                <Rating
                  name="read-only"
                  value={params.value}
                  readOnly
                  precision={0.5}
                  size="small"
                />
              </Box>
            ),
            headerAlign: 'left', // Ensure the header is also left-aligned
            align: 'left', // Align cell content to the left
          },
        { 
            field: 'created_at', 
            headerName: 'Skapad', 
            flex: 1, 
            minWidth: 150,
            valueFormatter: (params) => formatDate(params.value)
        },
        {
            field: 'details',
            headerName: 'Läs mer',
            sortable: false,
            renderCell: (params) => (
              <IconButton
                color="primary"
                onClick={() => handleDrawerOpen(params.row)}
                aria-label="view more"
              >
                <ReadMoreIcon />
              </IconButton>
            ),
          },
        ];
    

    // Prepare rows for Data Grid
    const rows = surveys.map((survey, index) => ({
        id: index,
        ...survey
    }));

    return (
        <Box sx={{ flexGrow: 1, padding: 5 }}>
            <Grid container spacing={3} justifyContent="center">
                {/* Survey Stats Cards */}
                <Grid item xs={12}>
                <SurveyStatsCards 
                    averageRating={averageRating} 
                    totalSurveys={totalSurveys} 
                    positiveNegativeBalance={positiveNegativeBalance} 
                />   
                </Grid>             
                {/* Survey Data Grid */}
                <Grid item xs={12}>
                    <StyledBox>
                            <Typography variant="h6" gutterBottom>
                                Enkät svar
                            </Typography>
                            <Box style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                 rows={rows}
                                 columns={columns}
                                 pageSize={5}
                                 checkboxSelection
                                 getCellClassName={(params) => {
                                     if (params.field === 'rating') {
                                         return 'rating-cell-padding';
                                     }
                                     return '';
                                 }}
                             />
                         </Box>
                 </StyledBox>
             </Grid>
         </Grid>
         <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerClose}
            >
                {drawerContent}
            </Drawer>
            <Grid item xs={12}>
            <StyledBox>
                <Typography variant="h6" gutterBottom>
                    Betyg statistik
                </Typography>
                {renderBarChart()}
            </StyledBox>
            </Grid>
     </Box>
 );
                                
}

export default SurveyStats;