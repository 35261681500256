import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import CompletedOrdersGrid from './grids/CompletedOrdersGrid';
import DeliveredOrdersGrid from './grids/DeliveredOrdersGrid';
import AuthContext from 'context/AuthContext';


import { ButtonGroup,Button } from '@mui/material';


const RestrictedMainOstenssons = () => {
    // Set the initial state to 'new'
    const [currentStatus, setCurrentStatus] = useState('new'); 
    let { user } = useContext(AuthContext);

    const handleStatusChange = (newStatus) => {
        setCurrentStatus(newStatus.toLowerCase());
    };


      // Effect to load the saved status from localStorage
  useEffect(() => {
    const savedStatus = localStorage.getItem('currentStatus');
    if (savedStatus) {
      setCurrentStatus(savedStatus);
    }
  }, []);


    // Effect to save the status to localStorage when it changes
    useEffect(() => {
        localStorage.setItem('currentStatus', currentStatus);
      }, [currentStatus]);

  
  const renderGrid = () => {
    switch (currentStatus) {
      case 'completed':
        return <CompletedOrdersGrid />;
      case 'delivered':
        return <DeliveredOrdersGrid />;
      default:
        return <CompletedOrdersGrid />; // Default to NewOrdersGrid if somehow an unknown status is received
    }
  };


  const buttonGroupSx = {
    width: '100%', // Set the width of the ButtonGroup to 100% of its parent container
    '.MuiButtonGroup-grouped': { // Target the grouped button styles
      flexGrow: 1, // Allow buttons to grow and fill up the container
      '&:not(:last-of-type)': { // Remove the border radius and margin between the buttons
        borderRadius: 0,
        borderColor: 'divider',
      },
      '&:first-of-type': {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
      },
      '&:last-of-type': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
      },
    },
  };

    // Add this style for the line above the table
    const topLineStyle = {
        height: '10px', // Adjust the height of the line as per your design
        bgcolor: 'primary.main', // Use your theme's primary color or any color you want for the line
        width: '100%', // Ensure the line spans the full width
      };

    // Styling for the buttons
    const buttonSx = (isSelected) => ({
        bgcolor: isSelected ? 'primary.main' : 'background.paper', // blue for active, white for non-active
        color: isSelected ? 'common.white' : 'text.primary', // white text for active, primary text color for non-active
        '&:hover': {
            bgcolor: isSelected ? 'primary.dark' : 'grey.200', // darker blue on hover for active, light grey for non-active
        },
        height: '60px', // height of the buttons
        // additional styles
    });
    


    return (
        <Box sx={{ p: 3 }}> {/* Add padding around the entire Box */}
        <h1>{ user?.restricted_store_name }</h1>
          <Box sx={{ mb: 3 }}> {/* Add margin at the bottom of the ButtonGroup container for spacing */}
            <ButtonGroup sx={buttonGroupSx} variant="contained" aria-label="outlined primary button group">
              <Button sx={buttonSx(currentStatus === 'completed')} onClick={() => handleStatusChange('completed')}>Färdig</Button>
              <Button sx={buttonSx(currentStatus === 'delivered')} onClick={() => handleStatusChange('delivered')}>Leveread</Button>
            </ButtonGroup>
          </Box>
          <Box sx={topLineStyle} /> {/* This Box acts as the colored line */}
          {renderGrid()}
        </Box>
      );
    };
    
    export default RestrictedMainOstenssons;