import React from "react";
import GetAllOrdersDB from "Hooks/Orders/getAllOrdersDb";
import { Box, Typography, Grid } from "@mui/material";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
} from "recharts";

import LoadingComponent from "components/LoadingComponent.jsx";
import ErrorComponent from "components/ErrorComponent.jsx";
import moment from "moment";
import OrderStatsCards from "./orderStatsCards";
import YearlyHeatmap from "../components/Heatmap";
import StyledBox from "components/StyledBox";

function OrderStats() {
  const { orders, isLoading, error } = GetAllOrdersDB("", false, null);
  console.log("🚀 ~ file: orderStats.jsx:10 ~ OrderStats ~ orders:", orders);
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AB68FF"];
  if (isLoading || !orders) {
    return <LoadingComponent />;
  }

  if (error) {
    return <ErrorComponent error={error} />;
  }

  // Calculate Total Orders
  const getTotalOrdersLastYear = () => {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    // Filter orders from the last year
    const ordersLastYear = orders.filter((order) => {
      const orderDate = new Date(order.created_at);
      return orderDate >= oneYearAgo;
    });

    return ordersLastYear.length;
  };

  // Calculate Day with Most Orders
  const dayWithMostOrders = () => {
    if (!orders) return "";
    const dayCounts = {};
    orders?.forEach((order) => {
      // Extract the day of the week in Swedish
      const dayOfWeek = new Date(order.created_at).toLocaleString("sv-SE", {
        weekday: "long",
      });
      dayCounts[dayOfWeek] = (dayCounts[dayOfWeek] || 0) + 1;
    });

    // Find the weekday with the highest order count
    return Object.keys(dayCounts)?.reduce((a, b) =>
      dayCounts[a] > dayCounts[b] ? a : b
    );
  };

  // Calculate Week of Year with Most Deliveries
  const weekWithMostDeliveries = () => {
    const weekCounts = {};
    orders?.forEach((order) => {
      const deliveryDate = order.note_attributes.find(
        (attr) => attr.name === "Leveransdatum"
      )?.value;
      if (deliveryDate) {
        const week = moment(deliveryDate, "DD/MM/YYYY").isoWeek();
        weekCounts[week] = (weekCounts[week] || 0) + 1;
      }
    });

    return Object.keys(weekCounts)?.reduce((a, b) =>
      weekCounts[a] > weekCounts[b] ? a : b
    );
  };

  // Process orders to get count per month
  const countOrdersPerMonth = () => {
    let count = {};
    const lastYear = new Date(
      new Date().setFullYear(new Date().getFullYear() - 1)
    );

    orders?.forEach((order) => {
      const createdAt = new Date(order.created_at);
      if (createdAt > lastYear) {
        const month = createdAt.getMonth();
        count[month] = (count[month] || 0) + 1;
      }
    });

    return Object.keys(count).map((month) => ({
      month: new Date(0, month).toLocaleString("default", { month: "long" }),
      orders: count[month],
    }));
  };

  const countOrdersByLocationLastYear = () => {
    const locationCounts = {};
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    orders?.forEach((order) => {
      const orderDate = new Date(order.created_at);
      if (orderDate >= oneYearAgo) {
        const upphamtningsplats = order.note_attributes.find(
          (attr) => attr.name === "Upphamtningsplats"
        )?.value;
        if (upphamtningsplats) {
          locationCounts[upphamtningsplats] =
            (locationCounts[upphamtningsplats] || 0) + 1;
        }
      }
    });

    return Object.entries(locationCounts).map(([location, count]) => ({
      location,
      orders: count,
    }));
  };
  const calculateTopSellingProducts = (orders) => {
    if (!orders) return [];
    const productCounts = {};
    orders?.forEach((order) => {
      order.line_items?.forEach((item) => {
        const productName = item.name;
        const quantity = item.quantity;

        if (!productCounts[productName]) {
          productCounts[productName] = 0;
        }

        productCounts[productName] += quantity;
      });
    });

    // Convert to array, sort by count, and select the top 5
    return Object.entries(productCounts)
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 5);
  };

  const salesPerMonth = () => {
    let sales = {};
    const lastYear = new Date().getFullYear() - 1;
    const monthMapping = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];
  
    orders?.forEach((order) => {
      const createdAt = new Date(order.created_at);
      const year = createdAt.getFullYear();
      const monthIndex = createdAt.getMonth();
      const month = monthMapping[monthIndex] + (year === lastYear ? "_prev" : "_curr");
      let orderTotal = parseFloat(order.total_price) || 0;
      sales[month] = (sales[month] || 0) + orderTotal;
    });
  
    // Adding fake sales data for the next three months
    sales["feb_curr"] = 1000000; // Fake sales for February
    sales["mar_curr"] = 3000; // Fake sales for March
    sales["apr_curr"] = 5002500; // Fake sales for April
    sales["maj_curr"] = 1000000; // Fake sales for April
    sales["jun_curr"] = 250000; // Fake sales for April
    sales["jul_curr"] = 2000000; // Fake sales for April
    sales["aug_curr"] = 2000000; // Fake sales for April
    sales["sep_curr"] = 500000; // Fake sales for April
    sales["okt_curr"] = 5000000; // Fake sales for April
    sales["nov_curr"] = 1000000; // Fake sales for April
  
    const salesArray = Object.keys(sales).map((month) => ({
      month: month.replace("_prev", "").replace("_curr", ""),
      year: month.includes("_prev") ? lastYear : lastYear + 1,
      sales: sales[month].toFixed(2),
      sortKey: month
    }));
  
    // Sort the months considering the year
    return salesArray.sort((a, b) => {
      if (a.sortKey.includes("_prev") && b.sortKey.includes("_curr")) {
        return -1;
      } else if (a.sortKey.includes("_curr") && b.sortKey.includes("_prev")) {
        return 1;
      }
      return monthMapping.indexOf(a.month) - monthMapping.indexOf(b.month);
    });
  };
  
  const prevSalesPerMonth = () => {
    let sales = {};
    const lastYear = new Date().getFullYear() - 2;
    const monthMapping = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];
  
    orders?.forEach((order) => {
      const createdAt = new Date(order.created_at);
      const year = createdAt.getFullYear() - 1;
      const monthIndex = createdAt.getMonth();
      const month = monthMapping[monthIndex] + (year === lastYear ? "_prev" : "_curr");
      let orderTotal = parseFloat(order.total_price) || 0;
      sales[month] = (sales[month] || 0) + orderTotal;
    });
  
    // Adding fake sales data for the next three months
    sales["feb_curr"] = 10000; // Fake sales for February
    sales["mar_curr"] = 3000; // Fake sales for March
    sales["apr_curr"] = 502500; // Fake sales for April
    sales["maj_curr"] = 5000000; // Fake sales for April
    sales["jun_curr"] = 150000; // Fake sales for April
    sales["jul_curr"] = 5000000; // Fake sales for April
    sales["aug_curr"] = 1000000; // Fake sales for April
    sales["sep_curr"] = 100000; // Fake sales for April
    sales["okt_curr"] = 3000000; // Fake sales for April
    sales["nov_curr"] = 8000000; // Fake sales for April
  
    const salesArray = Object.keys(sales).map((month) => ({
      month: month.replace("_prev", "").replace("_curr", ""),
      year: month.includes("_prev") ? lastYear : lastYear + 1,
      sales: sales[month].toFixed(2),
      sortKey: month
    }));
  
    // Sort the months considering the year
    return salesArray.sort((a, b) => {
      if (a.sortKey.includes("_prev") && b.sortKey.includes("_curr")) {
        return -1;
      } else if (a.sortKey.includes("_curr") && b.sortKey.includes("_prev")) {
        return 1;
      }
      return monthMapping.indexOf(a.month) - monthMapping.indexOf(b.month);
    });
  };

  const data = countOrdersPerMonth();
  const locationData = countOrdersByLocationLastYear();
  const totalOrders = getTotalOrdersLastYear();
  const busiestDay = dayWithMostOrders();
  const busiestWeek = weekWithMostDeliveries();
  const topSellingProductsData = calculateTopSellingProducts(orders);
  const salesData = salesPerMonth();
  const prevSalesData = prevSalesPerMonth();
  const processedSalesData = salesData.map(item => ({
    ...item,
    sales: parseFloat(item.sales),
  }));
  
  const prevProcessedSalesData = prevSalesData.map(item => ({
    ...item,
    sales: parseFloat(item.sales),
  }));



  return (
    <Box sx={{ flexGrow: 1, padding: 5 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <OrderStatsCards
            totalOrders={totalOrders}
            busiestDay={busiestDay}
            busiestWeek={busiestWeek}
          />
        </Grid>
        <Grid item container xs={12} spacing={3}>
          {/* This item takes up 2/3 of the width */}

          <Grid item xs={8}>
    <StyledBox>
      <Typography variant="h6" gutterBottom>
        Försäljning per månad senaste året
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
  <LineChart data={processedSalesData}>
    <XAxis dataKey="month" />
    <YAxis />
    <Tooltip />
    <Line type="monotone" data={prevProcessedSalesData} name="Året innan" dataKey="sales" stroke="#A5CBBD" strokeWidth={3} dot={false} strokeDasharray="3 3" activeDot={{ r: 5}}/>
    <Line type="monotone" name="Senaste året" dataKey="sales" stroke="#2B545A" strokeWidth={3} dot={false} activeDot={{ r: 5}}/>
  </LineChart>
</ResponsiveContainer>
    </StyledBox>
  </Grid>
          {/* This item takes up 1/3 of the width */}
          <Grid item xs={4}>
            <StyledBox>
            <Typography variant="h6" gutterBottom>
            Top 5 bästsäljande produkter
          </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={topSellingProductsData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={{
                      fontSize: "12px", // Smaller font size
                      fontWeight: "bold",
                    }}
                    cornerRadius={5}
                    outerRadius={75}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {topSellingProductsData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip formatter={(value, name) => [value, name]} />
                </PieChart>
              </ResponsiveContainer>
            </StyledBox>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StyledBox>
            <YearlyHeatmap
              orders={orders}
              type={"deliver"}
              label={"levereras"}
              header={"Beställningar levererade"}
            />
          </StyledBox>
        </Grid>
        {/* Orders Count Distribution Chart */}
        <Grid item xs={12}>
          <StyledBox>
              <Typography variant="h6" gutterBottom>
                Beställningar per månad senaste året
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="orders" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
          </StyledBox>
        </Grid>

        {/* Orders Count by Location Chart */}
        <Grid item xs={12}>
          <StyledBox>
              <Typography variant="h6" gutterBottom>
                Beställningar per butik senaste året
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  width={500}
                  height={300}
                  data={locationData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="location" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="orders" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
          </StyledBox>
        </Grid>

        <Grid item xs={12}>
            <StyledBox>
              <YearlyHeatmap
                orders={orders}
                type={"created"}
                label={"gjordes"}
                header={"Beställningar gjorda"}
              />
            </StyledBox>
          </Grid>
      </Grid>
    </Box>
  );
}

export default OrderStats;
