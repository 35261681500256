import data from 'data/data.json';

export function getTotalPrice(order) {

    var shopName = localStorage.getItem("shopName");

    // Start price as 0 and accumulate non-refunded item prices
    var price = 0.00;


    if (!order.lineItems){
        order.lineItems = order.line_items;
    }
    
    console.log("THE ORDDER", order)
    // Calculate price from lineItems
    for (let item of order.lineItems) {
        price += parseFloat(item.price || "0.00") * (item.quantity || 1);
    }

    // Calculate total discounts from line items
    var totalDiscount = 0;

    for (let item of order.lineItems) {
        if (item.discount_allocations && item.discount_allocations.length > 0) {
            for (let discount of item.discount_allocations) {
                totalDiscount += parseFloat(discount.amount || "0.00");
            }
        } else {
            totalDiscount += parseFloat(item.total_discount || "0.00");
        }
    }

    // Subtract discounts from the price
    price -= totalDiscount;

    // Check if shopName is in the data.json file
    const shopData = data.find(entry => entry.store === shopName);

    if (order.financial_status !== "paid") {
        if (shopData) {
            price = price + shopData.price;
        } else {
            console.log(`Shop ${shopName} was not found in the file`);
        }
    }

    return price;
};

export const calculateTotalPriceAddedByStore = (lineItems) => {
    if (!lineItems) {
        return 0;
    }
    return lineItems
      .filter(item => item.added_by_store)
      .reduce((total, item) => total + (parseFloat(item.price) * item.quantity), 0)
      .toFixed(2); // This will give you the total as a string with two decimal places
};

