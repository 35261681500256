import React from 'react';
import DraftOrdersList from './DraftOrdersList';
import GetDraftOrders from 'Hooks/Orders/getDraftOrders';

const DraftOrderPage = () => {
    const { draftOrders, isLoading, isError } = GetDraftOrders();

    console.log(draftOrders);

    if (isLoading) return <p>Loading...</p>;
    if (isError) return <p>Error loading draft orders.</p>;

    return <DraftOrdersList draftOrders={draftOrders} />;
}


export default DraftOrderPage;
