import React from "react";
import { Paper, Typography } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";

import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NoteIcon from '@mui/icons-material/Note';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { List, ListItem } from "@mui/material";

import translations from "translations";

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleString("sv-SE", options);
}

const OrderHistoryTimeline = ({ order }) => {
  // Define a function to determine the color of the timeline dot based on event status
  const getTimelineDotColor = (status) => {
    // if (status.toLowerCase().includes('successful')) {
    //   return 'primary';
    // }
    return "secondary";
  };

  // Define a function to get the appropriate icon for the event status
  const getTimelineIcon = (type) => {
    switch (type) {
      case 'kund':
        return <PersonIcon />;
      case 'line_item':
        return <ShoppingCartIcon />;
      case 'note_attribute':
        return <NoteIcon />;
      default:
        return <LocalShippingIcon />;
    }
  };

  const renderChangeDetails = (changes) => {
    return (
      <List dense sx={{ paddingLeft: 0, marginBottom: '8px' }}>
        {Object.keys(changes).map((key, idx) => (
          <ListItem key={idx} sx={{ padding: 0 }}>
            <Typography variant="caption" color="textSecondary">
          
              {translations.misc[key] || `${key}`} : {changes[key].from} → {changes[key].to}
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  // Create a shallow copy of the history array and reverse it
  const reversedHistory = [...order.history].reverse();

  return (
    <Paper
      elevation={0}
      sx={{ padding: "16px", margin: "16px", width: "auto" }}
    >
      <Typography variant="h6" gutterBottom>
        Versionshistorik
      </Typography>
      {order?.last_edit_by && (
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          Senast ändrad: {formatDate(order?.last_edit_at)}
        </Typography>
      )}
      {order?.last_edit_by && (
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          Senast ändrad av: {order?.last_edit_by}
        </Typography>
      )}
      <Timeline sx={{
        padding: 0,
        '& .MuiTimelineItem-root:before': {
          display: 'none',
        },
      }}>
        {reversedHistory.map((event, index) => (
          <TimelineItem key={index} sx={{
            '&:before': {
              display: 'none',
            },
            // Add any additional styles you need here
          }}>
            <TimelineSeparator>
              <TimelineDot color={getTimelineDotColor(event.type)}>
                {getTimelineIcon(event.type)}
              </TimelineDot>
              {index < reversedHistory.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              {/* Display the type of event */}
              <Typography variant="body2" color="textSecondary">
                {event?.type?.toUpperCase()}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {event.date}
              </Typography>
              {/* Display additional info based on event type */}
              {event.type === "kund" && event.changes && (
                <>
                  {/* <Typography variant="caption" color="textSecondary">
                    Användare ändring:
                  </Typography> */}
                  {renderChangeDetails(event.changes)}
                </>
              )}
              {event.type === "produkt" && (
                <Typography variant="caption" color="textSecondary">
                  {`Produkt: ${event.name} `} <br />
                </Typography>
              )}
              {/* {event.type === "customer" && (
                <Typography variant="caption" color="textSecondary">
                </Typography>
              )} */}
              {event.type === "note_attribute" && (
                <Typography variant="caption" color="textSecondary">
                  {`${event.name}: ${event.value}`} <br/> Ändrad från: {`${event.prev}`} 
                </Typography>
              )}
              {event.comment && (
                <Typography variant="caption" color="textSecondary">
                  Kommentar: {event.comment}
                </Typography>
              )}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Paper>
  );
};

export default OrderHistoryTimeline;

