import React from 'react';
import { Grid, Typography, TextField, Box, Button } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // include styles

function ProductDetailsComponent({
  updatedFields,
  handleChangeField,
  handleQuillChange,
  handleAddAllergener,
}) {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Titel:
        </Typography>
        <TextField
          value={updatedFields.title}
          onChange={(e) => handleChangeField("title", e.target.value)}
          fullWidth
          autoComplete="off"
        />

        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Leverantör / Avdelning:
        </Typography>
        <TextField
          value={updatedFields.vendor}
          onChange={(e) => handleChangeField("vendor", e.target.value)}
          fullWidth
          autoComplete="off"
        />


        <Box>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Beskrivning:
          </Typography>
          <ReactQuill
            className="my-quill-editor"
            value={updatedFields.body_html}
            onChange={(value, delta, source) => handleQuillChange("body_html", value, source)}
            modules={{
              toolbar: [["bold", "italic", "underline"]],
            }}
          />
        </Box>

        <Box>
          {updatedFields.allergener ? (
            <>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                Innehållsdeklaration:
              </Typography>
              <ReactQuill
                className="my-quill-editor"
                value={updatedFields.allergener}
                onChange={(value, delta, source) => handleQuillChange("allergener", value, source)}
                modules={{
                  toolbar: [["bold", "italic", "underline"]],
                }}
              />
            </>
          ) : (
            <>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                Innehållsdeklaration:
              </Typography>
              <Button
                variant="contained"
                onClick={handleAddAllergener}
                sx={{
                  textTransform: "none",
                }}
              >
                Lägg till
              </Button>
            </>
          )}
        </Box>
      </Grid>
    </>
  );
}

export default ProductDetailsComponent;
