import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/sv";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Chip,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import GetUpcomingOrders from "../../../Hooks/Old/useGetUpcommingOrders.js";

import "pages/SavedOldPages/OrdersCalendar/Style/customCalendar.css";

import {
  getStatusFromTags,
  iconForFinancialStatus,
} from "utilities/getStatusFromTags";
import { getStatusColor } from "utilities/getStatusColor.js";

import {
  getDeliveryDate,
  getDeliveryTimeRange,
} from "utilities/getNoteAttributes";
import { colorForFinancialStatus } from "utilities/getStatusColor.js";

import LoadingComponent from "components/LoadingComponent.jsx";
import ErrorComponent from "components/ErrorComponent.jsx";

import { CalendarNavigation } from "./components/calendarNavigation.jsx";
import translations from "translations.js";

const CustomToolbar = (toolbar) => {
  const label = () => {
    const date = toolbar.date;
    return (
      <Typography variant="h3" component="span" sx={{ marginBottom: "30px" }}>
        <b>{date.toLocaleString("default", { month: "long" })}</b>{" "}
        {date.getFullYear()}
      </Typography>
    );
  };

  return <CalendarNavigation label={label} toolbar={toolbar} />;
};

const useStyles = makeStyles(() => ({
  calendarContainer: {
    height: "650px",
    padding: "1rem",
    borderRadius: "5px",
    //boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    marginTop: "30px", // Add marginTop here
    marginLeft: "20px",
    marginRight: "20px",
  },
  event: {
    maxWidth: "80%", // Set a maximum width for the event box
  },
  header: {
    marginTop: "20px", // You can adjust the value to your preference
    marginBottom: "20px", // You can adjust the value to your preference
  },
}));

// const getFirstNameFromEmail = (email) => {
//   const match = email.match(/^[a-zA-Z]+/);
//   return match ? match[0] : email;
// };

// get a notification icon for when there is a note on the order
const getEventTitle = (name, note) => {
  if (note && note.trim() !== "") {
    // If the note is not empty, prepend the name with a small circle.
    return name + " ⭕";
  } else {
    return name;
  }
};

const getEventsFromCateringData = (cateringData) => {
  return cateringData
    ? cateringData.map((event) => {
        // Get Leveransdatum and Leveranstid from note_attributes
        const date = getDeliveryDate(event);
        const timeRange = getDeliveryTimeRange(event);
        // If date or timeRange is not defined, log the event and return null
        if (!date || !timeRange) {
          console.log("Invalid date/time detected:", event);
          return null;
        }

        const [startTime, endTime] = timeRange.split("-");

        const startDateTime = moment(
          date + " " + startTime,
          "DD/MM/YYYY HH:mm"
        );
        const endDateTime = moment(date + " " + endTime, "DD/MM/YYYY HH:mm");

        // If startDateTime or endDateTime is not valid, log the event and return null
        if (!startDateTime.isValid() || !endDateTime.isValid()) {
          console.log("Invalid date/time detected:", event);
          return null;
        }

        return {
          id: event.id,
          title: getEventTitle(event.billing_address.name, event.note),
          start: startDateTime.toDate(),
          end: endDateTime.toDate(),
          cateringData: event,
        };
      })
    : [];
};

function CateringOrdersCalendar() {
  moment.locale("sv"); // Set the locale to Swedish
  const localizer = momentLocalizer(moment);
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    upcomingOrders: cateringOrders,
    isLoading,
    isError,
  } = GetUpcomingOrders();

  const events = cateringOrders
    ? getEventsFromCateringData(cateringOrders)
    : [];

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [open, setOpen] = useState(false);

  const handleEventClick = (event) => {
    setSelectedEvent(event.cateringData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewDetails = () => {
    navigate(`/catering/${selectedEvent.id}/edit`);
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (isError) return <ErrorComponent error={isError} />;

  return (
    <div className={classes.calendarContainer}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100%" }}
        views={["month", "week", "day", "agenda"]}
        toolbar={true}
        popup={true}
        step={60}
        timeslots={1}
        dayLayoutAlgorithm="no-overlap"
        onSelectEvent={handleEventClick}
        components={{
          toolbar: CustomToolbar,
        }}
        eventPropGetter={(event, start, end, isSelected) => {
          const backgroundColor = getStatusColor(event.cateringData.tags);
            
          const borders = isSelected ? "10px" : "0px";

          const style = {
            backgroundColor,
            borderRadius: "5px",
            opacity: 1,
            color: "black",
            maxWidth: "90%", // Set a maximum width for the event box
            border: borders,
            display: "block",
            margin: "1px auto", // Decrease the margin to create less space
            padding: "2px", // Decrease padding to reduce space on the top and bottom
          };
          return {
            style,
          };
        }}
      />
      {selectedEvent && (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle sx={{ fontSize: "23px", fontWeight: "550" }}>
            Catering Beställningsdetaljer
          </DialogTitle>
          <DialogContent>
            <Chip
              size="medium"
              label={
                translations.financialStatus[selectedEvent.financial_status]
              }
              icon={iconForFinancialStatus(selectedEvent.financial_status)}
              sx={{
                minWidth: "80px",
                marginBottom: 2,
              }}
              style={{
                backgroundColor: colorForFinancialStatus(
                  selectedEvent.financial_status
                ),
              }}
            />
            <Typography style={{ marginBottom: "7px" }}>
              <strong>Datum: </strong> 
              {getDeliveryDate(selectedEvent)}
            </Typography>
            <Typography style={{ marginBottom: "7px" }}>
              <strong>Tid: </strong>
              {getDeliveryTimeRange(selectedEvent)}
            </Typography>
            <Typography>
              <strong>Beställning:</strong>
            </Typography>
            <List dense>
              {" "}
              {/* Apply the `dense` prop */}
              {selectedEvent.lineItems.map((item) => (
                <ListItem key={item.id}>
                  <ListItemText
                    disableTypography={false}
                    primary={
                      <Typography sx={{ fontWeight: "bold" }}>
                        {item.name}
                      </Typography>
                    }
                    secondary={
                      <Typography>{`Antal: ${item.quantity}`}</Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
            <Typography style={{ marginBottom: "7px" }}>
              <strong>Email:</strong> {selectedEvent.contact_email}
            </Typography>
            <Typography style={{ marginBottom: "7px" }}>
              <strong>Status:</strong>{" "}
              {selectedEvent.tags
                ? getStatusFromTags(selectedEvent.tags)
                : "Mottagen"}
            </Typography>
            <Typography style={{ marginBottom: "7px" }}>
              <strong>Leveransadress: </strong>
              {!selectedEvent.shipping_address
                ? "Denna order kommer att hämtas i butiken!"
                : selectedEvent.billing_address?.address1 &&
                  selectedEvent.billing_address?.address1
                ? selectedEvent.billing_address.address1 // assuming this is where the actual address string is stored
                : "Hemleverans, men kartan kunde inte laddas."}
            </Typography>
            <Typography style={{ marginBottom: "20px" }}>
              <strong>Anteckning: </strong>
              {selectedEvent.note || "Ingen anteckning tillgänglig"}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleViewDetails}
              sx={{
                fontWeight: "bold",
                textTransform: "none",
              }}
            >
              Se mer om beställningen
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default CateringOrdersCalendar;
