import React from "react";
import { Typography, Grid, ListItem, ListItemText } from "@mui/material";
import { getTotalPrice } from "utilities/getTotalPrice";

import data from "data/data.json"


function getDeliveryCost(order) {
  var shopName = localStorage.getItem("shopName");
  const shopData = data.find(entry => entry.store === shopName);

  if (!shopData){
    return 0;
  }

  if (order.financial_status !== "paid"){
    return shopData.price;
  }
  return 0;
}

function OrderDetailsGrid({
  order,
  translations,
  booked,
  handleBookedChange,
  theme,
  formatDate,
}) {

  const primaryStyle = {
    fontSize: "1rem",
    fontWeight: "600",
    color: theme.palette.green.main,
  };

  const secondaryStyle = { fontSize: "0.8rem" };

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <ListItem>
          <ListItemText
            disableTypography
            primary={<Typography style={primaryStyle}>Email</Typography>}
            secondary={
              <Typography style={secondaryStyle}>
                {order.customer?.email}
              </Typography>
            }
          />
        </ListItem>
      </Grid>
      <Grid item xs={6}>
        <ListItem>
          <ListItemText
            disableTypography
            primary={<Typography style={primaryStyle}>Telefon</Typography>}
            secondary={
              <Typography style={secondaryStyle}>
                {order.billing_address?.phone
                  ? order.billing_address.phone
                  : "Ej angivet"}
              </Typography>
            }
          />
        </ListItem>
      </Grid>
      <Grid item xs={6}>
        <ListItem>
          <ListItemText
            disableTypography
            primary={<Typography style={primaryStyle}>Skapad</Typography>}
            secondary={
              <Typography style={secondaryStyle}>
                {formatDate(order.created_at)}
              </Typography>
            }
          />
        </ListItem>
      </Grid>
      <Grid item xs={6}>
        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <Typography style={primaryStyle}>Finansiell status</Typography>
            }
            secondary={
              <Typography style={secondaryStyle}>
                {translations.financialStatus[order.financial_status]}
              </Typography>
            }
          />
        </ListItem>
      </Grid>
      <Grid item xs={6}>
        <ListItem>
          <ListItemText
            disableTypography
            primary={<Typography style={primaryStyle}>Totalt pris</Typography>}
            secondary={
              <Typography style={secondaryStyle}>
                {getTotalPrice(order) + " kr"}
              </Typography>
            }
          />
        </ListItem>
      </Grid>

      <Grid item xs={6}>
        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <Typography style={primaryStyle}>Leveransdatum</Typography>
            }
            secondary={
              <Typography style={secondaryStyle}>
                {order.note_attributes?.find(
                  (attr) => attr.name === "Leveransdatum"
                )?.value || "Saknas"}
              </Typography>
            }
          />
        </ListItem>
      </Grid>
      <Grid item xs={6}>
        <ListItem>
          <ListItemText
            disableTypography
            primary={<Typography style={primaryStyle}>Leveransdag</Typography>}
            secondary={
              <Typography style={secondaryStyle}>
                {translations.dagar[
                  order.note_attributes?.find(
                    (attr) => attr.name === "Leveransdag"
                  )?.value
                ] || "Saknas"}
              </Typography>
            }
          />
        </ListItem>
      </Grid>
      <Grid item xs={6}>
        <ListItem>
          <ListItemText
            disableTypography
            primary={<Typography style={primaryStyle}>Leveranstid</Typography>}
            secondary={
              <Typography style={secondaryStyle}>
                {order.note_attributes?.find(
                  (attr) => attr.name === "Leveranstid"
                )?.value || "Saknas"}
              </Typography>
            }
          />
        </ListItem>
      </Grid>
      <Grid item xs={6}>
        <ListItem>
          <ListItemText
            disableTypography
            primary={<Typography style={primaryStyle}>Bokförd</Typography>}
            secondary={
              <div>
                <input
                  type="checkbox"
                  checked={booked}
                  onChange={handleBookedChange}
                />
              </div>
            }
          />
        </ListItem>
      </Grid>
      <Grid item xs={6}>
        <ListItem>
          <ListItemText
            disableTypography
            primary={<Typography style={primaryStyle}>Tilläggsavgift</Typography>}
            secondary={
              <Typography style={secondaryStyle}>
                 {getDeliveryCost(order)}kr
              </Typography>
            }
          />
        </ListItem>
      </Grid>
    </Grid>
  );
}

export default OrderDetailsGrid;
