import React, { useState } from 'react';
import { Box, Typography, Grid, Drawer, List, ListItem, Divider, ListItemText } from '@mui/material';
import LoadingComponent from "components/LoadingComponent.jsx";
import ErrorComponent from "components/ErrorComponent.jsx";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import useShopifyProducts from 'Hooks/Products/useShopifyProducts.js';
import StatsBox from 'components/StatBox';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Chip from '@mui/material/Chip';
import StyledBox from 'components/StyledBox';



// Calculate the frequency of each tag across all products
const calculateTagStats = (products) => {
    const tagStats = {};
    products.forEach((product) => {
      const tags = product.tags.split(', ');
      tags.forEach((tag) => {
        if (!tagStats[tag]) {
          tagStats[tag] = { total: 0, active: 0 };
        }
        tagStats[tag].total += 1;
        if (product.status === 'active') {
          tagStats[tag].active += 1;
        }
      });
    });
    return Object.entries(tagStats).map(([tag, counts]) => ({
      tag,
      total: counts.total,
      active: counts.active
    }));
  };

function ProductStats() {
  const { products, isLoading, isError } = useShopifyProducts();
  const [selectedTag, setSelectedTag] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);


  if (isLoading) {
    return <LoadingComponent />;
  }

  if (isError) {
    return <ErrorComponent error={isError} />;
  }

  // Function to handle bar click
  const handleBarClick = (data, index) => {
    setSelectedTag(data.tag);
    setDrawerOpen(true);
  };

  // Function to close the drawer
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const tagStatsData = calculateTagStats(products);

  // Example statistics
  const totalProducts = products.length;
  const activeProducts = products.filter(product => product.status === 'active').length;
  const averagePrice = products.reduce((acc, product) => acc + parseFloat(product.variants[0].price), 0) / products.length;

  // Render pie chart
  const renderTagBarChart = () => (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart
        data={tagStatsData}
        margin={{
          top: 20, right: 30, left: 80, bottom: 5,
        }}
        layout="vertical"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis type="category" dataKey="tag" width={80} />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="total"
          fill="#8884d8"
          name="Total"
          onClick={handleBarClick}
          cursor="pointer" // Changes the cursor to a pointer on hover
        />
        <Bar
          dataKey="active"
          fill="#82ca9d"
          name="Active"
          onClick={handleBarClick}
          cursor="pointer" // Changes the cursor to a pointer on hover
        />
      </BarChart>
    </ResponsiveContainer>
  );

  const renderProductList = () => {
    const filteredProducts = products.filter(product => product.tags.includes(selectedTag));
    return (
      <List>
        {filteredProducts.map(product => (
          <React.Fragment key={product.id}>
            <ListItem
              button
              component="a"
              href={`/products/${product.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItemText
                primary={product.title}
                secondary={`ID: ${product.id}`}
              />
              <Chip
                label={product.status === 'active' ? 'Active' : 'Inactive'}
                color={product.status === 'active' ? 'success' : 'default'}
                size="small"
                variant="outlined"
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    );
  };
  


  return (
    <Box sx={{ flexGrow: 1, padding: 5 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
        {/* Product Stats Cards */}
        <ProductStatsCards 
          totalProducts={totalProducts}
          activeProducts={activeProducts}
          averagePrice={averagePrice}
        />
        </Grid>
        {/* You can add more components like charts or tables to visualize the product data */}
        <Grid item xs={12}>
          <StyledBox>
              <Typography variant="h6" gutterBottom>
                Tag Distribution
              </Typography>
              {renderTagBarChart()}
          </StyledBox>
        </Grid>
      </Grid>

      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box
          sx={{ width: 350 }}
          role="presentation"
        >
          <Typography variant="h6" sx={{ p: 2 }}>
            Products for "{selectedTag}"
          </Typography>
          {renderProductList()}
        </Box>
      </Drawer>
    </Box>
  );
}

export default ProductStats;

// This component will render individual stats cards
function ProductStatsCards({ totalProducts, activeProducts, averagePrice }) {
    console.log(totalProducts)
  return (
    <Grid container spacing={3} justifyContent="center">
      {/* Total Products Box */}
      <Grid item xs={12} sm={6} md={4}>
        <StatsBox
          title="Antal produkter"
          value={totalProducts}
          color="#2B545A" // Text color
          icon={StarBorderIcon} // Icon component
          // Add icon or styling as needed
        />
      </Grid>

      {/* Active Products Box */}
      <Grid item xs={12} sm={6} md={4}>
        <StatsBox
          title="Aktiva Produkter"
          value={activeProducts}
          color="#2B545A" // Text color
          icon={StarBorderIcon} // Icon component
          // Add icon or styling as needed
        />
      </Grid>

      {/* Average Price Box */}
      <Grid item xs={12} sm={6} md={4}>
        <StatsBox
          title="Average Price"
          value={`$${averagePrice.toFixed(2)}`}
          color="#2B545A" // Text color
          icon={StarBorderIcon} // Icon component
          // Add icon or styling as needed
        />
      </Grid>
    </Grid>
  );
}


// Reuse the StatsBox component from SurveyStatsCards or create a new one if the display data differs significantly
