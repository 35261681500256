import React from 'react';
import { Grid } from '@mui/material';
import StatsBox from 'components/StatBox';
import StarBorderIcon from '@mui/icons-material/StarBorder'; // Icon for average rating
import FeedbackIcon from '@mui/icons-material/Feedback'; // Icon for total feedback
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown'; // Icon for positive/negative feedback balance

function SurveyStatsCards({ averageRating, totalSurveys, positiveNegativeBalance }) {
  return (
    <Grid container spacing={3} justifyContent="center">
      {/* Average Rating Box */}
      <Grid item xs={12} sm={6} md={4}>
        <StatsBox
          title="Genomsnittligt betyg"
          value={averageRating.toFixed(1)} // Assuming averageRating is a number
          color="#2B545A" // Text color
          icon={StarBorderIcon} // Icon component
        />
      </Grid>

      {/* Total Surveys Box */}
      <Grid item xs={12} sm={6} md={4}>
        <StatsBox
          title="Totalt antal svar"
          value={totalSurveys}
          color="#2B545A" // Text color
          icon={FeedbackIcon} // Icon component
        />
      </Grid>

      {/* Positive/Negative Feedback Balance Box */}
      <Grid item xs={12} sm={6} md={4}>
        <StatsBox
          title="Balans mellan positiv/negativ feedback"
          value={positiveNegativeBalance} // This could be a calculated value
          color="#2B545A" // Text color
          icon={ThumbsUpDownIcon} // Icon component
        />
      </Grid>
    </Grid>
  );
}

export default SurveyStatsCards;
