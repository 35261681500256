import { useState } from 'react';

const useDeleteDiscount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteDiscount = async (discountId, priceRuleId) => {
    console.log("Deleting discount with id:", discountId, "and price rule id:", priceRuleId);
    setIsLoading(true);
    const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:8000";

    try {
      const response = await fetch(`${baseUrl}/shopify-api/discounts/delete/${discountId}/${priceRuleId}/`, {
        method: 'DELETE',
        credentials: 'include', // Include this line if you're including session-based authentication
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Something went wrong!');
      }

      setError(null);
      console.log(`Discount  ${discountId} deleted successfully`);
      // No need to return data for a delete operation
    } catch (err) {
      setError(err.message || 'An error occurred while deleting the discount.');
    } finally {
      setIsLoading(false);
    }
  };

  return { deleteDiscount, isLoading, error };
};

export default useDeleteDiscount;
