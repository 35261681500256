import { Snackbar, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from '@mui/material/Slide';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const CustomSnackbar = ({
  message,
  open,
  handleClose,
  severity = "info",
  autoHideDuration = 5000,
  isMobile,
  isLogin
}) => {

  return (
    <Snackbar
      anchorOrigin={{vertical: isMobile ? "bottom" : "top" , horizontal: isLogin ? "center" : "right" }}
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      key={`${severity}-${message}`}
      TransitionComponent={SlideTransition}
      ContentProps={{
        sx: {
          fontWeight: "bold",
          backgroundColor: "#ffffff", // White background for the snackbar
          color: "#000000", // Black text color
        },
      }}
    >
      <Alert
        severity={severity ? severity : "info"}
        action={
          <IconButton
            size="small"
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        sx={{ 
          fontWeight: "bold",
          color: "#000000", // Use parent's text color
          boxShadow: "0 8px 20px 0 rgba(0,0,0,0.1)", // Soft box shadow
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
