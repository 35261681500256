import React, { useEffect, useState, useContext } from "react";
import OrderDetailsTable from "./components/OrderDetailsTable";
import GetOrderDB from "../../../Hooks/Orders/getOrder";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import LoadingComponent from "components/LoadingComponent.jsx";
import { useUpdateOrder } from "../../../Hooks/Orders/useUpdateOrderFields";
import AuthContext from "context/AuthContext";
import useGetLocations from "../../../Hooks/Locations/useGetLocations";
import CustomDatePicker from "components/CustomDatePicker";
import CustomSnackbar from "components/SnackBar";
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import StyledBox from "../../../components/StyledBox";
import moment from 'moment';
import { printPDF } from 'utilities/generatePdf';
import generatePDF from 'utilities/generatePdf';
import { calculateTotalPriceAddedByStore } from "utilities/getTotalPrice";
import OrderHistoryTimeline from "./components/HistoryTimeLine";

import {
  Box,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Chip,
} from "@mui/material";


function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleString("sv-SE", options);
}


const OrderEdit = () => {
  const { user } = useContext(AuthContext);
  const { id } = useParams(); // This will get the orderId from the URL
  const { orders, isLoading, isError } = GetOrderDB(id);
  const navigate = useNavigate();
  const [editableOrder, setEditableOrder] = useState(null);
  const [initialOrderData, setInitialOrderData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [hasChanges, setHasChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const { mutate: updateOrder } = useUpdateOrder();
  const { locations } = useGetLocations();
  const totalPriceAddedByStore = calculateTotalPriceAddedByStore(editableOrder?.line_items);



  const handleSaveChanges = () => {
    if (!editableOrder) return;

    const changesMade = JSON.stringify(editableOrder) !== JSON.stringify(initialOrderData);
    if (!changesMade) {
      return;
    }
    // Prepare the data to be updated
    const updatedData = {
      last_edit_by: user?.first_name + " " + user?.last_name,
      note_attributes: editableOrder.note_attributes,
      customer: {
        first_name: editableOrder.customer.first_name,
        last_name: editableOrder.customer.last_name,
        email: editableOrder.customer.email,
        phone: editableOrder.customer.phone,
      },
    };
    console.log(updatedData)
    // Update the order
    updateOrder({ orderId: editableOrder.shopify_order_id, updatedFields: updatedData }, {
      onSuccess: () => {
        handleOpenSnackbar("Orderuppdatering lyckades!", "success");
        setHasChanges(false);
        setInitialOrderData(updatedData);
      },
      onError: (error) => {
        handleOpenSnackbar("Orderuppdatering misslyckades!", "error");
        console.log(error.message)
      }
    });
  };
    console.log("🚀 ~ handleSaveChanges ~ editableOrder:", editableOrder)

  const handleOrderDetailsChange = () => {
    setEditableOrder(prevOrder => ({
      ...prevOrder,
      last_edit_by: user?.first_name + " " + user?.last_name,
    }));
    setHasChanges(true); // This flags that changes have been made
  };

  const handleGoBack = () => {
    navigate(-1); // This will take the user back to the previous page
  };

  useEffect(() => {
    // Set the initial location based on the editableOrder data
    const initialLocation = editableOrder?.note_attributes?.find(
      (attr) => attr.name === "Upphamtningsplats"
    )?.value || "";

    setSelectedLocation(initialLocation);
  }, [editableOrder]);

  useEffect(() => {
    if (orders) {
      const formattedOrders = {
        ...orders,
        formattedDate: formatDate(orders.created_at),
      };
      setEditableOrder(formattedOrders);
      setInitialOrderData(formattedOrders);
    }
  }, [orders]);

  const handleInputChange = (path, value) => {
    // Check if the value has changed
    setHasChanges(true);
    setEditableOrder((prev) => {
      const keys = path.split(".");
      const lastKey = keys.pop();

      // Clone the state
      const updated = { ...prev };
      let current = updated;

      keys.forEach((key, index) => {
        if (key in current) {
          if (Array.isArray(current[key])) {
            // For arrays, the next key should be an index
            const arrIndex = keys[index + 1];
            current[key] = [...current[key]];
            current = current[key];
            current[arrIndex] = { ...current[arrIndex] };
            current = current[arrIndex];
          } else {
            // For objects
            current[key] = { ...current[key] };
            current = current[key];
          }
        }
      });

      // Update the final value
      current[lastKey] = value;
      return updated;
    });
  };

  const updateNoteAttribute = (attrName, value) => {
    const attrIndex = editableOrder?.note_attributes.findIndex(
      (attr) => attr.name === attrName
    );
    if (attrIndex !== -1) {
      handleInputChange(`note_attributes.${attrIndex}.value`, value);
    }
  };

  // Convert the delivery date string to a moment object
  const deliveryDate = editableOrder?.note_attributes?.find(attr => attr.name === "Leveransdatum")?.value;
  const deliveryDateMoment = deliveryDate ? moment(deliveryDate, 'DD/MM/YYYY') : moment();

  const handleDeliveryDateChange = (newDate) => {
    // Format the date into DD/MM/YYYY
    const formattedDate = newDate.format('DD/MM/YYYY');
    updateNoteAttribute("Leveransdatum", formattedDate);

    // Update Leveransdag based on the new date
    const dayOfWeek = newDate.format('dddd');
    console.log("🚀 ~ handleDeliveryDateChange ~ dayOfWeek:", dayOfWeek)
    updateNoteAttribute("Leveransdag", dayOfWeek);
  };

  const handleLocationChange = (e) => {
    const locationName = e.target.value;
    updateNoteAttribute("Upphamtningsplats", locationName);
    setSelectedLocation(locationName);
  };

  const handleOpenSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleAbortChanges = () => {
    setEditableOrder(initialOrderData);
    setHasChanges(false);
  };

  console.log(editableOrder?.note_attributes?.find(
    (attr) => attr.name === "Leveransdag"
  )?.value)
  const handleSinglePDFGeneration = async () => {
    setLoading(true);  
    try {
        if (editableOrder) {
            try {
                const pdfBlob = await generatePDF(editableOrder.shopify_order_id, editableOrder, "shopflow");
                printPDF(pdfBlob); // Print the PDF
            } catch (error) {
                console.error("Error generating PDF for order", editableOrder.shopify_order_id, error);
            }
        } else {
            console.log('Order not found.');
        }
    } catch (error) {
        console.error('Error in single PDF generation:', error);
    } finally {
        setLoading(false);
    }
};


  const handlePrint = () => {
    handleSinglePDFGeneration();
  };

  const financialStatusToChipStyles = {
    'paid': { label: 'Betald', labelColor: '#0F8D57', backgroundColor: '#E3F6E7' },
    'pending': { label: 'Obetald', labelColor: '#901420', backgroundColor: '#FDEDEF' },
    'partially-paid': { label: 'Delvis betald', labelColor: '#B76E01', backgroundColor: '#FFF3DE' },
    'refunded': { label: 'Återbetald', labelColor: '#637381', backgroundColor: '#EEF0F2' },
  };

  
  if (isLoading || !editableOrder) {
    return <LoadingComponent />;
  }

  if (isError) {
    return <div>Error loading order.</div>;
  }


  return (
    <Box sx={{
      pl: 7,
      pr: 7,
      pb: 7,
    }}>
      <Grid container spacing={2}>
      <Grid item xs={12}>
      <Box sx={{ display: 'flex', gap: 2, mb: 2, mt: 5, justifyContent: 'space-between', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
    <IconButton
      color="primary"
      onClick={handleGoBack}
    >
      <ArrowBackIosIcon fontSize="small"/>
    </IconButton>

    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="h3" sx={{ fontWeight: '800'}}>
          Order #{editableOrder?.shopify_order_id}
        </Typography>

        {/* Conditionally render the Chip with custom styles and bold label */}
        {editableOrder?.financial_status && (
          <Chip
            label={financialStatusToChipStyles[editableOrder.financial_status]?.label}
            sx={{
              bgcolor: financialStatusToChipStyles[editableOrder.financial_status]?.backgroundColor,
              color: financialStatusToChipStyles[editableOrder.financial_status]?.labelColor,
              '& .MuiChip-label': {
                color: financialStatusToChipStyles[editableOrder.financial_status]?.labelColor,
                fontWeight: 'bold',
                fontSize: '0.9rem',
            },
            padding: '20px 12px',
            borderRadius: '100px'
            }}
          />
        )}
      </Box>

      {/* Created and Last Edited Information */}
      <Typography variant="body1" sx={{ color: 'text.secondary', mt: 0.5 }}>
        Skapad: {formatDate(editableOrder?.created_at)}
      </Typography>
    </Box>
  </Box>
</Box>


  <Box sx={{ display: 'flex', gap: 2 }}>
    <Button 
      variant="contained" 
      color="secondary" 
      onClick={handleSaveChanges}
      sx={{ textTransform: 'none' }}
      disabled={!hasChanges}
    >
      Spara
    </Button>
    <Button 
      variant="contained" 
      color="primary" 
      onClick={handleAbortChanges}
      sx={{ textTransform: 'none' }}
      disabled={!hasChanges}
    >
      Avbryt
    </Button>
    <Button
      variant="contained"
      color="primary"
      onClick={handlePrint}
      sx={{ textTransform: 'none' }}
      startIcon={loading ? <CircularProgress size={15} /> : <PrintRoundedIcon />}
      disabled={loading}
    >
      {loading ? 'Laddar...' : 'Skriv ut'}
    </Button>
  </Box>
</Box>

    </Grid>
      <Grid item xs={12} md={8}>
      <StyledBox>
      <Grid item>
      <OrderDetailsTable order={editableOrder} onDetailsChange={handleOrderDetailsChange}/>
    </Grid>
  </StyledBox>

  <Grid item xs={12} md={0}>
      <StyledBox>
      <Grid item>
      <OrderHistoryTimeline order={editableOrder}/>
    </Grid>
  </StyledBox>
  </Grid>


  </Grid>
        <Grid item xs={12} md={4}>
        <StyledBox>


          
  <Grid container direction="column" spacing={2}>


    {/* Betalning Field */}
    <Grid item>
      <Box display="flex" alignItems="center" gap={1}> {/* Flex container with a gap */}
        <Typography style={{ fontSize: "1rem", fontWeight: "600" }}>
          Betalning
        </Typography>
        <Chip
          label={financialStatusToChipStyles[editableOrder.financial_status]?.label}
          sx={{
            bgcolor: financialStatusToChipStyles[editableOrder.financial_status]?.backgroundColor,
            color: financialStatusToChipStyles[editableOrder.financial_status]?.labelColor,
            '& .MuiChip-label': {
              color: financialStatusToChipStyles[editableOrder.financial_status]?.labelColor,
              fontWeight: 'bold',
              fontSize: '0.7rem',
            },
            padding: '10px 6px',
            borderRadius: '100px'
          }}
        />
      </Box>
      {/* Conditionally render the not paid message */}
      {/* {editableOrder.financial_status === 'partially-paid' && (
        <Typography style={{ fontSize: "0.9rem", marginTop: '8px' }}>
        Kvar att betala: <strong>{totalPriceAddedByStore} kr</strong>
      </Typography>
      )}
      {editableOrder.financial_status === 'pending' && (
        <Typography style={{ fontSize: "0.9rem", marginTop: '8px' }}>
        Att betala: <strong> {editableOrder.total_price} kr</strong>
      </Typography>
      )} */}

      <Typography style={{ fontSize: "0.9rem", marginTop: '8px' }}>
        Betalt: <strong> {editableOrder.total_price-totalPriceAddedByStore} kr</strong>
      </Typography>

      <Typography style={{ fontSize: "0.9rem", marginTop: '8px' }}>
        Kvar att betala: <strong>{totalPriceAddedByStore} kr</strong>
      </Typography>
    
      <Typography style={{ fontSize: "0.9rem", marginTop: '8px' }}>
       Totalt: <strong>{editableOrder.total_price} kr</strong>
      </Typography>

    </Grid>

  </Grid>
</StyledBox>
          <StyledBox>
        <Grid container direction="column" spacing={2}>
    
        {/* Email Field */}
        <Grid item>
          <Typography style={{ fontSize: "1rem", fontWeight: "600" }}>
            Email
          </Typography>
          <TextField
            fullWidth
            size="small"
            style={{ fontSize: "0.8rem" }}
            value={editableOrder?.customer?.email || ''}
            onChange={(e) => handleInputChange("customer.email", e.target.value)}
          />
        </Grid>

        {/* Telefon Field */}
        <Grid item>
          <Typography style={{ fontSize: "1rem", fontWeight: "600" }}>
            Telefon
          </Typography>
          <TextField
            fullWidth
            size="small"
            style={{ fontSize: "0.8rem" }}
            value={editableOrder?.customer?.phone || ''}
            onChange={(e) => handleInputChange("customer.phone", e.target.value)}
          />
        </Grid>

        {/* Förnamn Field */}
        <Grid item container spacing={2}> {/* This Grid container will hold both name fields */}
          <Grid item xs={6}> {/* This Grid item will take up half of the container's width */}
            <Typography style={{ fontSize: "1rem", fontWeight: "600" }}>
              Förnamn
            </Typography>
            <TextField
              fullWidth
              size="small"
              style={{ fontSize: "0.8rem" }}
              value={editableOrder?.customer?.first_name || ''}
              onChange={(e) => handleInputChange("customer.first_name", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}> {/* This Grid item will also take up half of the container's width */}
            <Typography style={{ fontSize: "1rem", fontWeight: "600" }}>
              Efternamn
            </Typography>
            <TextField
              fullWidth
              size="small"
              style={{ fontSize: "0.8rem" }}
              value={editableOrder?.customer?.last_name || ''}
              onChange={(e) => handleInputChange("customer.last_name", e.target.value)}
            />
          </Grid>
        </Grid>

      </Grid>
    </StyledBox>
    <StyledBox>
  <Grid container direction="column" spacing={2}>

    {/* Leveransdatum Field */}
    <Grid item>
      <Typography style={{ fontSize: "1rem", fontWeight: "600" }}>
        Leveransdatum
      </Typography>
      <CustomDatePicker
        value={deliveryDateMoment}
        onChange={handleDeliveryDateChange}
      />
    </Grid>

    <Grid item container spacing={2}> 
    {/* Leveransdag Field */}
    <Grid item xs={6}>
      <Typography style={{ fontSize: "1rem", fontWeight: "600" }}>
        Leveransdag
      </Typography>
      <TextField
        fullWidth
        size="small"
        style={{ fontSize: "0.8rem" }}
        value={
            editableOrder?.note_attributes?.find(
              (attr) => attr.name === "Leveransdag"
            )?.value
           || ''
        }
      />
    </Grid>

    {/* Leveranstid Field */}
    <Grid item xs={6}>
      <Typography style={{ fontSize: "1rem", fontWeight: "600" }}>
        Leveranstid
      </Typography>
      <TextField
        fullWidth
        size="small"
        style={{ fontSize: "0.8rem" }}
        value={
          editableOrder?.note_attributes?.find(
            (attr) => attr.name === "Leveranstid"
          )?.value || ''
        }
        onChange={(e) => updateNoteAttribute("Leveranstid", e.target.value)}
      />
    </Grid>
    </Grid>
    {/* Upphämtningsplats Field */}
    <Grid item>
      <Typography style={{ fontSize: "1rem", fontWeight: "600" }}>
        Upphämtningsplats
      </Typography>
      <Select
        fullWidth
        value={
          editableOrder?.note_attributes?.find(
            (attr) => attr.name === "Upphamtningsplats"
          )?.value || "Saknas"
        }
        onChange={handleLocationChange}
        displayEmpty
      >
        {locations?.map(location => (
          <MenuItem key={location.id} value={location.name}>
            {location.name}
          </MenuItem>
        ))}
      </Select>
    </Grid>

    {/* Upphämtningsaddress Field */}
    <Grid item>
      <Typography style={{ fontSize: "1rem", fontWeight: "600" }}>
        Upphämtningsadress
      </Typography>
      <TextField
        fullWidth
        size="small"
        style={{ fontSize: "0.8rem" }}
        value={
          locations?.find(location => location.name === selectedLocation)?.address1 || ""
        }
      />
    </Grid>
  </Grid>
  </StyledBox>


  
    </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleCloseSnackbar}
      />

    </Box>
  );
};

export default OrderEdit;


