// add more as needed for props from shopify api (english) that needs to be displayed in swedish for customer
const translations = {

    financialStatus: {
        "pending": "Obetald",
        "authorized": "Godkänd",
        "paid": "Betald",
        "partially-paid": "Delvis betald",
        "refunded": "Återbetald",
    },

    dagar: {
        "Monday": "Måndag",
        "Tuesday": "Tisdag",
        "Wednesday": "Onsdag",
        "Thursday": "Torsdag",
        "Friday": "Fredag",
        "Saturday": "Lördag",
        "Sunday": "Söndag",
    },

    days: {
        "måndag": "Monday",
        "tisdag": "Tuesday",
        "onsdag": "Wednesday",
        "torsdag": "Thursday",
        "fredag": "Friday",
        "lördag": "Saturday",
        "söndag": "Sunday",
    },

    productStatus: {
        "active" : "Publicerad",
        "draft" : "Inaktiv",
        "unavailable" : "Otillgänlig"
    },

    misc: {
        'first_name' : 'Förnamn',
        'last_name' : 'Efternamn',
        'phone' : 'Telefon',
        'email' : 'Email'
    }
}


export default translations;