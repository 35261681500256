import React from 'react';
import useGetCustomer from 'Hooks/Costumer/getCostumers';
import { Box, Typography, Grid } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import LoadingComponent from "components/LoadingComponent.jsx";
import ErrorComponent from "components/ErrorComponent.jsx";
import CustomerStatsCards from './customerStatsCards.jsx';
import StyledBox from 'components/StyledBox.jsx';

function CustomerStats() {
    const { customers, isCustomerLoading, isCustomerError } = useGetCustomer();

    if (isCustomerLoading|| !customers) {
        return <LoadingComponent />;
    }

    if (isCustomerError ) {
        return <ErrorComponent error={isCustomerError} />;
    }

    // Example calculation - you should replace these with real calculations based on your data
    const totalCustomers = customers.length;
    const customersAcceptingMarketing = customers.filter(c => c.accepts_marketing).length;
    // Add more calculations as needed


    // Calculating orders count data
    const ordersCountData = Array.from({ length: 10 }, (_, i) => ({ name: `${i + 1} ${i === 9 ? '+' : ''}`, count: 0 }));
    customers.forEach(customer => {
        // Ensure orders_count is a number and handle undefined or null
        const ordersCount = Number(customer.orders_count) || 0;
        const countIndex = Math.min(ordersCount, 10) - 1;
        if (countIndex >= 0 && countIndex < ordersCountData.length) {
            ordersCountData[countIndex].count += 1;
        }
    });

        // Grouping customers by orders count and calculating average spend per order
        const spendPerOrderData = Array.from({ length: 11 }, (_, i) => ({ name: `${i || '10+'}`, averageSpend: 0, count: 0 }));
        customers.forEach(customer => {
            const ordersCount = Number(customer.orders_count) || 0;
            const totalSpend = parseFloat(customer.total_spent) || 0;
            const countIndex = Math.min(ordersCount, 10) - 1;
    
            if (countIndex >= 0 && countIndex < spendPerOrderData.length) {
                spendPerOrderData[countIndex].count += 1;
                spendPerOrderData[countIndex].averageSpend += totalSpend;
            }
        });
    
        spendPerOrderData.forEach(item => {
            if (item.count > 0) {
                item.averageSpend /= item.count;
            }
        });



        return (
            <Box sx={{ flexGrow: 1, padding: 5 }}>
                <Grid container spacing={3} justifyContent="center">
                    {/* Customer Stats Cards */}
                    <Grid item xs={12}>

                    <CustomerStatsCards totalCustomers={totalCustomers} customersAcceptingMarketing={customersAcceptingMarketing} />

                    </Grid>
                    {/* Orders Count Distribution Chart */}
                    <Grid item xs={12}>
                        <StyledBox>
                                <Typography variant="h6" gutterBottom>
                                    Orders Count Distribution
                                </Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart data={ordersCountData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="count" fill="#8884d8" />
                                    </BarChart>
                                </ResponsiveContainer>
                        </StyledBox>
                    </Grid>
    
                    {/* Average Spend Per Order by Order Count Chart */}
                    <Grid item xs={12}>
                        <StyledBox>
                                <Typography variant="h6" gutterBottom>
                                    Average Spend Per Order by Order Count
                                </Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart data={spendPerOrderData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="averageSpend" fill="#82ca9d" />
                                    </BarChart>
                                </ResponsiveContainer>
                        </StyledBox>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    
    export default CustomerStats;