import * as React from 'react';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';


const CustomDateTimePicker = ({ value, onChange, label }) => {
  return (
    <LocalizationProvider dateAdapter={ AdapterMoment }>
      <DateTimePicker
        sx={{ width: '100%' }}
        renderInput={(props) => <TextField {...props} />}
        label={label}
        value={value}
        onChange={(newValue) => onChange(newValue)}
      />
    </LocalizationProvider>
  );
};

export default CustomDateTimePicker;