import { useQuery } from 'react-query';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';


const fetchOrder = async (updateToken, logoutUser, retryCount = 0) => {
  const baseUrl = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'http://localhost:8000';
    
  const url = `${baseUrl}/webhook/get-draft-orders`;


  const response = await fetch(url, {
    headers: {
      'Content-Type':'application/json',
    },
    credentials: 'include',
  });

  if ((response.status === 401 || response.status === 403) && retryCount < 1) {
    // If unauthorized and haven't retried yet, refresh token and try again
    await updateToken();
    return fetchOrder(updateToken, logoutUser, retryCount + 1);
} else if ((response.status === 401 || response.status === 403) && retryCount >= 1) {
    // If unauthorized and this was a retry, log the user out
    logoutUser();
    throw new Error('Authentication failed. Logging out.');
}

  const data = await response.json();

  return data;
}

export const GetDraftOrders = () => {  
    const { updateToken, logoutUser } = useContext(AuthContext);

    const { data, isLoading, isError } = useQuery('draftOrders', () => fetchOrder(updateToken, logoutUser), {
        retry: 1,
    });

    return { draftOrders: data, isLoading, isError };
};


export default GetDraftOrders;
